import Icon from './Icon.vue'
import FileUploader from './FileUploader.vue'
import ImageLoader from './ImageLoader.vue'
import ImageUploader from './ImageUploader.vue'
import ControlOutput from './ControlOutput.vue'

export default function install(Vue) {
  Vue.component('Icon', Icon)
  Vue.component('FileUploader', FileUploader)
  Vue.component('ImageLoader', ImageLoader)
  Vue.component('ImageUploader', ImageUploader)
  Vue.component('ControlOutput', ControlOutput)
}
