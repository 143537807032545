<template lang="pug">
  include /mixin
  +b.fake-wrapper
    +b.fake-tag.TAG(
      ref='visibleinput'
      :tabindex.props='1'
      v-if='show'
      @blur='blur',
      @close='blur',
      :tag='tag',
      v-model='value',
      v-bind='[$attrs, $props]'
      v-on="$listeners"
    )
    slot(
      name='dummy-value',
      :value='value'
      :focus='() => focus',
      v-else
    )
      +b.fake-input(
        @focus='focus',
        :tabindex='0'
      )
        +b.ds-caption--fake.js-fake-content.P(
          :data-error="_('This field is required')"
        ) {{ valueFormatter ? valueFormatter(value) : value }}
      slot(
        name='after'
        v-if='showAfter'
      )
        .multiselect__select
      slot(name='add')
</template>

<script>
export default {
  name: 'FakeInput',
  props: {
    inputShow: {
      default: false,
    },
    tag: {
      default: 'input-group',
    },
    model: {},
    valueFormatter: {
    },
    showAfter: {
      default: false,
    },
  },
  data() {
    return {
      show: false,
      value: this.model,
    }
  },
  watch: {
    model() {
      this.value = this.model
    },
  },
  methods: {
    focus() {
      this.show = true
      this.$nextTick(() => {
        const parent = this.$refs.visibleinput
        if (parent.$children[0].$children[0].$refs.input.focus) {
          parent.$children[0].$children[0].$refs.input.focus()
          return
        }
        parent.$children[0].$children[0].$refs.input.$el.focus()
      })
    },
    blur() {
      this.show = false
      this.$emit('update', this.value)
    },
  },
}
</script>
