/* eslint-disable */
const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    acc[x] = errors[x].map(messageGetter);
    return acc;
  }, {});
}

export default {
  data() {
    return {
      data: {},
      loading: false,
      nonFieldErrors: '',
    };
  },
  mounted() {
    if (this.$refs.validator && this.$refs.validator.$el) {
      this.$refs.validator.$el.addEventListener('blur', () => {
        if (this.$refs.validator) this.$refs.validator.reset();
        this.nonFieldErrors = '';
      }, true);
    }
  },
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400;
        const serverError = 500;
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e;
        }

        if (!this.updateValidator) {
          return e;
        }

        return e.json().then(body => {
          const errors = {};
          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state);
            }
          });
          
          this.updateValidator(errors);

          return e;
        });
      });
    },
    submit(valid, data) {
      if (!valid) {
        return Promise.reject();
      }

      if (this.loading) {
        return false
      }

      this.loading = true;

      return this.catchFormErrors(this.send(data))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

