/* eslint-disable no-restricted-globals */

const zero = 0

export const truncate = (text, stop, clamp) => text.slice(zero, stop) + (stop < text.length ? clamp || '...' : '')

export const normalizeDate = date => (
  date instanceof Date && !isNaN(date) && date || new Date()
);
export const parseDate = date => normalizeDate(new Date(date));
export const integer = value => (Number(value) || 0);

// const bitInKB = 8000
export const formatBytes = (bytes, decimals = 2) => {
  if (0 === bytes) return '0 B';

  const k = 1024;
  const dm = 0 > decimals ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm)) } ${ sizes[i]}`;
}
const BASE_SIZE = 2
export const dateTime = text => (text.toString().length === BASE_SIZE ? text : `${zero}${text}`)

export const install = Vue => {
  Vue.filter('integer', integer);
  Vue.filter('normalizeDate', normalizeDate);
  Vue.filter('parseDate', parseDate);
  Vue.filter('truncate', truncate);
  Vue.filter('dateTime', dateTime);
  Vue.filter('formatBytes', formatBytes);
};

export default { install };
