import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n'
import VueBem from '@aspectus/vue-bem'
// import VueDropzoneUploader from '@aspectus/vue-dropzone-uploader'
import VueDropzone from '@aspectus/vue-dropzone';
import VueDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Tag from '@aspectus/vue-tag'
import VueSelectionController from '@aspectus/vue-selection-controller';
import GridPlugin from '@aspectus/vue-grid';
import VModal from 'vue-js-modal'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import filters from '@utils/filters'

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(Tag)
  Vue.use(VueSelectionController);
  Vue.component('validation-provider', ValidationProvider);
  Vue.component('validation-observer', ValidationObserver);
  Vue.component('vue-date-picker', VueDatePicker);
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(VueDropzone, {
    name: 'vue-dropzone',
  });
  Vue.use(GridPlugin);
  Vue.use(VModal, { dynamic: true })
  Vue.use(VueRouter)
  Vue.use(filters)
}

export default { install }
