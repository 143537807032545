import ButtonsComponents from './Buttons'
import FormsComponents from './Forms'
import ControlsComponents from './Controls'
import SectionsComponents from './Sections'
import ElementsComponents from './Elements'
import ManagerComponents from './Manager/Orders'
import UsersListComponents from './Manager/Users'
import ShippingFormComponent from './Client/Shiping'
import SearchingFormComponent from './Client/Product'
import CheckingFormComponent from './Client/Checking'
import Utils from './Utils'
import General from './General'
import UserInterface from './UI'
import installPagination from './Pagination'
import PageIsNotFound from './Pages'

export default function plugin(Vue) {
  Vue.use(FormsComponents)
  Vue.use(ControlsComponents)
  Vue.use(ButtonsComponents)
  Vue.use(SectionsComponents)
  Vue.use(ElementsComponents)
  Vue.use(Utils)
  Vue.use(UserInterface)
  Vue.use(General)
  Vue.use(ManagerComponents)
  Vue.use(UsersListComponents)
  Vue.use(ShippingFormComponent)
  Vue.use(SearchingFormComponent)
  Vue.use(CheckingFormComponent)
  Vue.use(installPagination)
  Vue.use(PageIsNotFound)
}
