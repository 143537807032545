<template lang="pug">
  include /mixin.pug
  +b.ds-panel.--space-3xl
    +e.element.--offset_top
      vue-dropzone(
        ref="uploader"
        @drop="(e) => fileDrop(e)"
        @onLoad
      )
        template(
          #default="uploader"
        )
          +b.error-label.--avatar.LABEL(v-if="error")
            +e.P.element
              +b.ds-caption.--appearance_center {{error}}
          +b.BUTTON.button.--style_main-light.--style_main-avatar(
            @click.prevent="open"
            :disabled="isDisabled"
            :class="isDisabled? 'button--is-disabled' : '' "
              )
            +b.g-row.--justify_center.--align_center
              +b.g-cell.g-cols--narrow
                +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-accent
                  | {{ _('Change foto') }}
            +b.g-row.--justify_between.--align_center
              +b.g-cell.g-cols--narrow
                +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-accent(
                  v-for="file in uploader.accepted"
                  :key="file.id"
                  ) {{file.file.name}} {{uploader.accepted.length}}
</template>
<script>
import {
  getFile,
} from '@utils/file'

export default {
  name: 'FileUploader',
  props: {
    isDisabled: {
      default: false,
    },
  },
  data() {
    return {
      error: '',
      preLoadedFile: [],
      maxSizeUpload: 5 * 1024,
      value: [],
      maxLengthName: 44,
    }
  },
  computed: {
    config() {
      return { acceptor: this.acceptor };
    },
  },
  methods: {
    async fileDrop(e) {
      e.accepted.forEach(async el => {
        const fileElement = await getFile(el)
        const file = {
          preview: window.URL.createObjectURL(el),
          file: fileElement,
        }
        // this.list - твой массив с картинками
        // this.list.push(file)
        const x = /^[*/]+.JPEG|jpeg|jpg|PNG|png$/;
        if (!x.test(file.file.type)) {
          this.error = 'Inappropriate file type!'
        } else if (file.file.size > (3 * 1024) * 1024) {
          this.error = 'The file size is too large'
        } else {
          this.$emit('preLoadFilesHandler', {
            value: file.file,
          })
        }
        setTimeout(() => {
          this.error = ''
        }, 6000);
      })
    },
    log(data) {
      console.log(data)
    },
    uploadHandler({ result, descriptor }) {
      this.$refs.uploader.remove(descriptor);
      this.input(this.value.concat([result]));
    },
    input(data) {
      this.value = data;
    },
    open() {
      this.$refs.uploader.open();
    },
    remove(file) {
      const { id } = file;

      const index = this.value.findIndex(x => x.id === id);
      if (-1 !== index) {
        this.input(this.value.slice(0, index).concat(this.value.slice(index + 1)));
      }
    },
  },
}
</script>
<style lang="sass">
  .vue-dropzone__input
    display: none
</style>
