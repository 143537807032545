import './public-path'

import Vue from 'vue'
import Router from 'vue-router'
import I18n from 'vue-i18n'

import '@/js'

import {
  extend,
} from 'vee-validate';
import {
  required, confirmed, email,
  min, min_value as minValue, max, max_value as maxValue,
  ext, size,
} from 'vee-validate/dist/rules';
import { install } from '@/install'
import { createApp } from '@/app'
import appComponents from './js/components'

import './styles/index.sass'

Vue.config.productionTip = false

Vue.use(install)
Vue.use(appComponents)

const { app, i18n, router } = createApp({
  Vue,
  Router,
  I18n,
  // Store,
});

export { router };

function _(...arg) {
  return i18n.t(...arg);
}

extend('required_map', {
  validate(value, keys) {
    const invalid = keys.some(x => !required.validate(value && value[x]).valid);

    return { valid: !invalid, required: min.required || max.required };
  },
  computesRequired: true,
  message: app._('This field is required.'),
});

extend('only_сyrillic', {
  validate(value) {
    const SPECIAL_WORDS = /([~`!#$%\^&*+=\.\[\]\\';,/{}|\\":<>\?])/;
    return !SPECIAL_WORDS.test(value);
  },
  message: app._('Unacceptable symbols'),
})
extend('fraction_set', {
  validate(value) {
    const SPECIAL_WORDS = /^(?=.*\d)\d*(?:\.\d{0,2})?$/;
    return SPECIAL_WORDS.test(value);
  },
  message: app._('Unacceptable symbols'),
})
extend('required', { ...required, message: _('This field is required.') });
extend('confirmed', { ...confirmed, message: _('Passwords must match.') });
extend('min', { ...min, message: _('Value is too short.') });
extend('min_value', { ...minValue, message: _('Minimum value is {min}.') });
extend('max', { ...max, message: _('Value is too long.') });
extend('max_value', { ...maxValue, message: _('Maximum value is {max}.') });
extend('ext', { ...ext, message: _('Invalid file format') });
extend('email', { ...email, message: _('Email is invalid.') });
extend('size', { ...size, message: (e, a) => _('The file size must be less than ') + a.size / 1024 + _('Mb') });

extend('special_symbols', {
  validate(value) {
    const SPECIAL_WORDS = /([~`!#$%\^&*+=\.\[\]\\';,/{}|\\":<>\?])|(?=.*?[0-9])/;
    return !SPECIAL_WORDS.test(value);
  },
  message: app._('Unacceptable symbols'),
});

extend('password', {
  validate(value) {
    if (7 > value.length) return false;
    const PASSWORD_REG = /^(?=.*?[A-ZА-Я])(?=.*?[a-zа-я])(?=.*?[0-9]).*$/;
    return PASSWORD_REG.test(value);
  },
  computesRequired: true,
  message: app._('The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number.'),
});

app.$mount('#app')
