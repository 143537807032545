/* eslint-disable max-len */
<template lang="pug">
  include ../../../templates/mixin.pug
  label.d-control-input__label(
    :class="[{'d-control-input__label--active': value }]"
    :for='id',
  ) {{ inputLabel }}
    slot
    span(
      v-if='isRequired'
    ) *
</template>

<script>

export default {
  name: 'BoundLabel',
  block: 'd-control-input',
  props: {
    labelText: {},
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
  },
}
</script>
