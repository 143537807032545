<template lang="pug">
  include /mixin.pug
  +b.order-section.--full_height
    +e.element
      +b.g-row.--justify_center.--appearance_spaced.--space_xl-md.--align_end
        +b.g-cell.g-cols--12.--narrow-mac
          +b.ds-panel.--space_md
            +e.element.--offset_bottom.--offset_right
              +b.ds-caption.--size_lg.--bold.--appearance_start.--color_main.SPAN {{ _('PROFIT') }}
        +b.g-cell.g-cols--12.--6-md.--auto-xl(v-for="(stat, name, index) in statisticData" :key="stat.id")
          +b.ds-panel.--space_3xl
            +e.element.--offset_top
              +b.control-output.--style_main-light
                +e.label
                  +b.ds-caption.--size_xs.--light.--color_main {{statisticTitles[name]}}
                +b.ds-caption.--size_lg.--bold.--color_main-accent {{stat}}
                +b.ds-inliner--size_xl
                  +e.body
                    +b.icon-wrapper
                      +b.SVG.svg-icon.--color_main-accent(
                        :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/currency-usd.svg'"
                        )
    +e.element.--offset_horizontal.--offset_vertical
      +b.g-row.--justify_center.--appearance_spaced.--space_xl-md.--align_end(
        v-if="ordersType === 'shipping'"
      )
        +b.g-cell.g-cols--12.--narrow-mac
          +b.ds-panel.--space_md
            +e.element.--offset_bottom.--offset_right
              +b.ds-caption.--size_lg.--bold.--appearance_start.--color_main.SPAN {{ _('PROFIT') }}
        +b.g-cell.g-cols--12.--6-md.--auto-xl(v-for="(stat, name, index) in statisticShippingData" :key="stat.id")
          +b.ds-panel.--space_3xl
            +e.element.--offset_top
              +b.control-output.--style_main-light
                +e.label
                  +b.ds-caption.--size_xs.--light.--color_main {{statisticTitles[name]}}
                +b.ds-caption.--size_lg.--bold.--color_main-accent {{stat}}
                +b.ds-inliner--size_xl
                  +e.body
                    +b.icon-wrapper
                      +b.SVG.svg-icon.--color_main-accent(
                        :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/currency-usd.svg'"
                        )
      +b.g-row.--justify_center.--appearance_spaced.--space_xl-md.--align_end(
        v-else-if="ordersType === 'search'"
      )
        +b.g-cell.g-cols--12.--narrow-mac
          +b.ds-panel.--space_md
            +e.element.--offset_bottom.--offset_right
              +b.ds-caption.--size_lg.--bold.--appearance_start.--color_main.SPAN {{ _('PROFIT') }}
        +b.g-cell.g-cols--12.--6-md.--auto-xl(v-for="(stat, name, index) in statisticSearchData" :key="stat.id")
          +b.ds-panel.--space_3xl
            +e.element.--offset_top
              +b.control-output.--style_main-light
                +e.label
                  +b.ds-caption.--size_xs.--light.--color_main {{statisticTitles[name]}}
                +b.ds-caption.--size_lg.--bold.--color_main-accent {{stat}}
                +b.ds-inliner--size_xl
                  +e.body
                    +b.icon-wrapper
                      +b.SVG.svg-icon.--color_main-accent(
                        :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/currency-usd.svg'"
                        )
      +b.g-row.--justify_center.--appearance_spaced.--space_xl-md.--align_end(
        v-else-if="ordersType === 'check'"
      )
        +b.g-cell.g-cols--12.--narrow-mac
          +b.ds-panel.--space_md
            +e.element.--offset_bottom.--offset_right
              +b.ds-caption.--size_lg.--bold.--appearance_start.--color_main.SPAN {{ _('PROFIT') }}
        +b.g-cell.g-cols--12.--6-md.--auto-xl(v-for="(stat, name, index) in statisticCheckData" :key="stat.id")
          +b.ds-panel.--space_3xl
            +e.element.--offset_top
              +b.control-output.--style_main-light
                +e.label
                  +b.ds-caption.--size_xs.--light.--color_main {{statisticTitles[name]}}
                +b.ds-caption.--size_lg.--bold.--color_main-accent {{stat}}
                +b.ds-inliner--size_xl
                  +e.body
                    +b.icon-wrapper
                      +b.SVG.svg-icon.--color_main-accent(
                        :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/currency-usd.svg'"
                        )
    +e.element.--offset-bottom_2xs.--full_height
      +b.g-row.--justify_center
        +b.g-cell.g-cols--12
          +b.ds-panel.--space_none
            +e.element
              +b.g-row.--justify_center.--appearance_spaced.--space_xl-md.--align_center
                +b.g-cell.g-cols--12
                  +b.g-row.--space_none.--align_end.--justify_center.--justify_between-md
                    +b.g-cell.g-cols--12.--9-mac
                      +b.g-row.--space_none
                        +b.g-cell.g-cols--auto
                          +b.g-row.--space_xl
                            +b.g-cell.g-cols--12.--6-sm.--auto-xl(
                              v-for="(filter, name, index) in filters" :key="`field_${name}_${index}`"
                            )
                              template
                                +b.ds-panel.--space_3xl
                                  +e.element.--offset_top
                                    template(v-if="name === 'period'")
                                      +b.control-output--style_clear
                                        +e.label
                                          +b.ds-caption.--size_xs.--light.--color_main {{_('Over a period of time')}}
                                +b.TAG(
                                  @input="getList(form)"
                                  :tag="filters[name].tag"
                                  v-model='form[filter.name]',
                                  :name='name',
                                  type='select',
                                  v-bind="filters[name].props"
                                  :input-label='filters[name].label'
                                  :class='filters[name].class',
                                  :placeholder='filters[name].placeholder',
                                )
                    +b.g-cell.g-cols--narrow.--2-mac
                      +b.g-row.--space_none.--justify_end
                        +b.g-cell.g-cols--narrow
                          +b.ds-panel.--space_3xl
                            +e.element.--offset_top
                              router-link(
                                :to="{name: newOrderLink}"
                              )
                                +b.BUTTON.button.--style_main.--full_width
                                  +b.g-row.--justify_between.--align_center
                                    +b.g-cell.g-cols--auto
                                      +b.ds-inliner--size_xl
                                        +e.body
                                          +b.icon-wrapper
                                            +b.SVG.svg-icon.--color_main-white(
                                              :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/plus-box-outline.svg'"
                                              )
                                    +b.g-cell.g-cols--narrow
                                      +b.ds-caption.--size_sm.--bold.--appearance_start.--color_main-white
                                        | {{ _('Create order') }}
                +b.g-cell.g-cols--12.--scrollable
                  +b.ds-table.--style_cargos.--variant_hoverable
                    +e.head.THEAD
                      +e.row.TR
                        template(v-for="(item, name, index) in ordersTableSetting")
                          +e.cell.--offset_none.TH(v-if="name !== 'viewId'" :key="name")
                            +b.ds-caption.--size_xs.--bold.--appearance_start.--color_main-white.SPAN {{ordersTableSetting[name]}}
                    +e.body.TBODY
                      +e.row.--gray.TR(
                        :class="ordersColors[order.status]"
                        v-for="order in clientOrdersList"
                        :key="order.id"
                        )
                        template(
                            v-for="(element, name, index) in ordersTableSetting"
                            )
                          +e.cell.TD(:key="name" v-if="name !== 'id'" )
                            +b.ds-panel.--space_sm
                              +e.element.--offset_full
                                template(v-if="name === 'viewId'")
                                  +b.ROUTER-LINK.ds-caption.--hovered_underline.--size_sm.--light.--appearance_start.--color_main-accent(
                                    :to="{name: currentRouteLink, params: {id: order['id'], vId: order.viewId}}"
                                  )
                                    | {{order.viewId? "№"+ order.viewId : ''}}
                                template(v-else-if="name === 'client'")
                                  +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.SPAN
                                    | {{order[name].firstName}} {{order[name].lastName}}
                                template(v-else-if="name === 'counterparty'")
                                  +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.SPAN
                                    | {{order.counterparty? order.counterparty.name : '-'}}
                                template(v-else-if="name === 'buyer'")
                                  +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.SPAN(
                                    v-if="order.buyer"
                                  ) {{order.buyer.firstName || ''}} {{order.buyer.lastName || ''}}
                                  +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.SPAN(
                                    v-else
                                  ) -
                                template(v-else-if="name === 'deliveryCountry'")
                                  +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.SPAN
                                    | {{order[name] !== undefined? 'CHN - ' + order[name] : ''}}
                                template(v-else-if="name === 'invoiceSet'")
                                  +b.ds-panel.--space_none
                                    +e.element
                                      +b.ds-caption.--size_sm.--appearance_start.--color_main.P
                                        +b.ds-caption {{order.clientAmount}}
                                        +b.ds-caption.--light /
                                        +b.ds-caption.--light(
                                          :class="order.clientAmount !== order.clientPaid? 'ds-caption--color_accent-red' : 'ds-caption--color_lite-green'"
                                        ) {{order.clientPaid}}
                                    +e.element(v-for="item in order.invoiceSet")
                                      +b.link.A(
                                        :href="item.source"
                                        download
                                      )
                                        +b.ds-caption.--size_sm.--light.--hovered_underline.--color_main-accent {{item.filename}}
                                template(v-else-if="name === 'contractorDocs'")
                                  +b.ds-caption.--size_sm.--appearance_start.--color_main.P
                                    +b.ds-caption {{order.buyerAmount}}
                                    +b.ds-caption.--light /
                                    +b.ds-caption.--light(
                                      :class="order.buyerAmount !== order.buyerPaid? 'ds-caption--color_accent-red' : 'ds-caption--color_lite-green'"
                                    ) {{order.buyerPaid}}
                                template(v-else-if="name === 'counterpartyDocs'")
                                  +b.ds-caption.--size_sm.--appearance_start.--color_main.P
                                    +b.ds-caption {{order.counterpartyPaid}}
                                    +b.ds-caption.--light /
                                    +b.ds-caption.--light(
                                      :class="order.counterpartyPaid !== order.counterpartyAmount? 'ds-caption--color_accent-red' : 'ds-caption--color_lite-green'"
                                    ) {{order.counterpartyAmount}}
                                template(v-else-if="name === 'totalVolume'")
                                  +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.P
                                    | {{order['totalVolume']}} {{'m3'}}
                                  +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.P
                                    | {{order['totalWeight']}} {{'Kg'}}
                                template(v-else-if="name === 'buyerStatus'")
                                  +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.SPAN
                                    | {{order[name] === '0001'? 'Not accepted' : 'Accepted'}}
                                template(v-else)
                                  +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.SPAN
                                    | {{order[name]}}
    +e.element
      +b.g-cell.g-cols--12
        +b.g-row.--justify_start.--align_center
          +b.g-cell.g-cols--narrow.UI-LIMIT-PAGINATION(
            :pagination='pagination'
            @input='handlePaginate'
          )
    </template>
<script>
import {
  getSearchOrdersList,
  getCheckOrdersList,
  getShippingOrdersList,
  getStatusesList,
  statistic,
  statisticType,
  getPaymentStatuses,
} from '@services/auth.service.js';
import { clone } from 'ramda';
import {
  NEW_SHIPPING_ORDER_NAME,
  NEW_SEARCH_ORDER_NAME,
  NEW_CHECKING_ORDER_NAME,
  MANAGER_SEARCH_ORDER_NAME,
  MANAGER_CHECKING_ORDER_NAME,
  MANAGER_SHIPPING_ORDER_NAME,
} from '../routes'
// import FormMixin, { defaultValidatorErrorsParser } from '../../Forms/FormMixin'

export default {
  name: 'ClientOrders',
  props: ['ordersType'],
  // mixins: [FormMixin],
  data() {
    return {
      pagination: {},
      newOrderLink: '',
      loadDataLink: '',
      currentRouteLink: '',
      ordersTableSetting: {
        id: this._('Order'),
        viewId: this._('Order'),
        statusDisplayName: this._('Status'),
        createdAt: this._('Date'),
        client: this._('Client'),
        counterparty: this._('Сotractor'),
        totalVolume: this._('Cargo'),
        // deliveryCountry: this._('Route'),
        clientAmount: this._('Cost/Сlient (USD)'),
        counterpartyAmount: this._('Cost/Сotractor (USD)'),
        orderProfit: this._('Profit(USD)'),
        invoiceSet: this._('Client documents'),
        counterpartyDocs: this._('Сotractor documents'),
      },
      searchingTable: {
        id: this._('Order'),
        viewId: this._('Order id'),
        statusDisplayName: this._('Status'),
        createdAt: this._('Date'),
        client: this._('Client'),
        buyer: this._('Bayer'),
        productName: this._('Product'),
        buyerStatus: this._('Buyer response'),
        clientAmount: this._('Cost/Сlient (USD)'),
        buyerAmount: this._('Cost/Buyer (USD)'),
        orderProfit: this._('Profit(USD)'),
        invoiceSet: this._('Client documents'),
        contractorDocs: this._('Payment to the buyer'),
      },
      checkingTable: {
        id: this._('Order'),
        viewId: this._('Order'),
        statusDisplayName: this._('Status'),
        createdAt: this._('Date'),
        client: this._('Client'),
        checkTypeTitle: this._('Object'),
        title: this._('Name'),
        address: this._('Address'),
        checkDate: this._('Check date'),
        clientAmount: this._('Cost/Сlient (USD)'),
        // orderProfit: this._('Profit(USD)'),
        invoiceSet: this._('Client documents'),
      },

      filterSettings: [],
      statisticData: {},
      statisticShippingData: {},
      statisticSearchData: {},
      statisticCheckData: {},
      // statisticTitles: {
      //   amountOrders: this._('The total amount of all orders (USD)'),
      //   amountPayments: this._(`Total payments  ${'search' === this.ordersType ? 'to buyers' : 'to contractors'} (USD)`),
      //   amountPaymentsBuyers: this._('Total payments to buyers (USD)'),
      //   amountPaymentsContractors: this._('Total payments to cotractors (USD)'),
      //   amountProfit: this._('Total profit (USD)'),
      // },
      clientOrdersList: [],
      form: {
        type: '',
        status: '',
        paymentStatus: '',
        period: '',
      },
      statusList: [],
      filters: {
        keywords: {
          name: 'search',
          model: '',
          tag: 'd-control-input',
          class: 'd-control-input--style_main-light',
          label: this._('Search'),
          placeholder: this._('Client / Counterparty / Order'),
        },
        status: {
          name: 'status',
          model: '',
          tag: 'd-control-multiselect',
          class: 'd-control-multiselect d-control-multiselect--style_main d-control-multiselect--style_light',
          label: this._('Order status'),
          placeholder: this._('Select status'),
          props: {
            options: [
              { title: this._('View all'), code: '' },
            ],
            label: 'title',
            trackBy: 'title',
            searchable: false,
            allowEmpty: false,
          },
        },
        paymentStatus: {
          name: 'paymentStatus',
          model: '',
          tag: 'd-control-multiselect',
          class: 'd-control-multiselect d-control-multiselect--style_main d-control-multiselect--style_light',
          label: this._('Customer payment status'),
          placeholder: this._('Select status'),
          props: {
            options: [
              { title: this._('View all'), code: '' },
            ],
            label: 'title',
            trackBy: 'title',
            searchable: false,
            allowEmpty: false,
          },
        },
        period: {
          name: 'period',
          props: {
            valueType: 'YYYY-MM-DD',
            range: true,
          },
          label: this._('Over a period of time'),
          placeholder: this._('Select period'),
          rules: 'required',
          tag: 'vue-date-picker',
        },
      },
      ordersColors: {
        '0001': '',
        '0002': 'main-light',
        '0003': 'lite-green',
        '0004': 'main-gray',
      },
    }
  },
  computed: {
    statisticTitles() {
      const result = {
        amountOrders: this._('The total amount of all orders (USD)'),
        amountPaymentsBuyers: this._('Total payments to buyers (USD)'),
        amountPaymentsContractors: this._('Total payments to cotractors (USD)'),
        amountProfit: this._('Total profit (USD)'),
      }
      if ('search' === this.ordersType) {
        result.amountPayments = this._('Total payments to buyers (USD)')
      } else {
        result.amountPayments = this._('Total payments to cotractors (USD)')
      }
      return result
    },
  },
  methods: {
    getStatistic() {
      statistic.execute().then(res => {
        this.statisticData = res.data.item
      })
    },
    getStatisticShipping() {
      statisticType.execute({ type: 'shipping' }).then(res => {
        this.statisticShippingData = res.data.item
      })
    },
    getStatisticSearch() {
      statisticType.execute({ type: 'search' }).then(res => {
        this.statisticSearchData = res.data.item
      })
    },
    getStatisticCheck() {
      statisticType.execute({ type: 'checking' }).then(res => {
        delete res.data.item.amountPayments
        delete res.data.item.amountProfit
        this.statisticCheckData = res.data.item
      })
    },
    typeOrdersCheck() {
      if ('search' === this.ordersType) {
        this.loadDataLink = getSearchOrdersList
        this.currentRouteLink = MANAGER_SEARCH_ORDER_NAME
        this.newOrderLink = NEW_SEARCH_ORDER_NAME
        this.ordersTableSetting = this.searchingTable
      } else if ('check' === this.ordersType) {
        this.loadDataLink = getCheckOrdersList
        this.currentRouteLink = MANAGER_CHECKING_ORDER_NAME
        this.newOrderLink = NEW_CHECKING_ORDER_NAME
        this.ordersTableSetting = this.checkingTable
      } else {
        this.loadDataLink = getShippingOrdersList
        this.currentRouteLink = MANAGER_SHIPPING_ORDER_NAME
        this.newOrderLink = NEW_SHIPPING_ORDER_NAME
      }
    },
    getPaymentStatuses() {
      getPaymentStatuses.execute().then(res => {
      // eslint-disable-next-line
        for (const key in res.data.item) {
          this.filters.paymentStatus.props.options.push({ code: key, title: res.data.item[key] })
        }
      });
    },
    getStatuses() {
      getStatusesList.execute().then(res => {
      // eslint-disable-next-line
        for (const key in res.data.item) {
          this.filters.status.props.options.push({ code: key, title: res.data.item[key] })
        }
      });
    },
    // payment_status,created_from,created_to
    getList(params) {
      const transformedData = clone(params)
      if (params) {
        transformedData.instance_of = params?.type?.code
        transformedData.payment_status = params?.paymentStatus?.code
        transformedData.status = params?.status?.code
        const [createdFrom, createdTo] = params?.period
        transformedData.created_from = createdFrom
        transformedData.created_to = createdTo
      }
      // transformedData = { limit: 10 }
      this.loadDataLink.execute(transformedData).then(res => {
        this.clientOrdersList = res.data.items
        this.pagination = res.data.pagination
        res.data.items.forEach(element => {
          element.link = this.searchOrderLink
        });
      });
    },
    handlePaginate(params) {
      this.getList({ ...this.form, ...params })
    },
  },
  mounted() {
    this.typeOrdersCheck()
    this.getStatistic()
    this.getStatisticShipping()
    this.getStatisticSearch()
    this.getStatisticCheck()
    this.getList()
    this.getStatuses()
    this.getPaymentStatuses()
  },
}
</script>
