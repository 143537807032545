<template lang="pug">
  include /mixin.pug
  +b.page-not-found-section
    +e.element
      +b.header
        header-section(
          :not-found="true"
        )
      +e.wrapper
        +e.title
          +b.ds-caption.--bold.--variant_uppercase.--color_main.--appearance_center {{_('Page not found')}}
        +e.subtitle
          +b.P.ds-caption.--size_sm.--color_main.--light.--appearance_center {{_('The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.')}}
        +e.button
          +b.ds-link(href="/")
            +b.BUTTON.button.--style_main
              +b.g-row.--justify_center.--align_center
                +b.g-cell.g-cols--narrow
                  +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-white {{_('Back home')}}
      +e.footer
        footer-section
</template>
<script>

export default {
  name: 'NotFoundPage',
  data() {
    return {
    }
  },
  methods: {

  },

}
</script>
