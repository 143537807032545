<template lang="pug">
  include ../../../templates/mixin.pug
  +b.ds-section.--space_none.--relative
    +e.element.--style_main-form
    +e.element
      +b.g-row.--justify_center.--space_none.--align_center.--full-screen_height
        +b.g-cell.g-cols--12
          +b.g-row.--justify_center.--space_none.--align_center.--relative.--full-screen_height-till-sm
            +b.g-cell.g-cols--auto.--absolute.--layer-position_under.--full_height.--max-width_sm
              +b.img-wrap
                +e.IMG.img.--border-radius_sm(alt="Form background" src='../../../../../server/markup/static/img/auth-form-bg.png')
            +b.g-cell.g-cols--10.--9-sm.--max-width_md
              +b.ds-section.--space_md.--space_xl-md
                +e.element
                  +b.VALIDATION-OBSERVER(
                    ref="validator",
                    v-slot="{ handleSubmit }",
                    slim,
                    ): +b.FORM.d-control-form--main(@submit.prevent="handleSubmit(prepareData)")
                    +b.g-row.--justify_center.--appearance_column.--align_center
                      +b.g-cell.g-cols--narrow.--8-sm.--6-md
                        +b.ds-panel.--space_xl
                          +e.element.--offset_bottom
                            +b.ds-caption.--size_xl.--appearance_center.--appearance_left-sm.--bold.--color_main-white.--variant_uppercase {{_('New Password')}}
                            +b.error-label.LABEL(v-if="nonFieldErrors")
                          +e.element.--offset_bottom.--indent_inside
                      +b.g-cell.g-cols--narrow.--8-sm.--6-md
                        +b.ds-panel.--space_xl
                          +e.element.--offset_bottom
                            d-control-input(
                              v-model='form.newPassword1',
                              rules='required',
                              name='newPassword1',
                              type='password',
                              class="d-control-input--style_main"
                              :input-label=`_("Password")`
                              :placeholder=`_("Password")`,
                            )
                          +e.element.--offset_vertical.--indent_inside
                            d-control-input(
                              v-model='form.newPassword2'
                              rules='required',
                              name='newPassword2',
                              type='password',
                              class="d-control-input--style_main"
                              :input-label=`_("Repeat password")`,
                              :placeholder=`_("Password")`,
                            )
                          +e.element.--offset_bottom
                            +b.g-row.--justify_center
                              +b.g-cell.g-cols--12
                                +b.ds-panel.--space_lg
                                  +e.element.--offset_top
                                    +b.BUTTON.button.--style_main.--full_width(type="submit")
                                      +b.ds-caption.--size_md.--appearance_center.--bold.--color_main-white Send
</template>
<script>
import {
  newPassSet,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from './FormMixin'

export default {
  name: 'NewPassForm',
  mixins: [FormMixin],
  props: {
    auth_link: {
      type: [String, Number],
      required: true,
      default: '',
    },
    uid: {
      type: [String, Number],
      required: true,
      default: '',
    },
    token: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      regNewPassConfig: {
        props: {
          title: this._('New password is confirmed'),
          subtitle: this._('Now you can login with new password'),
          path: this.auth_link,
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      location: '',
      nonFieldErrors: '',
      form: {
        newPassword1: '',
        newPassword2: '',
        uid: this.uid,
        token: this.token,
      },
    }
  },
  methods: {
    modalOpener,
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return
        this.submit(valid, this.form)
      })
    },
    errorsReset() {
      this.nonFieldErrors = ''
    },
    send(data) {
      return newPassSet.execute({}, data).then(res => {
        if (202 === res.code) {
          this.modalOpener(this.regNewPassConfig)
          this.$refs.validator.reset();
        }
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.nonFieldErrors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>
