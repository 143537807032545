/* eslint-disable */
import ModalBaseTemplate from '@components/UI/ModalTemplate.vue';

export function modalOpener({
  modal = ModalBaseTemplate,
  props = {},
  actor = () => { },
  value = undefined,
  classes = [],
  config = {},
}) {
  const promise = arg => new Promise((resolve, reject) => {
    if (arg) {
      resolve(arg);
      return;
    }
    reject();
  }).then(result => {
    actor(value || result);
  });

  this.$modal.show(
    modal,
    { ...props, promise }, {
      classes,
      ...config,
    }
  );
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
