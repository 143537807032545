import AuthForm from './AuthForm'
import RegForm from './RegForm'
import PassRecoveryForm from './PassRecoveryForm'
import NewPassForm from './NewPassForm'
// import AddShippingForm from './AddShippingForm.vue'
import AddManagerForm from './AddManagerForm.vue'
import AddContractorsForm from './AddContractorsForm.vue'
import ShippingTemplate from './ShippingTemplate.vue'
import ProfileTemplate from './ProfileTemplate.vue'
import ProductSearchTemplate from './ProductSearchTemplate.vue'
import CheckProductCompanyTemplate from './CheckProductCompanyTemplate.vue'

export default function install(Vue) {
  Vue.component('AuthForm', AuthForm)
  Vue.component('RegForm', RegForm)
  Vue.component('PassRecoveryForm', PassRecoveryForm)
  Vue.component('NewPassForm', NewPassForm)
  // Vue.component('AddShippingForm', AddShippingForm)
  Vue.component('AddManagerForm', AddManagerForm)
  Vue.component('AddContractorsForm', AddContractorsForm)
  Vue.component('ProfileTemplate', ProfileTemplate)
  Vue.component('ProductSearchTemplate', ProductSearchTemplate)
  Vue.component('CheckProductCompanyTemplate', CheckProductCompanyTemplate)
  Vue.component('ShippingTemplate', ShippingTemplate)
}
