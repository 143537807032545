import {
  patchResource,
  createResource,
  postResource,
  deleteResource,
  putResource,
} from '@resource/resource'
import {
  simpleTransformer,
} from '@resource/transformer'
import { prefixAPI } from '@resource/api'

const AUTH_LINK = prefixAPI('auth/login/')
const REGISTER_LINK = prefixAPI('auth/register/')
const PASSRESET_LINK = prefixAPI('auth/password-reset/')
const SETNEWPASS_LINK = prefixAPI('auth/password-reset/set/')
const LOGOUT_LINK = prefixAPI('auth/logout/')

const GETACCOUNT_LINK = prefixAPI('account/')
const ACCOUNTCHANGE_LINK = prefixAPI('account/change/')
const EMAILCHANGE_LINK = prefixAPI('account/email-change/')
const PHONEDELETE_LINK = prefixAPI('account/phone/delete/{id}/')
const GETPHONESLIST_LINK = prefixAPI('account/phones/')
const PHONECREATE_LINK = prefixAPI('account/phone/create/')
const AVATARADD_LINK = prefixAPI('account/change/avatar/')

const GETCOUNTRIESLIST_LINK = prefixAPI('base/countries/list/')
const IMAGECREATE_LINK = prefixAPI('base/image/create/')
const IMAGEDESTROY_LINK = prefixAPI('base/image/destroy/{id}/')
const FILECREATE_LINK = prefixAPI('base/document/create/')
const DESTROYFILE_LINK = prefixAPI('base/document/destroy/{id}/')
const GETTRANSPORTATIONTYPES_LINK = prefixAPI('base/transportationtype/list/')
const GET_STATUSES_LIST_LINK = prefixAPI('base/statuses/list/')
const GET_CHECK_TYPES_LIST = prefixAPI('base/checktype/list/')
const GET_PAYMENT_STATUSES_LIST = prefixAPI('base/payment-statuses/list/')

const GETORDERSLIST_LINK = prefixAPI('client/order/list/{?status,resourcetype,limit,offset}')
const PRODUCTCHECK_LINK = prefixAPI('client/order/check/create/')
const PRODUCTSEARCH_LINK = prefixAPI('client/order/search/create/')
const CLIENTSEARCHORDER_LINK = prefixAPI('client/order/search/retrieve/{id}/')
const CLIENTCHECKORDER_LINK = prefixAPI('client/order/check/retrieve/{id}/')
const CLIENTSHIPPINGORDER_LINK = prefixAPI('client/order/transportation/retrieve/{id}/')
const TRANSPORTATIONCREATE_LINK = prefixAPI('client/order/transportation/create/')

const GETBUEYSRLIST_LINK = prefixAPI('users/buyers/{?is_active,search,limit,offset}')
const GETCLIENTSLIST_LINK = prefixAPI('users/clients/{?is_active,search,limit,offset}')
const CLIENTS_LIST_SIMPLE_LINK = prefixAPI('users/clients/simple/')

const UPDATE_SHIPPING_ORDER_LINK = prefixAPI('manager/order/transportation/update/{id}/')
const UPDATECHECKORDER_LINK = prefixAPI('manager/order/check/update/{id}/')
const GETCHECKINGORDER_LINK = prefixAPI('manager/order/check/retrieve/{id}/')
const UPDATE_SEARCH_ORDER_LINK = prefixAPI('manager/order/search/update/{id}/')
const GETSEARCHORDER_LINK = prefixAPI('manager/order/search/retrieve/{id}/')
const GETSHIPPINGORDER_LINK = prefixAPI('manager/order/transportation/retrieve/{id}/')
const REGBUYER_LINK = prefixAPI('manager/register/buyer/')
const SEARCHORDERSLIST_LINK = prefixAPI('manager/order/search/list/{?search,status,payment_status,created_from,created_to,limit,offset}')
const CHECKORDERSLIST_LINK = prefixAPI('manager/order/check/list/{?search,status,payment_status,created_from,created_to,limit,offset}')
const SHIPPINGHORDERSLIST_LINK = prefixAPI('manager/order/transportation/list/{?search,status,payment_status,created_from,created_to,limit,offset}')
const COUNTERPARTYDOCCREATE_LINK = prefixAPI('manager/documents/counterpartydocument/create/')
const MANAGER_DOCCREATE_LINK = prefixAPI('manager/documents/managerdocument/create/')
const TRANSPORTATION_SEND_LINK = prefixAPI('manager/order/transportation/send/{id}/')
const COPY_ORDER_DATA_LINK = prefixAPI('manager/order/transportation/retrieve/text/{id}/')
const CONFIRM_ORDER_REPORT_LINK = prefixAPI('manager/order/search/{id}/report/')
const REPORT_CREATE_LINK = prefixAPI('manager/documents/report/create/')
const MANAGER_SHIPPING_ORDER_CREATE_LINK = prefixAPI('manager/order/transportation/create/')
const MANAGER_SEARCHING_ORDER_CREATE_LINK = prefixAPI('manager/order/search/create/')
const MANAGER_CHECKING_ORDER_CREATE_LINK = prefixAPI('manager/order/check/create/')

const PAYMENT_SERVICES_LIST_LINK = prefixAPI('payments/payment/service/list')
const PAYMENT_INVOICE_CREATE_LINK = prefixAPI('payments/invoice/create/')
const PAYMENT_INVOICE_GET_LINK = prefixAPI('payments/invoice/{id}/')
const PAYMENT_PAYMENT_CREATE_LINK = prefixAPI('payments/payment/create/')

const GETCOUNTERPARTYLIST_LINK = prefixAPI('counterparty/list/{?search,payment_status,limit,offset}')
const COUNTERPARTYADD_LINK = prefixAPI('counterparty/create/')
const COUNTERPARTYDELL_LINK = prefixAPI('counterparty/delete/{id}/')

const BUYERORDERSLIST_LINK = prefixAPI('buyer/order/list/{?limit,offset}')
const BUYERORDERVIEW_LINK = prefixAPI('buyer/order/detail/{id}/')
const BUYERREPORTCREATE_LINK = prefixAPI('buyer/order/report/create/')
const BUYERORDERACCEPT_LINK = prefixAPI('buyer/order/accept/{id}/')
const BUYERORDERUPDATE_LINK = prefixAPI('buyer/order/update/{id}/')

const NOTIFICATIONCOUNTER_LINK = prefixAPI('notification/new/')
const GETNITIFICATIONS_LINK = prefixAPI('notification/list/{?is_seen,limit,offset}')
const NOTIFICATIONVIEW_LINK = prefixAPI('notification/view/{id}/')

const STATISTIC_LINK = prefixAPI('statistic/')
const STATISTIC_TYPE = prefixAPI('statistic/{type}/')
const GETUSERDATA_LINK = prefixAPI('users/{id}/')

export const getCounterpartyList = createResource(GETCOUNTERPARTYLIST_LINK)
export const delCounterparty = createResource(COUNTERPARTYDELL_LINK, postResource)
export const addCounterparty = createResource(COUNTERPARTYADD_LINK, postResource)

export const getCheckTypesList = createResource(GET_CHECK_TYPES_LIST)
export const getClientsListSimple = createResource(CLIENTS_LIST_SIMPLE_LINK)
export const getClientsList = createResource(GETCLIENTSLIST_LINK)
export const getBuyerList = createResource(GETBUEYSRLIST_LINK)
export const buyerRegistration = createResource(REGBUYER_LINK, postResource)
export const getSearchOrdersList = createResource(SEARCHORDERSLIST_LINK)
export const getCheckOrdersList = createResource(CHECKORDERSLIST_LINK)
export const getShippingOrdersList = createResource(SHIPPINGHORDERSLIST_LINK)
export const getShippingOrderData = createResource(GETSHIPPINGORDER_LINK)
// orders
// admin
export const managerShippingCreate = createResource(MANAGER_SHIPPING_ORDER_CREATE_LINK, postResource)
export const managerSearchingCreate = createResource(MANAGER_SEARCHING_ORDER_CREATE_LINK, postResource)
export const managerCheckingCreate = createResource(MANAGER_CHECKING_ORDER_CREATE_LINK, postResource)

export const copyShippingOrderData = createResource(COPY_ORDER_DATA_LINK)
export const shippingOrderUpdate = createResource(UPDATE_SHIPPING_ORDER_LINK, patchResource)
export const updateOrderCheck = createResource(UPDATECHECKORDER_LINK, patchResource)
export const searchOrderUpdate = createResource(UPDATE_SEARCH_ORDER_LINK, patchResource)
export const confirmOrderReport = createResource(CONFIRM_ORDER_REPORT_LINK, postResource)
export const reportDocCreate = createResource(REPORT_CREATE_LINK, postResource)

export const getPaymentStatuses = createResource(GET_PAYMENT_STATUSES_LIST)
// -------
export const getClientOrderSearch = createResource(CLIENTSEARCHORDER_LINK)
export const getClientOrderCheck = createResource(CLIENTCHECKORDER_LINK)
export const getClientOrderShipping = createResource(CLIENTSHIPPINGORDER_LINK)
export const getOrderSearch = createResource(GETSEARCHORDER_LINK)
export const getOrderCheck = createResource(GETCHECKINGORDER_LINK)
export const getOrderDataForBuyer = createResource(BUYERORDERVIEW_LINK)
export const getInvoice = createResource(PAYMENT_INVOICE_GET_LINK)
export const updateInvoice = createResource(PAYMENT_INVOICE_GET_LINK, putResource)

export const createBuyerReport = createResource(BUYERREPORTCREATE_LINK, postResource)
export const acceptBuyerOrder = createResource(BUYERORDERACCEPT_LINK)
export const updateBuyerOrder = createResource(BUYERORDERUPDATE_LINK, patchResource)

export const counterpartyDocCreate = createResource(COUNTERPARTYDOCCREATE_LINK, postResource)
export const managerDocCreate = createResource(MANAGER_DOCCREATE_LINK, postResource)

export const deletePhone = createResource(PHONEDELETE_LINK, deleteResource, simpleTransformer)
export const getPhonesList = createResource(GETPHONESLIST_LINK)
export const phoneAdd = createResource(PHONECREATE_LINK, postResource)
export const avatarAdd = createResource(AVATARADD_LINK, patchResource)
export const getCountiesList = createResource(GETCOUNTRIESLIST_LINK)

export const registerResource = createResource(REGISTER_LINK, postResource)
export const loginResource = createResource(AUTH_LINK, postResource)
export const passReset = createResource(PASSRESET_LINK, postResource)
export const newPassSet = createResource(SETNEWPASS_LINK, postResource)
export const logOutResource = createResource(LOGOUT_LINK, postResource)
export const changeEmail = createResource(EMAILCHANGE_LINK, postResource)
export const changeAccount = createResource(ACCOUNTCHANGE_LINK, patchResource)
export const getAccount = createResource(GETACCOUNT_LINK)

export const loadBayerOrdersList = createResource(BUYERORDERSLIST_LINK)
export const loadOrdersList = createResource(GETORDERSLIST_LINK)
export const productCheck = createResource(PRODUCTCHECK_LINK, postResource)
export const productSearch = createResource(PRODUCTSEARCH_LINK, postResource)
export const getTransportationTypes = createResource(GETTRANSPORTATIONTYPES_LINK)
export const createTransportation = createResource(TRANSPORTATIONCREATE_LINK, postResource)

export const creatingImage = createResource(IMAGECREATE_LINK, postResource)
export const deleteImage = createResource(IMAGEDESTROY_LINK, deleteResource, simpleTransformer)
export const creatingFile = createResource(FILECREATE_LINK, postResource)
export const deleteFile = createResource(DESTROYFILE_LINK, deleteResource, simpleTransformer)

export const getUsersData = createResource(GETUSERDATA_LINK)
export const statistic = createResource(STATISTIC_LINK)
export const statisticType = createResource(STATISTIC_TYPE)
export const getNotifications = createResource(GETNITIFICATIONS_LINK)
export const notificationView = createResource(NOTIFICATIONVIEW_LINK)
export const notificationCounter = createResource(NOTIFICATIONCOUNTER_LINK)

export const getStatusesList = createResource(GET_STATUSES_LIST_LINK)
export const getPaymentServicesList = createResource(PAYMENT_SERVICES_LIST_LINK)
export const createInvoice = createResource(PAYMENT_INVOICE_CREATE_LINK, postResource)
export const paymentCreate = createResource(PAYMENT_PAYMENT_CREATE_LINK, postResource)
export const sendOrder = createResource(TRANSPORTATION_SEND_LINK, postResource)
