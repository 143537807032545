import UserBar from './UserBar.vue'
import SideBar from './SideBar.vue'
import LanguageBar from './LanguageBar'
import NotificationsListTemplate from './NotificationsListTemplate.vue'

export default function install(Vue) {
  Vue.component('UserBar', UserBar)
  Vue.component('SideBar', SideBar)
  Vue.component(LanguageBar.name, LanguageBar)
  Vue.component('NotificationsListTemplate', NotificationsListTemplate)
}
