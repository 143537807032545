<template lang="pug">
  include ../../../templates/mixin
  +b.modal-inner
    +b.VALIDATION-OBSERVER(
      ref="validator",
      v-slot="{ handleSubmit }",
      slim,
    )
      +b.FORM.d-control-form--main(@submit.prevent="handleSubmit(prepareData)")
        +b.g-row.--justify_center.--appearance_spaced.--space_none.--space_3xl-md
          +b.g-cell.g-cols--12
            +b.ds-panel.--space_md
              +e.element.--offset_bottom
                d-control-input(
                  v-model='form.email',
                  rules='required',
                  name='email',
                  type='email'
                  class="d-control-input--style_main-light"
                  :input-label=`_("E-mail")`
                  :placeholder=`_("E-mail")`,
                )
          +b.g-cell.g-cols--12(v-if="nonFieldErrors")
            +b.ds-caption.--appearance_center
              +b.error-label.LABEL {{ nonFieldErrors[0] }}
          +b.g-cell.g-cols--12
            +b.BUTTON.button.--style_main.--full_width(type="submit")
              +b.ds-caption.--size_md.--appearance_center.--bold.--color_main-white {{ _('Send') }}
</template>
<script>
import {
  passReset,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from './FormMixin'

export default {
  props: ['promise'],
  name: 'PassRecoveryForm',
  mixins: [FormMixin],
  data() {
    return {
      passRecoveryModalConfig: {
        props: {
          title: this._('Password Reset Email Sent'),
          subtitle: this._('An email has been sent to yor  email address. Follow the direction in the mail to reset your password'),
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      location: '',
      nonFieldErrors: '',
      form: {
        email: '',
      },
    }
  },
  methods: {
    modalOpener,
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return
        this.submit(valid, this.form)
      })
    },
    errorsReset() {
      this.nonFieldErrors = ''
    },
    send(data) {
      return passReset.execute({}, data).then(res => {
        if (200 === res.code) {
          this.closeModal()
          this.modalOpener(this.passRecoveryModalConfig)
        }
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.nonFieldErrors
      this.$refs.validator.setErrors(parsed)
    },
    closeModal() {
      this.$emit('close');
    },
  },
}
</script>
