<template lang="pug">
  include /mixin.pug
  +b.order-section.--full_height
    +e.element.--full_height.--offset-bottom_none
      +b.g-row.--space_md.--appearance_spaced
        template(v-if="notificationsData.length === 0")
          +b.g-cell.g-cols--12
            +b.g-row.--space_none.--justify_center
              +b.g-cell.g-cols--narrow
                +b.ds-caption.--size_lg.--bold.--appearance_start.--color_main.--no-wrap.SPAN
                  | {{_(' There are no notifications at the moment.')}}
        template(v-for="(item, index) in notificationsData")
          +b.g-cell.g-cols--12
            +b.ds-panel.--space_md(
                  v-if="index == 0 || item.date != notificationsData[index - 1 ].date"
                )
              +e.element.--offset_bottom(
                :class="index === 0? 'ds-panel__element--offset_bottom' : 'ds-panel__element--offset_vertical'"
              )
                +b.ds-caption.--size_sm.--appearance_start.--color_main.--no-wrap.SPAN {{item.date}}
            +b.notification
              +b.g-row.--align_center.--full_width
                +b.g-cell.g-cols--auto
                  +b.g-row.--space_md.--appearance_spaced.--align_center
                    +b.g-cell.g-cols--narrow
                      +b.ds-inliner--size_2xl
                        +e.body
                          +b.icon-wrapper
                            +b.SVG.svg-icon.--color_main-accent.--size_sm-special(data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/clock-time-five-outline.svg")
                    +b.g-cell.g-cols--narrow
                      +b.ds-caption.--size_lg.--bold.--appearance_start.--color_main.--no-wrap.SPAN {{item.time}}
                    +b.g-cell.g-cols--narrow
                      +b.ds-caption.--size_sm.--appearance_start.--color_main.--no-wrap.SPAN {{item.notificationText}}
                    +b.g-cell.g-cols--narrow
                      +b.ds-caption.--size_sm.--appearance_start.--color_main-accent.--hovered_underline.--no-wrap.cur-pointer(
                        @click="noticeView(item.id, item.link, item.objectId, item.viewId )"
                      ) {{"№" + item.viewId}}
                +b.g-cell.g-cols--narrow
                  +b.ds-inliner--size_2xs.cur-pointer(
                    @click="noticeView(item.id)"
                  )
                    +e.body
                      +b.icon-wrapper
                        +b.SVG.svg-icon.--color_lite-green.--sm-special(data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/checkbox-marked-circle-outline.svg")
    +e.element.--bordered-top
      +b.g-cell.g-cols--12
        +b.g-row.--justify_start.--align_center
          +b.g-cell.g-cols--narrow.UI-LIMIT-PAGINATION(
            :pagination='pagination'
            @input='handlePaginate'
          )
    </template>
<script>
import {
  getNotifications,
  notificationView,
} from '@services/auth.service.js';
import {
  SHIPPING_ORDER_NAME,
  SEARCH_ORDER_NAME,
  CHECKING_ORDER_NAME,
} from '../Client/routes'
import {
  MANAGER_SHIPPING_ORDER_NAME,
  MANAGER_SEARCH_ORDER_NAME,
  MANAGER_CHECKING_ORDER_NAME,
} from '../Manager/routes'
import {
  BUYER_SEARCH_ORDER_NAME,
} from '../Buyer/routes'

export default {
  name: 'NotificationsList',
  props: ['userType'],
  // mixins: [FormMixin],
  data() {
    return {
      pagination: {},
      form: {
        limit: 10,
      },
      userRoutes: {
        buyer: {
          SearchOrder: BUYER_SEARCH_ORDER_NAME,
        },
        client: {
          TransportationOrder: SHIPPING_ORDER_NAME,
          SearchOrder: SEARCH_ORDER_NAME,
          CheckOrder: CHECKING_ORDER_NAME,
        },
        manager: {
          TransportationOrder: MANAGER_SHIPPING_ORDER_NAME,
          SearchOrder: MANAGER_SEARCH_ORDER_NAME,
          CheckOrder: MANAGER_CHECKING_ORDER_NAME,
        },
      },
      notificationsData: [],
    }
  },
  methods: {
    linkFollow(link, id) {
      this.$router.push({ name: link, params: { id } })
      // if (202 === this.noticeView(id)) {
      //   console.log('follow to link!!!!');
      // }
    },
    noticeView(id, link, orderId, orderVid) {
      notificationView.execute({ id }).then(res => {
        if (202 === res.code) {
          if (link) {
            this.$router.push({ name: link, params: { id: orderId, vId: orderVid } })
          } else {
            this.getNotifications()
            this.$router.go()
          }
        }
      })
    },
    checkUserType() {

    },
    getNotifications(params = this.form) {
      getNotifications.execute(params).then(res => {
        this.notificationsData = res.data.items
        this.pagination = res.data.pagination
        this.notificationsData.forEach(element => {
          element.link = this.userRoutes[this.userType][element.resourcetype]
        });
      })
    },
    handlePaginate(params) {
      this.getNotifications({ ...this.form, ...params })
    },
  },
  mounted() {
    this.getNotifications()
  },
}
</script>
