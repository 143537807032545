<template lang="pug">
  include /mixin.pug
  +b.order-section
    +e.element.--offset-bottom_none.--space-bottom_none
      +b.g-row.--space_xl.--space_3xl-lg.--appearance_spaced
        +b.g-cell.g-cols--12
          +b.g-row.--space_xl.--space_3xl-lg.--appearance_spaced
            +b.g-cell.g-cols--6.--4-xl
              +b.ds-panel.--space_2xl
                +e.element.--offset_top
                  +b.control-output.--style_main.--height_fit-content
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{orderfields[0].createdAt}}
                    +b.ds-caption.--size_sm.--light.--color_main-gray.SPAN {{form.createdAt}}
            +b.g-cell.g-cols--6.--4-xl
              +b.ds-panel.--space_2xl
                +e.element.--offset_top
                  +b.control-output.--style_main.--height_fit-content
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{orderfields[0].status}}
                    +b.ds-caption.--size_sm.--light.--color_main-gray.SPAN {{form.status}}
            +b.g-cell.g-cols--12.--4-xl
              +b.ds-panel.--space_2xl
                +e.element.--offset_top
                  +b.control-output.--style_main.--height_fit-content
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{orderfields[0].transportationType}}
                    +b.ds-caption.--size_sm.--light.--color_main-gray.SPAN(
                        v-for="(type, idx) in transportationTypes" :key="type"
                      ) {{orderTransportationTypes[type]}} {{transportationTypes.length > 1? idx !== transportationTypes.length - 1? '/ ' : '' : ''}}
        +b.g-cell.g-cols--12
          +b.g-row.--space_3xl
            +b.g-cell.g-cols--12.--4-md
                +b.ds-panel.--space_2xl.--space_3xl-md.--space_2xl-xl
                  +e.element.--offset_top
                    +b.control-output.--style_main.--height_fit-content
                      +e.label
                        +b.ds-caption.--size_xs.--light.--color_main {{orderfields[1].totalVolume}}
                      +b.ds-caption.--size_sm.--light.--color_main-gray.SPAN {{form.totalVolume}} {{'m3'}}
            +b.g-cell.g-cols--12.--4-md
                +b.ds-panel.--space_3xl.--space_2xl-xl
                  +e.element.--offset_top
                    +b.control-output.--style_main.--height_fit-content
                      +e.label
                        +b.ds-caption.--size_xs.--light.--color_main {{orderfields[1].totalWeight}}
                      +b.ds-caption.--size_sm.--light.--color_main-gray.SPAN {{form.totalWeight}} {{'kg'}}
            +b.g-cell.g-cols--12.--4-md
                +b.ds-panel.--space_3xl.--space_2xl-xl
                  +e.element.--offset_top
                    +b.control-output.--style_main.--height_fit-content
                      +e.label
                        +b.ds-caption.--size_xs.--light.--color_main {{orderfields[1].volumeWeightSea}}
                      +b.ds-caption.--size_sm.--light.--color_main-gray.SPAN {{form.totalWeight}} {{'m3'}}
        +b.g-cell.g-cols--12.--6-xl(
              v-for="(item, name, index) in orderfields[2]" :key="name"
              )
          +b.g-row.--space_3xl
            +b.g-cell.g-cols--12
              +b.ds-panel.--space_2xl
                +e.element.--offset_top
              +b.control-output.--style_main.--height_fit-content
                +e.label
                  +b.ds-caption.--size_xs.--light.--color_main {{item}}
                template
                  +b.ds-caption.--size_sm.--light.--color_main-gray.SPAN {{form[name]}}
        +b.g-cell.g-cols--12
          +b.ds-panel.--space_3xl
            +e.element.--offset_top
              +b.ds-caption.--size_mg.--bold.--color_main.--variant_uppercase.P {{_('Cargo data')}}
    +e.element.--space-top_sm.--offset-bottom_none.--space-bottom_none
      +e.element-inner-wrapper--colorized(
        v-for="(freight, index) in freights" :key="index"
        :class="index === freights.length - 1? 'order-section__element-inner-wrapper--offset-bottom_none' : '' ")
        +b.ds-panel.--space_2xl-till-md.--space_none
          +e.element.--offset_top
        +b.g-row.--space_3xl
          +b.g-cell.g-cols--12
            +b.g-row.--space_3xl.--appearance_spaced
              +b.g-cell.g-cols--12.--6-md.--3-xl(
                v-for="(item, name, index) in orderDataFields[0]" :key="name"
                )
                control-output(
                  :text="freight[name]"
                  :label="item"
                )
          +b.g-cell.g-cols--12.--scrollable(v-for="(pack, index) in freight.packages" :key="pack.id")
            +b.ds-panel.--space_2xl
              +e.element.--offset_top
                +b.ds-table.--style_cargos
                  +e.head.THEAD
                    +e.row.TR
                      +e.cell.--offset_none.TH(v-for="(cell, name, index) in packagesData" :key="cell")
                        +b.ds-panel.--space_sm
                          +e.element.--offset_full
                            +b.ds-caption.--size_xs.--bold.--appearance_start.--color_main-white.SPAN {{cell}}
                  +e.body.TBODY
                    +e.row.--gray.TR
                      +e.cell.TD(v-for="(cell, name, index) in packagesData" :key="cell")
                        template
                          +b.ds-panel.--space_md
                            +e.element.--offset_full
                              +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.SPAN
                                | {{pack[name]}}
          +b.g-cell.g-cols--12
            +b.ds-panel.--space_3xl
              +e.element.--offset_top
                +b.control-output.--style_main.--height_fit-content
                  +e.label
                    +b.ds-caption.--size_xs.--light.--color_main {{_('Comments of cargo')}}
                  +b.ds-caption.--size_sm.--light.--color_main-gray.P {{freight['freightComment']}}
    +e.element
      +b.g-row.--space_3xl
        +b.g-cell.g-cols--12
          +b.ds-panel.--space_3xl
            +e.element.--offset_top
              +b.control-output.--style_main.--height_fit-content.--height_2xl
                +e.label
                  +b.ds-caption.--size_xs.--light.--color_main {{_('Customer comment')}}
                +b.ds-caption.--size_sm.--light.--color_main-gray.SPAN {{form.clientComment}}
        +b.g-cell.g-cols--6(v-if="documents.length !== 0")
          +b.ds-panel.--space_3xl
            +e.element.--offset_top
              +b.control-output--style_clear
                +e.label
                  +b.ds-caption.--size_xs.--light.--color_main {{_('Attached files')}}
                +b.g-row.--justify_start.--align_end
                  +b.g-cell.g-cols--narrow(
                    v-for="(file, inx) in documents"
                    :key="file.index"
                    )
                    +b.ds-panel.--space_md
                      +e.element.--offset_bottom
                        +b.g-row.--justify_center.--align_center.--space_none
                          +b.g-cell.g-cols--narrow.--style_files-loaded
                            +b.g-row.--space_none
                              +b.g-cell.g-cols--narrow
                                +b.ds-panel.--space_sm
                                  +e.element.--offset_horizontal
                                    +b.link.A(
                                      :href="file.source"
                                      download
                                      )
                                      +b.ds-caption.--size_sm.--light.--color_main-accent {{file.title}}
    +e.element
      +b.g-row.--space_none
        +b.g-cell.g-cols--12.--scrollable
          +b.ds-table.--style_cargos
            +e.head.THEAD
              +e.row.TR
                +e.cell.--offset_none.TH(v-for="(cell, name, index) in tableFields" :key="cell")
                  +b.ds-panel.--space_sm
                    +e.element.--offset_full
                      +b.ds-caption.--size_xs.--bold.--appearance_start.--color_main-white.SPAN {{cell}}
            +e.body.TBODY
              +e.row.--gray.TR
                +e.cell.TD(v-for="(cell, name, index) in tableFields" :key="cell")
                  template(v-if="name === 'invoiceSet'")
                    +b.ds-panel.--space_md(v-if="invoices.length")
                      +e.element.--offset_full(v-for="invoice in invoices" :key="invoice.id")
                        +b.link.A(
                          :href="invoice.source"
                          download
                        )
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.SPAN(
                          ) {{invoice.filename}}
                    +b.ds-panel.--space_md(v-else)
                      +e.element.--offset_full
                        span -
                  template(v-else-if="name === 'documentSet'")
                    +b.ds-panel.--space_md(v-if="attachedDocuments.length")
                      +e.element.--offset_full
                        +b.link.A(
                          v-for="document in attachedDocuments" :key="document.id"
                          :href="document.source"
                          download
                        )
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.P
                            | {{document.title}}
                        +b.link.A(
                          v-for="photo in photos" :key="photo.id"
                          :href="photo.source"
                          download
                        )
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.P(
                          ) {{photo.title}}
                    +b.ds-panel.--space_md(v-else)
                      +e.element.--offset_full
                        span -
                  template(v-else-if="name === 'managerComment'")
                    +b.ds-panel.--space_md
                      +e.element.--offset_full
                        +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.--word-wrap.P {{form[name] || '-'}}
                  template(v-else)
                    +b.ds-panel.--space_md
                      +e.element.--offset_full
                        +b.ds-caption.--size_sm.--appearance_start.--color_main.SPAN
                          +b.ds-caption {{form.clientAmount}}
                          +b.ds-caption.--light /
                          +b.ds-caption.--light(
                            :class="form.clientAmount !== form.clientPaid? 'ds-caption--color_accent-red' : 'ds-caption--color_lite-green'"
                          ) {{form.clientPaid}}
</template>
<script>
import {
  getClientOrderShipping,
  getCountiesList,
} from '@services/auth.service.js';

// import { clone } from 'ramda';

export default {
  components: {
  },
  name: 'CheckOrderView',
  data() {
    return {
      countiesList: [],
      invoices: [],
      reports: [],
      documents: [],
      attachedDocuments: [],
      photos: [],
      freights: [],
      transportationTypes: [],
      orderTransportationTypes: {
        1: this._('Regular Sea'),
        2: this._('Fast Sea'),
        3: this._('Express'),
        4: this._('Air'),
      },
      orderStatus: {
        '0001': this._('Planed'),
        '0002': this._('In work'),
        '0003': this._('Done'),
        '0004': this._('Canceled'),
      },
      tableFields: {
        invoiceSet: this._('Invoices'),
        payment: this._('Payment State'),
        managerComment: this._('Comment'),
        documentSet: this._('Attached files'),
      },
      orderfields: [
        {
          createdAt: this._('Order date'),
          status: this._('Order status'),
          transportationType: this._('Transportation type'),
        },
        {
          totalVolume: this._('Overall volume'),
          totalWeight: this._('Total weight'),
          volumeWeightSea: this._('Volume Weight SEA'),
        },
        {
          pickupAddress: this._('Shipping address'),
          deliveryCountry: this._('Delivery country'),
        },
      ],
      orderDataFields: [
        {
          title: this._('Name of cargo'),
          customsCode: this._('Customs code'),
          freightCost: this._('Cargo value'),
          deliveryAddress: this._('Alternative address'),
        },
        {
          totalVolume: this._('Overall volume'),
          totalWeight: this._('Total weight'),
          volumeWeightSea: this._('Volume Weight SEA'),
        },
        // {
        //   pickupAddress: this._('Shipping address'),
        //   deliveryCountry: this._('Delivery country'),
        // },
      ],
      packagesData: {
        title: this._('Model Number'),
        quantity: this._('Number'),
        totalPackageVolume: this._('Overall volume'),
        totalPackageWeight: this._('Total weight'),
      },
      form: {
      },
    }
  },
  methods: {
    async loadCountriesList() {
      await getCountiesList.execute().then(res => {
        Array.from(res.data.item).forEach(element => {
          this.countiesList.push(element)
        })
      })
    },
    getOrderData(id) {
      if (id) {
        getClientOrderShipping.execute({ id }).then(res => {
          this.form = res.data.item
          this.transportationTypes = this.form.transportationType
          this.freights = this.form.freights
          this.invoices = this.form.invoiceSet
          this.reports = this.form.reportdocument
          this.documents = this.form.documentSet
          this.attachedDocuments = this.form.managerdocumentSet
          this.photos = this.form.photoSet
          this.countiesList.forEach(element => {
            if (this.form.deliveryCountry === element.code) {
              this.form.deliveryCountry = element.country
            }
          })
          this.form.status = this.orderStatus[this.form.status]
          if (1 === res.data.item.checkType) {
            this.form.checkType = 'Factory'
          } else {
            this.form.checkType = 'Product'
          }
        })
      }
    },
  },
  async mounted() {
    await this.loadCountriesList()

    this.getOrderData(this.$route.params.id)
  },
}
</script>
