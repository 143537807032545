<template lang="pug">
  include /mixin.pug
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ handleSubmit, valid }",
    slim,
  )
    +b.FORM.d-control-form.--main.--full_height(
      @submit.prevent="handleSubmit(prepareData)"
      )
      +b.order-section.--full_height
        +e.element(v-if="manager")
          +b.g-row.--justify_between
            +b.g-cell.g-cols--12
              +b.ds-panel.--space_3xl
                +e.element.--offset_bottom
                  +b.ds-caption.--spacing_xs.--variant_uppercase.--size_xs.--size_sm-sm.--bold.--color_main
                    | {{_('Creating order')}}
                    span(v-if="orderFor.client") &nbsp;{{ _('for:') }} {{ orderFor.client.name }}
            +b.g-cell.g-cols--6.--4-sm.--3-xl
              +b.ds-panel.--space_xl
                +e.element.--offset_top
                  template
                    +b.TAG(
                      :tag="clientsSet.tag"
                      v-model='orderFor.client',
                      name='status',
                      type='select',
                      rules="required"
                      v-bind="clientsSet.props"
                      :input-label='clientsSet.label'
                      :class='clientsSet.class',
                      :placeholder='clientsSet.placeholder',
                    )
        +e.element.--full_height
          +b.g-row.--space_3xl
            template(
                v-for="(item, name, index) in formSettings.selects"
                )
              +b.g-cell.g-cols--12.--6-sm.--3-xl(:key="`field_${name}_${index}`")
                template
                  +b.ds-panel.--space_3xl.--relative
                    +e.element.--offset_top
                      template(v-if="name === 'checkDate'")
                        +b.control-descriptor__date-select-errors(v-if="errors")
                          +b.ds-caption {{ errors }}
                        +b.control-output--style_clear
                          +e.label
                            +b.ds-caption.--size_xs.--light.--color_main {{_('Check date *')}}
                  +b.TAG(
                    :tag='formSettings.selects[name].tag || "d-control-input"'
                    v-model='form[name]',
                    :rules='formSettings.selects[name].rules',
                    :name='name',
                    type='text'
                    v-bind="formSettings.selects[name].props"
                    :input-label='formSettings.selects[name].label'
                    :class="formSettings.selects[name].class || 'd-control-input--style_main-light'"
                    :placeholder='formSettings.selects[name].placeholder',
                  )
            +b.g-cell.g-cols--12.--6-xl(
              v-for="(item, name, index) in formSettings.inputs" :key="`field_${name}_${index}`"
              )
              template
                +b.ds-panel.--space_3xl
                  +e.element.--offset_top
                +b.TAG(
                  :tag='formSettings.inputs[name].tag || "d-control-input"'
                  v-model='form[name]',
                  :rules='formSettings.inputs[name].rules',
                  :name='name',
                  type='text'
                  v-bind="formSettings.inputs[name].props"
                  :input-label='formSettings.inputs[name].label'
                  :class="formSettings.inputs[name].class || 'd-control-input--style_main-light'"
                  :placeholder='formSettings.inputs[name].placeholder',
                )
            +b.g-cell.g-cols--12
              +b.g-row.--justify_center.--justify_start-sm.--align_end(v-if="preLoadedFiles.length > 0")
                +b.g-cell.g-cols--narrow(
                  v-for="(file, inx) in preLoadedFiles"
                  :key="file.index"
                  )
                  +b.ds-panel.--space_lg
                    +e.element.--offset_top
                      +b.g-row.--justify_center.--align_center.--space_none
                        +b.g-cell.g-cols--narrow.--style_files-loaded
                          +b.g-row.--space_none
                            +b.g-cell.g-cols--narrow
                              +b.ds-panel.--space_sm
                                +e.element.--offset_horizontal
                                  +b.ds-caption.--size_sm.--light.--color_main-accent {{file.value.name}}
                            +b.g-cell.g-cols--narrow.cur-pointer(
                              @click.prevent="docDelete(inx, file.id)"
                            )
                              +b.ds-panel.--space_sm
                                +e.element.--offset_horizontal
                                  +b.ds-caption.--size_sm.--color_accent-red {{_('✕')}}
            +b.g-cell.g-cols--12
              +b.g-row.--justify_center.--justify_between-sm.--space_3xl.--full_height
                +b.g-cell.g-cols--narrow
                  +b.g-row.--justify_start
                    +b.g-cell.g-cols--narrow
                      +b.ds-panel.--space_3xl
                        +e.element.--offset_top
                          file-uploader(
                            @preLoadFilesHandler='getPreloadedFiles'
                            :is-disabled="filesLimit"
                          )
                +b.g-cell.g-cols--narrow
                  +b.ds-panel.--space_3xl
                    +e.element.--offset_top
                      click-outside(:do='resetDateErrors')
                        +b.BUTTON.button.--style_main.--full_width(
                          id="submit-btn"
                          :type="submit"
                          @click.prevent="prepareData(valid)"
                        )
                          +b.g-row.--justify_between.--align_center
                            +b.g-cell.g-cols--narrow
                              +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-white
                                | {{ _('Make request') }}
</template>
<script>
import {
  creatingFile,
  deleteFile,
  productCheck,
  getClientsListSimple,
  managerCheckingCreate,
  getCheckTypesList,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import { clone } from 'ramda';
import FormMixin, { defaultValidatorErrorsParser } from '../../Forms/FormMixin'

const ClickOutside = () => import('../../General/ClickOutside');

export default {
  components: {
    ClickOutside,
  },
  props: ['manager'],
  name: 'roductSearchForm',
  mixins: [FormMixin],
  keyGetter: x => x.id,
  data() {
    return {
      clientsSet: {
        name: 'clientsSet',
        tag: 'd-control-multiselect',
        class: 'd-control-multiselect d-control-multiselect--style_main d-control-multiselect--style_light',
        label: this._('Client'),
        placeholder: this._('Select client'),
        props: {
          options: [],
          label: 'name',
          trackBy: 'name',
          searchable: false,
          allowEmpty: false,
        },
      },
      errors: '',
      checkTypeError: '',
      roductSearchFormModalConfig: {
        props: {
          title: 'Your order is accepted',
          subtitle: 'In the near future you will receive a miscalculation on the order',
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      preLoadedFiles: [],
      filesLimit: false,
      nonFieldErrors: '',
      type: [],
      multiple: true,
      value: '',
      formSettings: {
        selects: {
          checkType: {
            label: this._('Object *'),
            placeholder: this._('Product Name'),
            rules: 'required',
            tag: 'd-control-multiselect',
            type: 'select',
            class: 'd-control-multiselect d-control-multiselect--style_main d-control-multiselect--style_light',
            props: {
              options: [],
              label: 'title',
              trackBy: 'title',
              searchable: true,
            },
          },
          checkDate: {
            props: {
              valueType: 'YYYY-MM-DD',
            },
            label: this._('Check date *'),
            placeholder: this._('Select a date'),
            rules: 'required',
            tag: 'vue-date-picker',
          },
        },
        inputs: {
          title: {
            label: this._('Name *'),
            placeholder: this._('Enter your comment'),
            rules: 'required|max:3000',
          },
          address: {
            label: this._('Address *'),
            placeholder: this._('Enter the address'),
            rules: 'required|max:3000',
            tag: 'd-control-textarea',
            class: 'd-control-input--style_main-light d-control-input--height_2xl',
          },
          clientComment: {
            label: this._('Comment'),
            placeholder: this._('Enter your comment'),
            rules: 'max:3000',
            tag: 'd-control-textarea',
            class: 'd-control-input--style_main-light d-control-input--height_2xl',
          },
        },
        setters: {
          documentSet: [],
          photoSet: [],
        },
      },
      orderFor: {
        client: '',
      },
      form: {
        title: '',
        checkType: '',
        address: '',
        checkDate: '',
        clientComment: '',
        documentSet: [],
      },
      orderCreatedModal: {
        props: {
          title: this._('Order is accepted'),
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
    }
  },
  watch: {
    preLoadedFiles: function filesLimiter() {
      if (5 === this.preLoadedFiles.length) {
        this.filesLimit = true
      } else {
        this.filesLimit = false
      }
    },
  },
  methods: {
    modalOpener,
    getPreloadedFiles(data) {
      creatingFile.execute({}, { doc: data.value.url }).then(res => {
        if (201 === res.code) {
          this.preLoadedFiles.push({ id: res.data.item.id, value: data.value })
          this.form.documentSet.push(res.data.item.id)
        }
      })
    },
    docDelete(index, id) {
      deleteFile.execute({ id }).then(res => {
        if (204 === res.status) {
          this.preLoadedFiles.splice(index, 1)
          this.form.documentSet.splice(index, 1)
        }
      })
    },
    resetDateErrors() {
      this.errors = ''
    },
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) {
          if ('' === this.form.checkDate || null === this.form.checkDate) {
            this.errors = this._('This field is required.');
            return
          }
          return
        }
        if ('' === this.form.checkDate || null === this.form.checkDate) {
          this.errors = this._('This field is required.');
          return
        }
        this.submit(valid, this.form)
      })
    },
    send(data) {
      const transformedData = clone(data);
      transformedData.checkType = transformedData.checkType.id
      if (this.manager) {
        transformedData.client = this.orderFor.client.id
        return managerCheckingCreate.execute({}, transformedData).then(res => {
          if (201 === res.code) {
            this.$router.push({ name: 'manager:checking' })
            this.modalOpener(this.orderCreatedModal)
            this.$refs.validator.reset()
          }
        })
      }
      return productCheck.execute({}, transformedData).then(res => {
        if (201 === res.code) {
          this.$router.push({ name: 'user:orders' })
          this.modalOpener(this.orderCreatedModal)
          this.$refs.validator.reset()
        }
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.username
      this.$refs.validator.setErrors(parsed)
    },
    loadClientsList() {
      if (this.manager) {
        getClientsListSimple.execute().then(res => {
          res.data.items.forEach(element => {
            this.clientsSet.props.options.push({ id: element.id, name: `${element.firstName} ${element.lastName}` })
          });
        });
      }
    },
    loadChecktypesList() {
      getCheckTypesList.execute().then(res => {
        this.formSettings.selects.checkType.props.options = res.data.items
      })
    },
  },
  mounted() {
    this.loadClientsList()
    this.loadChecktypesList()
  },
}
</script>
