<template lang="pug">
  include /mixin.pug
  +b.order-section
    +e.element
      +b.g-row.--space_3xl
        +b.g-cell.g-cols--12.--6-xl
          +b.g-row.--space_3xl
            +b.g-cell.g-cols--6(v-for="(item, name, index) in fields[0]" :key="name")
              +b.ds-panel.--space_2xl
                +e.element.--offset_top
                  +b.control-output.--style_main
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{item}}
                    +b.text-wrapper
                      +b.ds-caption.--size_sm.--light.--color_main-gray {{form[name]}}
            +b.g-cell.g-cols--12(v-for="(item, name, index) in fields[1]" :key="name")
              +b.ds-panel.--space_3xl
                +e.element.--offset_top
                  +b.control-output.--style_main.--height_2xl
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{item}}
                    +b.text-wrapper
                      +b.ds-caption.--size_sm.--light.--color_main-gray {{form[name]}}
        +b.g-cell.g-cols--12.--6-xl
          +b.g-row.--space_3xl
            +b.g-cell.g-cols--12(v-for="(item, name, index) in fields[2]" :key="name")
              +b.ds-panel.--space_3xl.--space_2xl-xl
                +e.element.--offset_top
                  +b.control-output.--style_main
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{item}}
                    +b.text-wrapper
                      +b.ds-caption.--size_sm.--light.--color_main-gray {{form[name]}}
            +b.g-cell.g-cols--12(v-for="(item, name, index) in fields[3]" :key="name")
              +b.ds-panel.--space_3xl
                +e.element.--offset_top
                  +b.control-output.--style_main.--height_2xl
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{item}}
                    +b.text-wrapper
                      +b.P.ds-caption.--size_sm.--light.--color_main-gray {{form[name]}}
            +b.g-cell.g-cols(v-if="photos.length !== 0")
              +b.ds-panel.--space_3xl
                +e.element.--offset_top
                  +b.control-output--style_clear
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{_('Attached photos')}}
                    +b.g-row.--justify_start.--align_end
                      +b.g-cell.g-cols--narrow(
                        v-for="(photo, inx) in photos"
                        :key="photo.index"
                        )
                        +b.ds-panel.--space_md
                          +e.element.--offset_bottom
                            +b.g-row.--justify_center.--align_center.--space_none
                              +b.g-cell.g-cols--narrow.--style_files-loaded
                                +b.g-row.--space_none
                                  +b.g-cell.g-cols--narrow
                                    +b.ds-panel.--space_sm
                                      +e.element.--offset_horizontal
                                        +b.link.A(
                                          :href="photo.source"
                                          download
                                          )
                                          +b.ds-caption.--size_sm.--light.--color_main-accent {{photo.title}}
            +b.g-cell.g-cols(v-if="documents.length !== 0")
              +b.ds-panel.--space_3xl
                +e.element.--offset_top
                  +b.control-output--style_clear
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{_('Attached files')}}
                    +b.g-row.--justify_start.--align_end
                      +b.g-cell.g-cols--narrow(
                        v-for="(file, inx) in documents"
                        :key="file.index"
                        )
                        +b.ds-panel.--space_md
                          +e.element.--offset_bottom
                            +b.g-row.--justify_center.--align_center.--space_none
                              +b.g-cell.g-cols--narrow.--style_files-loaded
                                +b.g-row.--space_none
                                  +b.g-cell.g-cols--narrow
                                    +b.ds-panel.--space_sm
                                      +e.element.--offset_horizontal
                                        +b.link.A(
                                          :href="file.source"
                                          download
                                          )
                                          +b.ds-caption.--size_sm.--light.--color_main-accent {{file.title}}
    +e.element
      +b.g-row.--space_none
        +b.g-cell.g-cols--12(v-if="userType !== 'buyer'")
          +b.ds-table.--style_cargos
            +e.head.THEAD
              +e.row.TR
                +e.cell.--offset_none.TH(v-for="(cell, name, index) in tableFields" :key="cell")
                  +b.ds-panel.--space_sm
                    +e.element.--offset_full
                      +b.ds-caption.--size_xs.--bold.--appearance_start.--color_main-white.SPAN {{cell}}
            +e.body.TBODY
              +e.row.--gray.TR
                +e.cell.TD(v-for="(cell, name, index) in tableFields" :key="cell")
                  template(v-if="name === 'reportdocument'")
                    +b.ds-panel.--space_md(v-if="reports")
                      +e.element.--offset_full
                        +b.link.A(
                          :href="reports.source"
                          download
                        )
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.SPAN
                            | {{reports.title}}
                    +b.ds-panel.--space_md(v-else)
                      +e.element.--offset_full
                        span -
                  template(v-else-if="name === 'invoiceSet'")
                    +b.ds-panel.--space_md(v-if="invoices.length")
                      +e.element.--offset_full
                        +b.link.A(
                          v-for="invoice in invoices" :key="invoice.id"
                          :href="invoice.source"
                          download
                        )
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.SPAN(
                          ) {{invoice.filename}}
                    +b.ds-panel.--space_md(v-else)
                      +e.element.--offset_full
                        span -
                  template(v-else-if="name === 'managerdocumentSet'")
                    +b.ds-panel.--space_md(v-if="form.managerdocumentSet && form.managerdocumentSet.length")
                      +e.element.--offset_full
                        +b.link.A(
                          v-for="document in form.managerdocumentSet" :key="document.id"
                          :href="document.source"
                          download
                        )
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.P(
                        ) {{document.title}}
                    +b.ds-panel.--space_md(v-else)
                      +e.element.--offset_full
                        span -
                  template(v-else-if="name === 'managerComment'")
                    +b.ds-panel.--space_md
                      +e.element.--offset_full
                        +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.--word-wrap.P {{form[name] || '-'}}
                  template(v-else)
                    +b.ds-panel.--space_md
                      +e.element.--offset_full
                        +b.ds-caption.--size_sm.--appearance_start.--color_main.SPAN
                          +b.ds-caption {{form.clientAmount}}
                          +b.ds-caption.--light /
                          +b.ds-caption.--light(
                            :class="form.clientAmount !== form.clientPaid? 'ds-caption--color_accent-red' : 'ds-caption--color_lite-green'"
                          ) {{form.clientPaid}}
        +b.g-cell.g-cols--12(v-if="userType === 'buyer'")
          +b.VALIDATION-OBSERVER(
            ref="validator",
            v-slot="{ handleSubmit }",
            slim,
          )
            +b.FORM.d-control-form--main(
              @submit.prevent="handleSubmit(prepareData)"
              )
              +b.g-row.--justify_between.--align_center.--space_3xl
                +b.g-cell.g-cols--12.--6-xl
                  +b.g-row.--justify_center.--justify_between-xl.--align_center
                    +b.g-cell.g-cols--12.--6-sm.--4-auto(
                      v-if="accepted"
                    )
                      +b.ds-panel.--space_3xl
                        +e.element.--offset_top
                          +b.control-output--style_main
                            +e.label
                              +b.ds-caption.--size_xs.--light.--color_main {{_('Acceptance for work')}}
                            +b.ds-caption.--size_sm.--light.--color_main-gray   {{_('Accepted')}}
                    +b.g-cell.g-cols--12.--6-sm.--4-md.--narrow-lg(
                      v-if="!accepted"
                    )
                      +b.ds-panel.--space_3xl
                        +e.element.--offset_top
                          +b.BUTTON.button.--style_main-light.--full_width(
                              @click.prevent="accepOrder($route.params.id)"
                            )
                              +b.ds-caption.--size_md.--appearance_center.--bold.--color_main-accent
                                | {{ !accepted? _('Accept') : _('Accepted')  }}
                    +b.g-cell.g-cols--auto(
                      :class="!accepted? 'g-cols--inactive' : ''"
                    )
                      +b.g-row.--justify_between.--align_center
                        +b.g-cell.g-cols--6(
                          v-for="(cost, name, index) in buyerCosts" :key="`${name}_${index}`"
                          :class="name === 'keywordsFilter' ? 'g-cols--6' : 'g-cols--4'"
                          )
                          template
                            +b.ds-panel.--space_3xl
                              +e.element.--offset_top
                            +b.TAG(
                              @input="() => debounceAction()"
                              :tag="buyerCosts[name].tag"
                              v-model='form[cost.name]',
                              :name='name',
                              :type='buyerCosts[name].type',
                              v-bind="buyerCosts[name].props"
                              :input-label='buyerCosts[name].label'
                              :class='buyerCosts[name].class',
                              :key="`${name}_${index}`"
                              :placeholder='buyerCosts[name].placeholder',
                              :rules="buyerCosts[name].rules"
                            )
                +b.g-cell.g-cols--12.--6-xl(
                  :class="!accepted? 'g-cols--inactive' : ''"
                )
                  +b.g-row.--justify_center.--justify_between-xl.--align_center
                    +b.g-cell.g-cols--12.--auto-xl
                      +b.ds-panel.--space_3xl
                        +e.element.--offset_top
                          +b.control-output--style_main
                            +e.label
                              +b.ds-caption.--size_xs.--light.--color_main {{_('Search report')}}
                            +b.ds-caption.--size_sm.--light.--color_main-gray.--wrap   {{preLoadedFiles.name}}
                    +b.g-cell.g-cols--narrow
                      +b.ds-panel.--space_3xl
                        +e.element.--offset_top
                          file-uploader(
                            @preLoadFilesHandler='getPreloadedFiles'
                            :title="_('Attach file')"
                          )
                +b.g-cell.g-cols--12(v-if='orderChanged')
                  +b.g-row.--justify_center.--justify_end-xl
                    +b.g-cell.g-cols--narrow
                      +b.ds-panel.--space_3xl
                        +e.element.--offset_top
                          +b.BUTTON.button.--style_main.--full_width(
                              @click.prevent="updateOrder($route.params.id)"
                            )
                              +b.ds-caption.--size_md.--appearance_center.--bold.--color_main-white
                                | {{ _('Save') }}
</template>
<script>
// import { clone } from 'ramda';
import {
  getClientOrderSearch,
  getOrderDataForBuyer,
  acceptBuyerOrder,
  updateBuyerOrder,
  // creatingFile,
  createBuyerReport,
} from '@services/auth.service.js';
import { debounce } from '@utils/helpers'
// import FormMixin, { defaultValidatorErrorsParser } from './FormMixin'

export default {
  components: {
  },
  name: 'CheckOrderView',
  props: ['userType'],
  // mixins: [FormMixin],
  data() {
    return {
      accepted: false,
      orderChanged: false,
      preLoadedFiles: [],
      loadDataLink: '',
      invoices: [],
      reports: [],
      documents: [],
      photos: [],
      orderStatus: {
        '0001': this._('Planed'),
        '0002': this._('In work'),
        '0003': this._('Done'),
        '0004': this._('Canceled'),
      },
      tableFields: {
        reportdocument: this._('Inspection report'),
        invoiceSet: this._('Invoices'),
        payment: this._('Payment State'),
        managerComment: this._('Comment'),
        managerdocumentSet: this._('Attached files'),
      },
      fields: [
        {
          createdAt: this._('Order date'),
          status: this._('Order status'),
        },
        {
          similarLink: this._('Product Links and Keywords'),
          clientComment: this._('Customer comment'),
        },
        {
          productName: this._('Product Name'),
        },
        {
          characteristics: this._('Important product characteristics'),
        },
      ],
      buyerCosts: {
        buyerWorkCost: {
          name: 'buyerWorkCost',
          model: '',
          tag: 'd-control-input',
          class: 'd-control-input--style_main-light',
          label: this._('Cost of work'),
          placeholder: this._('Name / Last Name / County / Company'),
          rules: 'required',
          type: 'number',
        },
        buyerExpenses: {
          name: 'buyerExpenses',
          model: '',
          tag: 'd-control-input',
          class: 'd-control-input--style_main-light',
          label: this._('Search costs'),
          placeholder: this._('Name / Last Name / County / Company'),
          rules: 'required',
          type: 'number',
        },
      },
      form: {
      },
    }
  },
  created() {
    const delay = 1000;
    this.debounceAction = debounce(this.editingHandler, delay);
  },
  methods: {
    editingHandler() {
      this.orderChanged = true
    },
    getPreloadedFiles(data) {
      createBuyerReport.execute({}, { order: this.$route.params.id, doc: data.value.url }).then(res => {
        if (201 === res.code) {
          const file = data.value
          this.preLoadedFiles = file
          this.orderChanged = !this.orderChanged
          // this.form.documentSet.push(res.data.item.id)
        }
      })
    },
    updateOrder(id) {
      updateBuyerOrder.execute({ id }, { buyerExpenses: this.form.buyerExpenses, buyerWorkCost: this.form.buyerWorkCost }).then(res => {
        if (200 === res.code) {
          this.orderChanged = false
          this.$router.push({ name: 'buyer:orders' })
        }
      })
    },
    accepOrder(id) {
      acceptBuyerOrder.execute({ id }).then(res => {
        if (200 === res.code) {
          this.accepted = true
        }
      })
    },
    getOrderData(id) {
      if ('buyer' === this.userType) {
        this.loadDataLink = getOrderDataForBuyer
      } else {
        this.loadDataLink = getClientOrderSearch
      }
      this.loadDataLink.execute({ id }).then(res => {
        if (res.data.item.buyer) {
          this.accepted = true
          this.preLoadedFiles.name = res?.data?.item?.reportdocument?.title
        }
        this.form = res.data.item
        this.invoices = this.form.invoiceSet
        this.reports = this.form.reportdocument
        this.documents = this.form.documentSet
        this.photos = this.form.photoSet
        this.form.status = this.orderStatus[this.form.status]
        if (1 === res.data.item.checkType) {
          this.form.checkType = 'Factory'
        } else {
          this.form.checkType = 'Product'
        }
      })
    },
    createReport() {
      return createBuyerReport.execute({}).then(res => {
        if (201 === res.code) {
          // this.modalOpener(this.roductSearchFormModalConfig)
        }
      })
    },
  },
  mounted() {
    this.getOrderData(this.$route.params.id)
  },
}
</script>
