<template lang="pug">
  include ../../../templates/mixin.pug
  +b.ds-section.--space_none.--relative
    +e.element.--style_main-form
    +e.element
      +b.g-row.--justify_center.--space_none.--align_center.--full-screen_height
        +b.g-cell.g-cols--12
          +b.g-row.--justify_center.--space_none.--align_center.--relative.--full-screen_height-till-sm
            +b.g-cell.g-cols--auto.--absolute.--layer-position_under.--full_height.--max-width_sm
              +b.img-wrap
                +e.IMG.img.--border-radius_sm(alt="Form background" src='/static/img/auth-form-bg.png')
            +b.g-cell.g-cols--10.--9-sm.--max-width_md
              +b.ds-section.--space_md.--space_xl-md
                +e.element
                  +b.VALIDATION-OBSERVER(
                    ref="validator",
                    v-slot="{ handleSubmit }",
                    slim,
                    ): +b.FORM.d-control-form--main(@submit.prevent="handleSubmit(prepareData)")
                    +b.g-row.--justify_center.--appearance_column.--align_center
                      +b.g-cell.g-cols--narrow.--8-sm.--6-md
                        +b.ds-panel.--space_xl
                          +e.element.--offset_bottom(v-if="verified")
                              +b.ds-caption.--size_lg.--appearance_center.--appearance_left-sm.--bold.--color_main-white
                                | {{_('Your email address has been verified, now you can log in to your account.')}}
                          +e.element.--offset_bottom
                            +b.ds-caption.--size_xl.--appearance_center.--appearance_left-sm.--bold.--color_main-white.--variant_uppercase {{ _('Authorization') }}
                          +e.element.--offset_vertical
                            transition(name='opasity')
                              +b.error-label.LABEL(v-if="nonFieldErrors")
                                +e.P.element
                                  +b.ds-caption.--appearance_center {{nonFieldErrors[0]}}
                          +e.element.--offset_bottom.--indent_inside
                      +b.g-cell.g-cols--narrow.--8-sm.--6-md
                        +b.ds-panel.--space_xl
                          +e.element.--offset_bottom
                            d-control-input(
                              v-model='form.email',
                              rules='email|required',
                              name='login',
                              class="d-control-input--style_main"
                              :input-label=`_("E-mail")`
                              :placeholder=`_("E-mail")`,
                              autofocus
                            )
                          +e.element.--offset_vertical.--indent_inside
                            d-control-input(
                              v-model='form.password'
                              rules='required',
                              name='password',
                              type='password',
                              class="d-control-input--style_main"
                              :input-label=`_("Password")`,
                              :placeholder=`_("Password")`,
                            )
                          +e.element.--offset_bottom
                            +b.g-row.--justify_center
                              +b.g-cell.g-cols--12.--7-sm
                                +b.ds-panel.--space_lg
                                  +e.element.--offset_vertical
                                    +b.SPAN.ds-caption.--hovered_main-light.--color_main-white.--size_sm.--appearance_center.--light.--cur-pointer(
                                      @click="modalOpener(passRecoveryModalConfig)"
                                      :class="(!verified) ? 'ds-caption--appearance_left-sm' : ''"
                                    ) {{_('Forgot your password?')}}
                              +b.g-cell.g-cols--12.--5-sm(v-if="!verified")
                                +b.ds-panel.--space_lg
                                  +e.element.--offset_vertical
                                    +b.ds-caption.--color_main-accent.--bold.--size_sm.--appearance_center.--appearance_right-sm.--light.--cur-pointer
                                      +b.SPAN.ds-caption.--hovered_main(
                                        @click="redirect(reg_link)"
                                      ) {{_('Registration')}}
                            +b.g-row.--justify_center
                              +b.g-cell.g-cols--12
                                +b.ds-panel.--space_lg
                                  +e.element.--offset_top
                                    +b.BUTTON.button.--style_main.--full_width(type="submit" :disabled="loading")
                                      +b.ds-caption.--size_md.--appearance_center.--bold.--color_main-white {{_('Login')}}
</template>
<script>
import {
  loginResource,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from './FormMixin'

const PassRecoveryForm = () => import('../Forms/PassRecoveryForm');

export default {
  props: ['verified', 'reg_link'],
  name: 'FormAuthorization',
  mixins: [FormMixin],
  data() {
    return {
      passRecoveryModalConfig: {
        props: {
          isComponent: PassRecoveryForm,
          title: this._('Password Recovery'),
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      location: '',
      nonFieldErrors: '',
      form: {
        email: '',
        password: '',
      },
    }
  },
  methods: {
    modalOpener,
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return
        this.submit(valid, this.form)
      })
    },
    errorsReset() {
      this.nonFieldErrors = ''
    },
    send(data) {
      return loginResource.execute({}, data).then(res => {
        if (200 === res.code) {
          window.location.pathname = res.data.item.redirect
          this.$refs.validator.reset();
        }
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.nonFieldErrors
      this.$refs.validator.setErrors(parsed)
    },
    redirect(path) {
      window.location.pathname = path;
    },
  },
}
</script>
