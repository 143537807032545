<template lang="pug">
  include /mixin.pug
  +b.order-section
    +e.element
      +b.g-row.--space_3xl
        +b.g-cell.g-cols--12.--6-xl
          +b.g-row.--space_3xl
            +b.g-cell.g-cols--6(v-for="(item, name, index) in fields[0]" :key="name")
              +b.ds-panel.--space_2xl
                +e.element.--offset_top
                  +b.control-output.--style_main
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{item}}
                    +b.ds-caption.--size_sm.--light.--color_main-gray {{form[name]}}
            +b.g-cell.g-cols--12(v-for="(item, name, index) in fields[1]" :key="name")
              +b.ds-panel.--space_3xl
                +e.element.--offset_top
                  +b.control-output.--style_main(
                    :class="name === 'clientComment'? 'control-output--height_2xl' : ''"
                  )
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{item}}
                    +b.ds-caption.--size_sm.--light.--color_main-gray {{form[name]}}
        +b.g-cell.g-cols--12.--6-xl
          +b.g-row.--space_3xl
            +b.g-cell.g-cols--6.--6-xl(v-for="(item, name, index) in fields[2]" :key="name")
              +b.ds-panel.--space_3xl.--space_2xl-xl
                +e.element.--offset_top
                  +b.control-output.--style_main
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{item}}
                    +b.ds-caption.--size_sm.--light.--color_main-gray {{form[name]}}
            +b.g-cell.g-cols--6.--12-xl(v-for="(item, name, index) in fields[3]" :key="name")
              +b.ds-panel.--space_3xl
                +e.element.--offset_top
                  +b.control-output.--style_main.--full-height
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{item}}
                    +b.ds-caption.--size_sm.--light.--color_main-gray {{form[name]}}
            +b.g-cell.g-cols--narrow.--12-xl(v-if="documents.length !== 0")
              +b.ds-panel.--space_3xl
                +e.element.--offset_top
                  +b.control-output--style_clear
                    +e.label
                      +b.ds-caption.--size_xs.--light.--color_main {{_('Attached files')}}
                    +b.g-row.--justify_start.--align_end
                      +b.g-cell.g-cols--narrow(
                        v-for="(file, inx) in documents"
                        :key="file.index"
                        )
                        +b.g-row.--justify_center.--align_center.--space_none
                          +b.g-cell.g-cols--narrow.--style_files-loaded
                            +b.g-row.--space_none
                              +b.g-cell.g-cols--narrow
                                +b.ds-panel.--space_sm
                                  +e.element.--offset_horizontal
                                    +b.link.A(
                                      :href="file.source"
                                      download
                                      )
                                      +b.ds-caption.--size_sm.--light.--color_main-accent {{file.title}}
    +e.element
      +b.g-row.--space_none
        +b.g-cell.g-cols--12
          +b.ds-table.--style_cargos
            +e.head.THEAD
              +e.row.TR
                +e.cell.--offset_none.TH(v-for="(cell, name, index) in tableFields" :key="cell")
                  +b.ds-panel.--space_sm
                    +e.element.--offset_full
                      +b.ds-caption.--size_xs.--bold.--appearance_start.--color_main-white.SPAN {{cell}}
            +e.body.TBODY
              +e.row.--gray.TR
                +e.cell.TD(v-for="(cell, name, index) in tableFields" :key="cell")
                  template(v-if="name === 'reportdocument'")
                    +b.ds-panel.--space_md(v-if="reports")
                      +e.element.--offset_full
                        +b.link.A(
                          :href="reports.source"
                          download
                        )
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.SPAN
                            | {{reports.title}}
                    +b.ds-panel.--space_md(v-else)
                      +e.element.--offset_full
                        span -
                  template(v-else-if="name === 'invoiceSet'")
                    +b.ds-panel.--space_md(v-if="invoices.length")
                      +e.element.--offset_full
                        +b.link.A(
                          v-for="invoice in invoices" :key="invoice.id"
                          :href="invoice.source"
                          download
                        )
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.SPAN
                            | {{invoice.filename}}
                    +b.ds-panel.--space_md(v-else)
                      +e.element.--offset_full
                        span -
                  template(v-else-if="name === 'documentSet'")
                    +b.ds-panel.--space_md(v-if="form.managerdocumentSet && form.managerdocumentSet.length")
                      +e.element.--offset_full
                        +b.link.A(
                          v-for="document in form.managerdocumentSet" :key="document.id"
                          :href="document.source"
                          download
                        )
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.P(
                          ) {{document.title}}
                    +b.ds-panel.--space_md(v-else)
                      +e.element.--offset_full
                        span -
                  template(v-else-if="name === 'managerComment'")
                    +b.ds-panel.--space_md
                      +e.element.--offset_full
                        +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.--word-wrap.p {{form[name] || '-'}}
                  template(v-else)
                    +b.ds-panel.--space_md
                      +e.element.--offset_full
                        +b.ds-caption.--size_sm.--appearance_start.--color_main.SPAN
                          +b.ds-caption {{form.clientAmount}}
                          +b.ds-caption.--light /
                          +b.ds-caption.--light(
                            :class="form.clientAmount !== form.clientPaid? 'ds-caption--color_accent-red' : 'ds-caption--color_lite-green'"
                          ) {{form.clientPaid}}
</template>
<script>
import {
  getClientOrderCheck,
  getOrderDataForBuyer,
} from '@services/auth.service.js';

// import { clone } from 'ramda';

export default {
  components: {
  },
  name: 'CheckOrderView',
  data() {
    return {
      loadDataLink: '',
      orderStatus: {
        '0001': this._('Planed'),
        '0002': this._('In work'),
        '0003': this._('Done'),
        '0004': this._('Canceled'),
      },
      invoices: [],
      reports: [],
      documents: [],
      managerDocs: [],
      tableFields: {
        reportdocument: this._('Inspection report'),
        invoiceSet: this._('Invoices'),
        payment: this._('Payment State'),
        managerComment: this._('Comment'),
        documentSet: this._('Attached files'),
      },
      fields: [
        {
          createdAt: this._('Order date'),
          status: this._('Order status'),
        },
        {
          title: this._('Name'),
          clientComment: this._('Customer comment'),
        },
        {
          checkType: this._('Inspection object'),
          checkDate: this._('Check date'),
        },
        {
          address: this._('Address'),
        },
      ],
      form: {
      },
    }
  },
  methods: {
    getOrderData(id) {
      if ('buyer' === this.userType) {
        this.loadDataLink = getOrderDataForBuyer
      } else {
        this.loadDataLink = getClientOrderCheck
      }
      this.loadDataLink.execute({ id }).then(res => {
        this.form = res.data.item
        this.invoices = this.form.invoiceSet
        this.reports = this.form.reportdocument
        this.documents = this.form.documentSet
        this.form.status = this.orderStatus[this.form.status]
        if (1 === res.data.item.checkType) {
          this.form.checkType = 'Factory'
        } else {
          this.form.checkType = 'Product'
        }
      })
    },
  },
  mounted() {
    this.getOrderData(this.$route.params.id)
  },
}
</script>
