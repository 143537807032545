import installer from '@aspectus/vue-route-installer'

import {
  createRoutes as createUserRoutes,
} from './routes.js'

import {
  route,
  prefixRoutes,
} from '../../Routing/index.js'

const CabinetInstaller = () => import('./index')
const Cabinet = () => import('./Views/Cabinet')

export function createRoutes(options) {
  const { Vue } = options
  return [
    route('', installer(Cabinet, CabinetInstaller, Vue), 'Cabinet', {
      children: [
        prefixRoutes('', createUserRoutes(options), {}),
        // { path: '', redirect: BASE_CABINET },
      ],
    }),
  ]
}
