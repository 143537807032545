<template lang="pug">
  include ../../../templates/mixin.pug
  +b.modal-inner
    +b.VALIDATION-OBSERVER(
      ref="validator",
      v-slot="{ handleSubmit }",
      slim,
    )
      +b.FORM.d-control-form--main(@submit.prevent="handleSubmit(prepareData)")
        +b.g-row.--justify_center.--appearance_spaced.--space_none.--space_3xl-md
          +b.g-cell.g-cols--12(v-for="field in fields" :key="field.name")
            +b.ds-panel.--space_xl.--space_sm-md
              +e.element.--offset_vertical
                d-control-input(
                @input="phoneLengthValodator()"
                :ref="field.name"
                :name='field.name'
                :rules='field.rules'
                :type='field.type'
                :input-label='field.label'
                class='d-control-input--style_main-light'
                v-model='form[field.name]'
                :placeholder="_(field.placeholder)"
                :autofocus="'autofocus'"
                )
          +b.g-cell.g-cols--12(v-if="nonFieldErrors")
            +b.ds-caption.--appearance_center
              +b.error-label.LABEL {{ nonFieldErrors[0] }}
          +b.g-cell.g-cols--12.--10-sm.--6-md
            +b.BUTTON.button.--style_main.--full_width(
              type="submit"
            )
              +b.ds-caption.--size_md.--appearance_center.--bold.--color_main-white
                | {{ _('Add') }}
</template>
<script>
import {
  buyerRegistration,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import { clone } from 'ramda';
import FormMixin, { defaultValidatorErrorsParser } from './FormMixin'

export default {
  props: ['auth_link'],
  name: 'FormAuthorization.',
  mixins: [FormMixin],
  data() {
    return {
      regNoticeModalConfig: {
        props: {
          title: 'Manager added',
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      countiesList: [],
      selectedCountry: '',
      location: '',
      nonFieldErrors: '',
      fields: [
        {
          name: 'firstName',
          placeholder: this._('First name'),
          label: this._('Name'),
          rules: 'only_сyrillic|required|min:2|special_symbols',
        },
        {
          name: 'lastName',
          placeholder: this._('Last name'),
          label: this._('Last Name'),
          rules: 'only_сyrillic|required|min:2|special_symbols',
        },
        {
          name: 'phone',
          type: 'number',
          placeholder: this._('Phone number'),
          label: this._('Phone'),
          rules: 'required|min:2',
        },
        {
          name: 'email',
          type: 'email',
          placeholder: this._('Email address'),
          label: this._('Email'),
          rules: 'required',
        },
        {
          name: 'password',
          type: 'password',
          placeholder: this._('Password'),
          label: this._('Password'),
          rules: 'required',
        },
      ],
      form: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
      },
    }
  },
  methods: {
    modalOpener,
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return
        this.submit(valid, this.form)
      })
    },
    phoneLengthValodator() {
      this.form.phone = this.form.phone.slice(0, 16);
    },
    send(data) {
      const transformedData = clone(data);
      return buyerRegistration.execute({}, transformedData).then(res => {
        if (201 === res.code) {
          this.modalOpener(this.regNoticeModalConfig)
          this.$refs.validator.reset()
          this.$emit('close');
          this.$emit('finish', this.form)
        }
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.username
      this.$refs.validator.setErrors(parsed)
    },
    errorsReset() {
      this.nonFieldErrors = ''
    },
  },
  mounted() {
    this.$refs.firstName[0].$el.children[0].children[0].children[0].focus()
  },
}
</script>
