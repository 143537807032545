<template lang="pug">
  include ../../../templates/mixin.pug
  +b.ds-section.--space_none.--relative
    +e.element.--style_main-form
    +e.element
      +b.g-row.--justify_center.--space_none.--align_center.--full-screen_height
        +b.g-cell.g-cols--12
          +b.g-row.--justify_center.--space_none.--align_center.--relative.--full-screen_height-till-sm
            +b.g-cell.g-cols--auto.--absolute.--layer-position_under.--full_height
              +b.img-wrap
                +e.IMG.img.--border-radius_sm(alt="Form background" src='/static/img/auth-form-bg.png')
            +b.g-cell.g-cols--10.--9-sm.--max-width_md
              +b.ds-section.--space_md.--space_xl-md
                +e.element
                  +b.VALIDATION-OBSERVER(
                    ref="validator",
                    v-slot="{ handleSubmit }",
                    slim,
                  ): +b.FORM.d-control-form--main(@submit.prevent="handleSubmit(prepareData)")
                    +b.g-row.--justify_center.--appearance_column.--appearance_spaced.--space_3xl-md.--align_center
                      +b.g-cell.g-cols--12
                        +b.ds-panel.--space_lg
                          +e.element
                            +b.ds-caption.--size_xl.--size_2xl-md.--appearance_center.--appearance_left-md.--bold.--color_main-white.--variant_uppercase {{_('Registration')}}
                            +b.error-label.LABEL(v-if="nonFieldErrors")
                      +b.g-cell.g-cols--auto.--12-md
                        +b.g-row
                          +b.g-cell.g-cols--narrow
                            +b.ds-panel.--space_lg
                              +e.element.--offset_bottom.--indent_inside
                                +b.ds-caption.--color_main-white.--size_sm.--appearance_center.--light {{_('Already Registered?')}}
                          +b.g-cell.g-cols--narrow
                            +b.ds-panel.--space_xl
                              +e.element.--offset_left
                                +b.ds-caption.--color_main-accent.--bold.--size_sm.--appearance_center.--light.--cur-pointer
                                  +b.SPAN.ds-caption.--hovered_main(
                                  @click="redirect(auth_link)"
                                ) {{_('Login')}}
                      +b.g-cell.g-cols--narrow
                        +b.g-row.--justify_center.--appearance_spaced.--space_none.--space_3xl-md
                          +b.ds-caption.--appearance_center
                            +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors[0] }}
                          +b.g-cell.g-cols--12.--10-sm.--6-md(v-for="field in fields" :key="field.name")
                            +b.ds-panel.--space_xl.--space_sm-md
                              +e.element.--offset_vertical
                                +b.TAG(
                                :tag='field.tag || "d-control-input"'
                                :name='field.name'
                                :rules='field.rules'
                                :type='field.type || "text"'
                                :input-label='field.label'
                                :class='(field.tag)? field.tag + "--style_main" : "d-control-input--style_main"'
                                v-model='form[field.name]'
                                :options="countiesList"
                                :searchable="true"
                                track-by="country"
                                label="country"
                                :placeholder="_(field.placeholder)"
                                :preselect-first="true"
                                :autofocus="field.name === 'firstName'? true : false"
                                )
                          +b.g-cell.g-cols--12.--10-sm.--6-md
                            +b.ds-panel.--space_xl.--space_sm-md
                              +e.element.--offset_top.--indent_inside
                                +b.g-row.--justify_center
                                  +b.g-cell.g-cols--12
                                    +b.BUTTON.button.--style_main.--full_width(
                                      type="submit"
                                    )
                                      +b.ds-caption.--size_md.--appearance_center.--bold.--color_main-white
                                        | {{ _('Register') }}
</template>
<script>
import {
  getCountiesList,
  registerResource,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import { clone } from 'ramda';
import FormMixin, { defaultValidatorErrorsParser } from './FormMixin'

export default {
  props: ['auth_link'],
  name: 'FormAuthorization.',
  mixins: [FormMixin],
  data() {
    return {
      regNoticeModalConfig: {
        props: {
          title: 'Thank you for registering!',
          subtitle: 'A letter was sent to the Email specified during registration to confirm the mail.',
          path: this.auth_link,
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      countiesList: [],
      selectedCountry: '',
      location: '',
      nonFieldErrors: '',
      fields: [
        {
          name: 'firstName',
          placeholder: this._('Enter your name'),
          label: this._('Name'),
          rules: 'only_сyrillic|required|min:2|special_symbols',
        },
        {
          name: 'lastName',
          placeholder: this._('Enter your last name'),
          label: this._('Last Name'),
          rules: 'only_сyrillic|required|min:2|special_symbols',
        },
        {
          name: 'country',
          placeholder: this._('Select your country'),
          label: this._('Country'),
          rules: 'required',
          type: 'select',
          tag: 'd-control-multiselect',
          props: {
            options: this.countiesList,
          },
        },
        {
          name: 'companyName',
          placeholder: this._('Enter your company'),
          label: this._('Company'),
          rules: 'required',
        },
        {
          name: 'companyAddress',
          placeholder: this._('Addres of company'),
          label: this._('Addres'),
          rules: 'required',
        },
        {
          name: 'email',
          placeholder: this._('E-mail'),
          label: this._('E-mail'),
          rules: 'required',
        },
        {
          name: 'password',
          type: 'password',
          placeholder: this._('Password'),
          label: this._('Password'),
          rules: 'required',
        },
      ],
      form: {
        firstName: '',
        lastName: '',
        country: '',
        companyName: '',
        companyAddress: '',
        email: '',
        password: '',
      },
    }
  },
  methods: {
    modalOpener,
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return
        this.submit(valid, this.form)
      })
    },
    send(data) {
      const transformedData = clone(data);
      transformedData.country = transformedData.country.code
      return registerResource.execute({}, transformedData).then(res => {
        if (201 === res.code) {
          this.modalOpener(this.regNoticeModalConfig)
          this.$refs.validator.reset()
        }
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.username
      this.$refs.validator.setErrors(parsed)
    },
    errorsReset() {
      this.nonFieldErrors = ''
    },
    loadCountriesList() {
      getCountiesList.execute().then(res => {
        Array.from(res.data.item).forEach(element => {
          this.countiesList.push(element)
        });
      });
    },
    redirect(path) {
      window.location.pathname = path;
    },
  },
  mounted() {
    this.loadCountriesList()
  },
}
</script>
