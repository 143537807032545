import {
  route,
} from '../../Routing/index.js'

const ShippingRequestForm = () => import('./Shiping/ShipingRequestForm.vue')
const ProductSearchForm = () => import('./Product/ProductSearchForm.vue')
const CheckingForm = () => import('./Checking/CheckingForm.vue')
const ClientOrders = () => import('./Orders/ClientOrders.vue')
const ShippingOrder = () => import('./Orders/ShippingOrderView.vue')
const SearchOrder = () => import('./Orders/SearchOrderView.vue')
const CheckingOrder = () => import('./Orders/CheckingOrderView.vue')
const PersonalData = () => import('./Profile/PersonalData.vue')
const NotificationsList = () => import('./Notifications/ClientsNotifications.vue')
const ErrorPage = () => import('../Pages/NotFound.vue')

const SHIPPING_REQUEST = 'shipping/'
const PRODUCT_SEARCH = 'product/'
const CHECKING_REQUEST = 'check/'
const CLIENT_ORDERS = 'orders/'
const SHIPPING_ORDER = 'shipping-order/:id/:vId/'
const SEARCH_ORDER = 'search-order/:id/:vId/'
const CHECKING_ORDER = 'checking-order/:id/:vId/'
const PERSONAL_DATA = 'profile/'
const NOTIFICATIONS_LIST = 'notifications/'
const ERROR_PAGE = 'page-not-found/'

export const CHIPPING_REQUEST_NAME = 'user:shipping'
export const PRODUCT_SEARCH_NAME = 'user:search'
export const CHECKING_REQUEST_NAME = 'user:checking'
export const SHIPPING_ORDER_NAME = 'user:shipping-order'
export const SEARCH_ORDER_NAME = 'user:search-order'
export const CHECKING_ORDER_NAME = 'user:checking-order'
export const CLIENT_ORDERS_NAME = 'user:orders'
export const PERSONAL_DATA_NAME = 'user:profile'
export const NOTIFICATIONS_LIST_NAME = 'user:notifications'
export const ERROR_PAGE_NAME = 'user:page-not-found'

export function createRoutes() {
  return [
    route(SHIPPING_REQUEST, ShippingRequestForm, CHIPPING_REQUEST_NAME, { meta: { current: 'shipping-form' } }),
    route(PRODUCT_SEARCH, ProductSearchForm, PRODUCT_SEARCH_NAME, { meta: { current: 'search-form' } }),
    route(CHECKING_REQUEST, CheckingForm, CHECKING_REQUEST_NAME, { meta: { current: 'check-form' } }),
    route(SHIPPING_ORDER, ShippingOrder, SHIPPING_ORDER_NAME, { meta: { current: 'my-orders' } }),
    route(SEARCH_ORDER, SearchOrder, SEARCH_ORDER_NAME, { meta: { current: 'my-orders' } }),
    route(CHECKING_ORDER, CheckingOrder, CHECKING_ORDER_NAME, { meta: { current: 'my-orders' } }),
    route(CLIENT_ORDERS, ClientOrders, CLIENT_ORDERS_NAME, { meta: { current: 'my-orders' } }),
    route(PERSONAL_DATA, PersonalData, PERSONAL_DATA_NAME, { meta: { current: 'personal' } }),
    route(NOTIFICATIONS_LIST, NotificationsList, NOTIFICATIONS_LIST_NAME, { meta: { current: 'notice' } }),
    route(ERROR_PAGE, ErrorPage, ERROR_PAGE_NAME),

    { path: '', redirect: { name: CLIENT_ORDERS_NAME } },
    { path: '*', redirect: { name: '404' } },

  ]
}
