<template lang="pug">
  include ../../../templates/mixin.pug
  +b.main(
    :class="(minimized)? 'side-bar--minimized' : ''"
  )
    +b.ds-section.--space_none.--relative
      +e.element
        +b.g-container--fit_2xl.g-container--space_none
          +b.g-row.--justify_center.--align_center.--space_none
            +b.g-cell.g-cols--12(v-if="type === 'side-bar' ")
              +b.ds-section.--space_none.--bg-color_main-white.--style_header.--absolute-till-lg
                +b.g-row.--justify_center.--align_center.--space_none.--full_height
                  +b.g-cell.g-cols--auto.--narrow-lg
                    +b.ds-panel.--space_3xl-md
                      +e.element.--offset_horizontal
                        toggler(
                          class="burger-toggler"
                          v-slot:default='{ active, toggleActive, collapse }',
                          :required-emitting="true"
                          @toggle="$event => {$event ? $body.classList.add('is-blocked') : $body.classList.remove('is-blocked')}"
                        )
                          click-outside(:do='collapse')
                            div
                              +b.burger-wrapper
                                +b.burger(
                                  :class='{"is-active": active }'
                                  @click.prevent='toggleActive'
                                )
                                  +e.item(
                                      v-for='i in 3'
                                    )
                                +b.content(:class='{"blured-content": active}', @click.prevent='toggleActive')
                                  transition(name='fade')
                                    +b.burger-menu(v-if="active")
                                      +b.g-row.--justify_center.--align_center
                                        +b.g-cell.g-cols--auto
                                          side-bar.side-bar.side-bar--style_main(
                                            :fields="fields"
                                          )
                    +b.g-cell.g-cols--narrow.--disabled_till-lg(
                      :class="(!minimized)? 'g-cols--max-width_variant-side-bar-lg':''"
                      )
                      +b.g-row.--align_center.--space_none
                        +b.g-cell.g-cols--auto
                          +b.g-row.--justify_center.--justify_start-lg.--align_center.--space_none
                            +b.g-cell.g-cols--narrow
                              +b.ds-panel.--space_none.--space_lg-lg
                                +e.element.--offset_horizontal
                                  +b.A.ds-link(href="/")
                                    +b.ds-avatar.--size_sm.--size_md-sm
                                      +e.image(alt="company logo" src='/static/img/logo.png')
                            transition(name='scale')
                              +b.g-cell.g-cols--narrow(v-if="!minimized")
                                +b.ds-panel.--space_xs.--space_xl-lg
                                  +e.element.--offset_left
                                    +b.ds-caption.--size_2xs.--size_md-sm.--bold.--color_main {{_('BLH CONSULTING')}}
                                  +e.element.--offset_left
                                    +b.ds-caption.--size_3xs.--size_xs-sm.--light.--color_main {{_('Your Consultant in China')}}
                        +b.g-cell.--align_stretch.g-cols--3.--disabled_till-lg.--narrow-lg
                          +b.g-row.--align_center.--space_none.--full_height
                            +b.g-cell.g-cols--auto
                              +b.ds-panel.--space_md
                                +e.element.--offset_right
                                  +b.ds-inliner--size_xl
                                    +e.body(@click="minimizeSidebar")
                                      +b.SVG.svg-icon.--color_main-light.cur-pointer(
                                        :class="(minimized)? 'svg-icon--sidebar': 'svg-icon--sidebar-is-active'"
                                        data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/arrow-left-circle-outline.svg"
                                        )
                            +b.g-cell.g-cols--auto(v-if="!minimized")
                              +b.ds-panel.--space_md
                                +e.element.--offset_left.--bordered-left_style-main
            +b.g-cell.g-cols--12(
              :class="(type === 'side-bar')? 'g-cols--disabled_till-lg':''"
            )
              +b.sidebar-section
                +e.item(
                  v-for="(element, index) in fields"
                  :key="element.name"
                )
                  transition(name='opasity')
                    template(v-if="!minimized")
                      +e.title
                        +b.ds-caption.--size_sm.--bold.--color_main-white.--variant_uppercase.--spacing_xs {{element.name}}
                  +e.ROUTER-LINK.link(
                    v-for="item in element.data"
                    :key="item.name"
                    :to="{name: item.link}"
                  )
                    +e.link-content
                      +e.icon
                        +b.SVG.svg-icon.--color_main-light(
                          :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/' + item.icon + '.svg'"
                        )
                      transition(name='opasity')
                        template(v-if="!minimized")
                          +e.link-text
                            +b.ds-caption.--size_sm.--light.--color_main-light {{item.name}}
                      +e.arrow
                        +b.icon-wrapper
                          +b.SVG.svg-icon.--color_main-light.--size_sm(data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/chevron-right.svg")
</template>
<script>
const Toggler = () => import('../General/Toggler');
const ClickOutside = () => import('../General/ClickOutside');
export default {
  props: ['type', 'fields'],
  components: {
    Toggler,
    ClickOutside,
  },
  name: 'SideBar',
  data() {
    return {
      minimized: false,

    }
  },
  methods: {
    minimizeSidebar() {
      this.minimized = !this.minimized
    },
  },
  mounted() {
  },
}
</script>
