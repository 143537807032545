<template lang="pug">
  include /mixin.pug
  +b.order-section.--full_height
    +e.element.--full_height
      +b.VALIDATION-OBSERVER(
        ref="validator",
        v-slot="{ handleSubmit, valid }",
        slim,
      )
        +b.FORM.d-control-form--main(
          ref="sendedForm"
          @submit.prevent="handleSubmit(prepareData)"
        )
          +b.g-row.--justify_center.--justify_start-xl.--align_left.--appearance_spaced.--space_3xl
            +b.g-cell.g-cols--narrow.--narrow-xl
              +b.ds-panel.--space_none.--space_lg-lg.--space_none-xl
                +e.element.--offset_horizontal(
                  v-if='form.avatar'
                )
                  +b.ds-avatar.--size_5xl.--appearance_rounded.--avatar_hover.pos-relative
                    image-loader(
                      v-if="!$route.params.id"
                      @preLoadFilesHandler='getPreloadedFiles'
                    )
                    +e.image.IMG(
                      alt="user avatar"
                      :src='form.avatar'
                      )
                +e.element.--offset_horizontal(
                  v-if='!form.avatar'
                  )
                  +b.ds-avatar.--size_5xl.--appearance_rounded.--avatar_hover-none.pos-relative
                    image-loader(
                      v-if="!$route.params.id"
                      @preLoadFilesHandler='getPreloadedFiles'
                    )
                    +e.image.IMG(
                      alt="user avatar"
                      src='/static/img/no-user-image.jpg'
                      )
            +b.g-cell.g-cols--12.--7-xl.--offset_top
              +b.g-row.--justify_center.--justify_start-xl.--appearance_spaced.--space_none.--space_4xl-xl
                validation-provider(
                  vid="newEmail"
                  v-slot="{ errors }"
                )
                    +b.error-label.LABEL(v-if="errors") {{ errors[0] }}
                //- +b.g-cell.g-cols--12
                //-   +b.ds-caption.--appearance_center
                //-     +b.error-label.LABEL(v-if="nonFieldErrors") {{ nonFieldErrors }}
                +b.g-cell.g-cols--12.--10-sm.--6-md(
                  v-for="(item, name, index) in form" :key="name"
                  :class="name === 'phones' ? 'g-cols--disabled' : name === 'avatar' ? 'g-cols--disabled' : name === 'email' ? 'g-cols--disabled' : ''"
                  )
                  +b.ds-panel.--space_3xl.--space_none-xl
                    +e.element.--offset_top
                      +b.ds-panel.--space_lg.--space_none-xl
                        +e.element.--offset_horizontal
                          +b.g-row.--justify_center.--align_center.--style-main-light-input.--space_none.--relative
                            +b.g-cell.g-cols--auto
                              label.typo__label(
                                v-if="name === 'country'"
                              ) {{profileDataOptions[name].label}}
                              template(v-if="name !== 'phones' & name !== 'avatar' & name !== 'email'")
                                +b.TAG(
                                  :tag='profileDataOptions[name].tag || "d-control-input"'
                                  :name='name'
                                  :rules='profileDataOptions[name].rules || "required" '
                                  :type='profileDataOptions[name].type || "text"'
                                  :input-label='profileDataOptions[name].label'
                                  :class='(profileDataOptions[name].tag)? profileDataOptions[name].tag + "--style_most-light" : "d-control-input--style_main d-control-input--style_main-most-light"'
                                  v-model='form[name]'
                                  v-bind="profileDataOptions[name].props"
                                  :readonly="profileDataOptions[name].editing? true : false"
                                  :ref="`${name}_input`"
                                  :custom-label="nameWithLang"
                                  :disabled="name === 'country'? profileDataOptions[name].editing? true : false : false "
                                )
                            +b.g-cell.g-cols--narrow(
                                v-if="!$route.params.id"
                              )
                              +b.BUTTON.button(
                                @click="setEditingElement(name, valid)"
                              )
                                +b.ds-panel.--space_sm
                                  +e.element.--offset_right
                                    +b.ds-inliner--size_xl.hovered_scale-sm
                                      +e.body.icon-wrapper
                                        +b.SVG.svg-icon.--color_main-accent(
                                          v-show="profileDataOptions[name].editing"
                                          :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/square-edit-outline.svg'"
                                          )
                                        +b.SVG.svg-icon.--color_lite-green(
                                          v-show="!profileDataOptions[name].editing"
                                          :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/checkbox-marked-outline.svg'"
                                          )
                +b.g-cell.g-cols--12.--10-sm.--6-md(
                    v-if="!$route.params.id"
                  )
                  +b.ds-panel.--space_3xl.--space_none-xl
                    +e.element.--offset_top
                      +b.ds-panel.--space_lg.--space_none-xl
                        +e.element.--offset_horizontal
                          +b.g-row.--justify_center.--align_center.--style-main-light-input.--space_none.--relative
                            +b.g-cell.g-cols--auto
                              d-control-input(
                                name='password'
                                rules='required'
                                type='password'
                                :input-label=`_("Password")`
                                :class='"d-control-input--style_main d-control-input--style_main-most-light d-control-input--none_change-type"'
                                v-model='form'
                                readonly
                                ref="password_input"
                              )
                            +b.g-cell.g-cols--narrow
                              +b.BUTTON.button(
                                @click="modalOpener(passRecoveryModalConfig)"
                              )
                                +b.ds-panel.--space_sm
                                  +e.element.--offset_right
                                    +b.ds-inliner--size_xl.hovered_scale-sm
                                      +e.body.icon-wrapper
                                        +b.SVG.svg-icon.--color_main-accent(
                                          v-show="profileDataOptions.password.editing"
                                          :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/square-edit-outline.svg'"
                                          )
                +b.g-cell.g-cols--12.--10-sm.--6-md
                  +b.ds-panel.--space_3xl.--space_none-xl
                    +e.element.--offset_top
                      +b.ds-panel.--space_lg.--space_none-xl
                        +e.element.--offset_horizontal
                          +b.g-row.--justify_center.--align_center.--space_none
                            +b.g-cell.g-cols--12(v-if="!isManager")
                              +b.ds-panel
                                +e.element
                                  +b.g-row.--justify_center.--align_center.--style-main-light-input.--space_none.--relative(
                                    @click="addPhone()"
                                    v-if='phoneNumbers.length === 0'
                                  )
                                    +b.g-cell.g-cols--auto
                                      +b.ds-panel.--space_md
                                        +e.element.--offset_left
                                          +b.BUTTON.button.--height_1(
                                            type="button"
                                          )
                                            +b.ds-caption.--appearance_center.--color_main-gray.--light.--size_sm {{ _('Add phone number') }}
                                    +b.g-cell.g-cols--narrow
                                      +b.ds-panel.--space_sm
                                        +e.element.--offset_right
                                          +b.ds-inliner--size_xl
                                            +e.body.icon-wrapper
                                              +b.SVG.svg-icon.--color_lite-green(
                                                :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/plus.svg'"
                                                )
                            +b.g-cell.g-cols--12(v-for="(item, index) in phoneNumbers" :key="`phone_${index}`")
                              +b.ds-panel.--space_3xl
                                +e.element.--offset_bottom
                                  template(
                                    v-if="phoneNumbers.length !== 0"
                                  )
                                    +b.g-row.--justify_center.--align_center.--style-main-light-input.--space_none.--relative
                                      +b.g-cell.g-cols--auto
                                        d-control-input(
                                          autofocus
                                          @input="phoneLengthValodator(index, item.number)"
                                          :name='`phone_${index}`'
                                          :readonly="item.isSave? true : false"
                                          type='number'
                                          rules="max:16"
                                          :input-label=`_("Phone")`
                                          :class='"d-control-input--style_main d-control-input--style_main-most-light"'
                                          v-model='item.number'
                                          :key="`phone_${index}`"
                                        )
                                      +b.g-cell.g-cols--narrow(
                                        v-if="!$route.params.id"
                                      )
                                        +b.BUTTON.button
                                          +b.ds-panel.--space_sm
                                            +e.element.--offset_right
                                              +b.ds-inliner--size_xl.hovered_scale-sm
                                                +e.body.icon-wrapper
                                                  +b.SVG.svg-icon.--color_lite-green(
                                                    v-if="item.isSave ? index === phoneNumbers.length - 1 : false"
                                                    @click="addPhone(), canDelete = false"
                                                    key="plus"
                                                    :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/plus.svg'"
                                                  )
                                                  +b.SVG.svg-icon.--color_main-accent-red(
                                                    v-if="!item.number.length ? (phoneNumbers.length !== 1 ? (canDelete ? false : !item.isSave) : false) : (index !== phoneNumbers.length - 1 ? canDelete : false)"
                                                    @click="delPhone(index, item.id), canDelete = true"
                                                    key="minus"
                                                    :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/minus.svg'"
                                                  )
                                                  +b.SVG.svg-icon.--color_lite-green(
                                                    v-if="item.number.length > 16 ? false : (!item.isSave ? (item.number.length ? true : !index) : false)"
                                                    @click="savePhone(item, index), canDelete = true"
                                                    key="checkbox"
                                                    :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/checkbox-marked-outline.svg'"
                                                  )
    </template>
<script>
import {
  getAccount,
  changeAccount,
  changeEmail,
  getCountiesList,
  phoneAdd,
  getPhonesList,
  deletePhone,
  avatarAdd,
  getUsersData,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import { clone } from 'ramda';
import FormMixin, { defaultValidatorErrorsParser } from './FormMixin'

const PassRecoveryForm = () => import('../Forms/PassRecoveryForm');

export default {
  name: 'PersonalData',
  mixins: [FormMixin],
  props: ['isManager'],
  data() {
    return {
      canDelete: true,
      passRecoveryModalConfig: {
        props: {
          isComponent: PassRecoveryForm,
          title: this._('Password Recovery'),
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      emailChangeModalConfig: {
        props: {
          title: this._('Email change request accepted'),
          subtitle: this._('An email has been sent to yor  email address. Follow the direction in the mail to confirm you email'),
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      userAvatar: '',
      hasPhones: false,
      isEditing: false,
      profileData: '',
      countiesList: [],
      currentCountry: '',
      selectedCountry: '',
      location: '',
      nonFieldErrors: '',
      phoneNumbers: [],
      profileDataOptions: {
        avatar: {
          label: this._('Name'),
          editing: 'false',
        },
        firstName: {
          label: this._('Name'),
          editing: 'false',
        },
        lastName: {
          label: this._('Last name'),
          editing: 'false',
        },
        country: {
          label: this._('Country'),
          type: 'select',
          tag: 'multi-select',
          props: {
            options: [],
            trackBy: 'country',
            searchable: true,
          },
          editing: 'false',
        },
        newEmail: {
          label: this._('Email'),
          editing: 'false',
          rules: 'email',
        },
        email: {
          label: this._('Email'),
          editing: 'false',
        },
        password: {
          label: this._('Pass'),
          editing: 'false',
        },
        phones: {
          label: this._('Phone'),
          editing: 'false',
        },
        companyName: {
          label: this._('Company'),
          editing: 'false',
        },
        companyAddress: {
          label: this._('Address'),
          editing: 'false',
        },
      },
      form: {
      },
    }
  },
  methods: {
    modalOpener,
    getPreloadedFiles(data) {
      const avatarImage = data.value.url
      avatarAdd.execute({}, { avatar: avatarImage }).then(res => {
        if (200 === res.code) {
          this.form.avatar = res.data.item.avatar
        }
      })
    },
    phoneLengthValodator(index) {
      this.phoneNumbers[index].number = this.phoneNumbers[index].number.slice(0, 16);
    },
    addPhone() {
      this.hasPhones = !this.hasPhones
      this.phoneNumbers.push({
        number: '',
        isSave: false,
      })
    },
    savePhone(item, index) {
      const transformedPhone = clone(this.phoneNumbers[index].number);
      return phoneAdd.execute({}, { number: transformedPhone }).then(res => {
        if (201 === res.code) {
          const { data } = res

          item.isSave = true
          item.id = data.item.id
        }
      })
    },
    delPhone(index, id) {
      setTimeout(() => {
        if (id) {
          deletePhone.execute({ id }).then(res => {
            if (204 === res.code) {
              this.loadPhonesList()
            }
          })
        }
      }, 1);
      this.phoneNumbers.splice(index, 1)
    },
    nameWithLang({ country }) {
      return `${country}`
    },
    setEditingElement(el, valid) {
      this.profileDataOptions[el].editing = !this.profileDataOptions[el].editing
      if ('country' === this.$refs[`${ el }_input`][0].name) {
        if (!this.profileDataOptions[el].editing) {
          setTimeout(() => {
            this.$refs[`${el}_input`][0].$el.focus()
          }, 1);
        }
      } else if ('country' !== this.$refs[`${el}_input`][0].name) {
        this.$refs[`${el}_input`][0].$children[0].$children[0].$el.children[0].focus()
      }
      if (this.profileDataOptions[el].editing) {
        if ('newEmail' === el) {
          this.sendEmailChange(valid)
        } else {
          this.prepareData(valid)
        }
      }
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    errorsReset() {
      this.nonFieldErrors = ''
    },
    idGetter(value) {
      return value.id;
    },
    sendEmailChange(valid) {
      this.$refs.validator.validate();
      if (!valid) return null;

      this.loading = true;
      return this.catchFormErrors(this.emailChange())
        .finally(() => {
          this.loading = false;
        });
    },

    emailChange() {
      const transformedEmail = clone(this.form.newEmail);
      return changeEmail.execute({}, { newEmail: transformedEmail }).then(res => {
        if (202 === res.code) {
          this.modalOpener({
            props: {
              title: this._('Accepted!'),
              subtitle: res.data.item.message,
            },
            config: {
              width: '400',
              height: 'auto',
              adaptive: true,
              scrollable: true,
            },
          })
        }
      })
    },
    send(data) {
      const transformedData = clone(data)

      transformedData.country = transformedData.country.code

      return changeAccount.execute({}, transformedData)
    },
    loadPhonesList() {
      getPhonesList.execute().then(res => {
        res.data.items.forEach(element => {
          this.phoneNumbers.push({
            id: element.id,
            number: element.number,
            isSave: true,
          })
        });
      });
    },
    async loadCountriesList() {
      await getCountiesList.execute().then(res => {
        Array.from(res.data.item).forEach(element => {
          this.profileDataOptions.country.props.options.push(element)
          if (element.code === this.form.country) {
            this.form.country = element
          }
        })
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.nonFieldErrors
      this.$refs.validator.setErrors(parsed)
    },
    async loadAccountData(id) {
      if (id) {
        await getUsersData.execute({ id }).then(res => {
          this.form = res.data.item
          res.data.item.phones.forEach(element => {
            this.phoneNumbers.push({
              // eslint-disable-next-line
              id: id,
              number: element.number,
              isSave: true,
            })
          });
        });
      } else {
        await getAccount.execute().then(res => {
          this.form = res.data.item
          this.form.newEmail = this.form.email
          this.loadPhonesList()
        });
      }
    },
  },
  async mounted() {
    await this.loadAccountData(this.$route.params.id)

    await this.loadCountriesList()
  },
}
</script>
