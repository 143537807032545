<template lang="pug">
  include /mixin.pug
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ handleSubmit }",
    slim,
  ): +b.FORM.d-control-form--main(
    @submit.prevent="handleSubmit(prepareData)"
    )
    +b.order-section
      +e.element(v-if="manager")
        +b.g-row.--justify_between
          +b.g-cell.g-cols--12
            +b.ds-panel.--space_3xl
              +e.element.--offset_bottom
                +b.ds-caption.--spacing_xs.--variant_uppercase.--size_xs.--size_sm-sm.--bold.--color_main
                  | {{_('Creating order')}}
                  span(v-if="orderFor.client") &nbsp;{{ _('for:') }} {{ orderFor.client.name }}
          +b.g-cell.g-cols--6.--4-sm.--3-xl
            +b.ds-panel.--space_xl
              +e.element.--offset_top
                template
                  +b.TAG(
                    :tag="clientsSet.tag"
                    v-model='orderFor.client',
                    name='status',
                    type='select',
                    rules="required"
                    v-bind="clientsSet.props"
                    :input-label='clientsSet.label'
                    :class='clientsSet.class',
                    :placeholder='clientsSet.placeholder',
                  )
      +e.element
        +b.g-row.--justify_between.--align_center
          +b.g-cell.g-cols--12.--6-md(v-for="(address, name, index) in form" :key="address.type")
            template(v-if="!name.includes('documentSet') & !name.includes('clientComment') & !name.includes('freights')")
              +b.ds-panel.--space_3xl
                +e.element.--offset_top
                  +b.TAG(
                    :tag='deliveryOptions[name].tag || "d-control-input"'
                    v-model='form[name]',
                    :rules='deliveryOptions[name].rules',
                    :name='name',
                    :type='deliveryOptions[name].type || "text"'
                    :input-label='deliveryOptions[name].label'
                    :class='name === "deliveryCountry" ? "d-control-multiselect d-control-multiselect--style_main d-control-multiselect--style_light" : "d-control-input--style_main-light"'
                    :placeholder='deliveryOptions[name].placeholder',
                    :options="countiesList"
                    track-by="country"
                    label="country"
                    :searchable="true"
                  )
      +e.element.--offset-bottom_2xs
        +b.g-row.--appearance_spaced.--space_lg
          +b.g-cell.g-cols--12(v-for="(item, ,index) in form.freights" :key="index")
            +b.g-row.--justify_start.--align_baseline(
              :style="{marginBottom: '20px'}"
            )
              +b.g-cell.g-cols--narrow
                +b.ds-panel.--space_md
                  +e.element.--offset_bottom
                    +b.ds-caption.--spacing_xs.--variant_uppercase.--size_xs.--size_sm-sm.--bold.--color_main {{_('Transportation type')}}
              +b.g-cell.g-cols--narrow
                +b.ds-panel.--space_md
                  +e.element.--offset_bottom
                    +b.control-descriptor__check-box-errors(v-if="errors[index]")
                      +b.ds-caption {{ errors[index] }}
              +b.g-cell.g-cols--12
                +b.ds-panel
                  +e.element
                    vue-selection-controller(
                      :options="transportationTypesList"
                      :multiple="multiple"
                      :key-getter="$options.keyGetter"
                      v-model="item.transportationType"
                      v-slot="controller"
                    )
                      +b.g-row.--justify_between.--appearance_spaced.--align_stretch.--space_xl
                        +b.g-cell.g-cols--6.--3-sm.--6-md.--3-xl.--style_shipping-checkbox(
                          v-for="(option, indx, name) in controller.options" :key="option.key"
                          )
                          +b.ds-panel.--space_md.--style_shipping-checkbox(
                          :class="item.transportationType.includes(option.value) ? 'ds-panel--style_shipping-checkbox-active' : '' "
                          @click.prevent="!option.disabled? controller.change(option.value): null"
                          )
                            +e.element(
                              :key="option.key"
                            )
                              +b.g-row.--justify_center.--align_center
                                +b.g-cell.g-cols--.--auto-xl
                                  +b.ds-inliner--size_xl
                                    +e.body
                                      +b.icon-wrapper
                                        +b.SVG.svg-icon.--color_main-accent.--size_md(
                                          :class="option.value.title === 'Air' ? 'svg-icon--rotate_45' : '' "
                                          :data-src="option.value.icon"
                                          )
                                +b.g-cell.g-cols--12.--narrow-md
                                  +b.ds-panel.--space_none.--space_md-md
                                    +e.element.--offset_left
                                      +b.P.ds-caption.--size_2xs.--size_xs-md.--appearance_center.--bold.--spacing_xs.--color_main-accent.--variant_uppercase {{ option.value.title }}
            +b.g-row.--justify_center.--justify_between-md.--appearance_spaced
              +b.g-cell.g-cols--12.--6-md.--auto-xl(
                v-for="(elem, name, idx) in item" :key="`${index}_${idx}`"
                :class="((name === 'packages' || name === 'freightComment' || name === 'transportationType' ) ? 'g-cols--disabled' : '')"
              )
                template(v-if="name !== 'packages' && name !== 'freightComment' && name !== 'transportationType'")
                  +b.ds-panel.--space_3xl
                    +e.element.--offset_top
                      d-control-input(
                        v-model='item[name]',
                        :rules='cargoOptions[name].rules',
                        :name='name',
                        :input-label='cargoOptions[name].label'
                        :class="'d-control-input--style_main-light'"
                        :key="`field_${index}_${idx}`"
                        :type="cargoOptions[name].type || 'text'"
                      )
              +b.g-cell.g-cols--12.--scrollable(v-for="(itm, ind) in item['packages']" :key="ind")
                +b.ds-table.--style_cargos.--space_1
                  +e.head.THEAD
                    +e.row.TR
                      +e.cell.TH(v-if="item['packages'].length > 1")
                      +e.cell.TH(v-for="(elem, name, idx) in itm")
                        +b.ds-caption.--size_xs.--bold.--appearance_start.--color_main-white.--no-wrap.P {{packageLabels[name]}}
                  +e.body.TBODY
                    +e.row.--gray.TR
                      +e.cell.TD(v-if="item['packages'].length > 1")
                        +b.fake-input.--del-icon
                          +b.ds-panel
                            +e.element(
                              @click.prevent="item['packages'].splice(ind, 1),totalValues(item),allTotalValues()"
                            )
                              +b.ds-inliner--size_xl
                                +e.body
                                  +b.icon-wrapper
                                    +b.SVG.svg-icon.--color_main-accent-red(
                                      :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/minus-circle-outline.svg'"
                                      )
                      +e.cell.TD(
                        v-for="(elem, name, idx) in itm" :key="`${ind}_${idx}`"
                        :data-title="`${packageLabels[name]}`"
                        )
                        template(v-if="name !== 'totalPackageVolume' & name !== 'totalPackageWeight'")
                          +b.ds-panel
                            +e.element
                              +b.fake-input
                                fake-input(
                                  :type="name !== 'title'? 'number' : 'text'"
                                  :input-show="showFakeInput"
                                  :invalid="true"
                                  :model="itm[name]"
                                  :tag='"d-control-input"',
                                  @input='itm[name] = $event, totalValues(item), allTotalValues()'
                                  rules='required',
                                  :name='`${name}_${index}_${ind}_${idx}`',
                                  :class="'d-control-input--style_main-light d-control-input--style_table'"
                                  :key="`field_${ind}_${idx}`"
                                )
                        +b.fake-input(v-if="name === 'totalPackageVolume'")
                          +b.ds-panel.--space_2xlz
                            +e.element {{itm['totalPackageVolume']}}
                        +b.fake-input(v-if="name === 'totalPackageWeight'")
                          +b.ds-panel.--space_2xl
                            +e.element {{itm['totalPackageWeight']}}
              +b.g-cell.g-cols--12(v-if="form.freights.length !== 0")
                +b.g-row.--justify_center.--justify_start-md
                  +b.g-cell.g-cols--12
                    +b.g-row(
                      :class="form.freights.length === 1? 'g-row--justify_center-till-lg' : 'g-row--justify_between'"
                    )
                      +b.g-cell.g-cols--narrow
                        +b.ds-panel.--space_lg
                          +e.element.--offset_top
                            +b.BUTTON.button.--style_main-light.--full_width(
                                @click.prevent="addPackign(index)"
                                :disabled="item['packages'].length > 9"
                                :class="item['packages'].length > 9? 'button--is-disabled' : ''"
                              )
                                +b.g-row.--justify_between.--align_center
                                  +b.g-cell.g-cols--narrow
                                    +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-accent
                                      | {{ _('Add packaging') }}
                                  +b.g-cell.g-cols--auto
                                    +b.ds-inliner--size_xl
                                      +e.body
                                        +b.icon-wrapper
                                          +b.SVG.svg-icon.--color_main-accent(
                                            :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/plus-box-outline.svg'"
                                            )
                      +b.g-cell.g-cols--narrow
                        +b.ds-panel.--space_3xl(v-if="form.freights.length > 1")
                          +e.element.--offset_top
                            +b.BUTTON.button.--style_main-light.--full_width(
                                @click.prevent="form.freights.splice(index, 1), allTotalValues()"
                              )
                                +b.g-row.--justify_between.--align_center
                                  +b.g-cell.g-cols--narrow
                                    +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-accent
                                      | {{ _('Remove cargo') }}
                                  +b.g-cell.g-cols--auto
                                    +b.ds-inliner--size_xl
                                      +e.body
                                        +b.icon-wrapper
                                          +b.SVG.svg-icon.--color_main-accent-red(
                                            :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/minus-circle-outline.svg'"
                                            )
              +b.g-cell.g-cols--12(
                v-for="(element, name, idx) in item"
                v-if="name === 'freightComment'"
              )
                +b.ds-panel.--space_3xl
                  +e.element.--offset_top
                    +b.ds-panel.--space_2xl
                      +e.element.--offset_top
                        d-control-input(
                          v-model='item[name]',
                          :rules='cargoOptions[name].rules',
                          :name='name',
                          :input-label='cargoOptions[name].label'
                          :class="'d-control-input--style_main-light'"
                          :key="'field_'"
                          :placeholder='cargoOptions[name].placeholder'
                        )
      +e.element
        +b.g-row.--justify_center.--justify_start-md
          +b.g-cell.g-cols--narrow
            +b.BUTTON.button.--style_main-light.--full_width(
              @click.prevent="addCargo"
              :disabled="form.freights.length > 9"
              :class="form.freights.length > 9? 'button--is-disabled' : ''"
            )
              +b.g-row.--justify_between.--align_center
                +b.g-cell.g-cols--narrow
                  +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-accent
                    | {{ _('Add cargo') }}
                +b.g-cell.g-cols--auto
                  +b.ds-inliner--size_xl
                    +e.body
                      +b.icon-wrapper
                        +b.SVG.svg-icon.--color_main-accent(
                          :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/plus-box-outline.svg'"
                          )
      +e.element.--offset-bottom_2xs
        +b.g-row.--justify_center.--align_end.--appearance_spaced
          +b.g-cell.g-cols--12.--auto-md(
            v-for="(element, name, idx) in form"
            :class="name !== 'clientComment'? 'disabled' : ''"
            )
            template(v-if="name.includes('clientComment')")
              +b.ds-panel.--space_lg
                +e.element.--offset_top
                  d-control-input(
                      v-model='form[name]',
                      :rules='deliveryOptions[name].rules',
                      :name='name',
                      :input-label='deliveryOptions[name].label'
                      :class="'d-control-input--style_main-light'"
                      :key="'field_'"
                      :placeholder='deliveryOptions[name].placeholder'
                    )
          +b.g-cell.g-cols--narrow
            file-uploader(
              @preLoadFilesHandler='getPreloadedFiles'
              :is-disabled="filesLimit"
            )
        +b.g-row.--justify_start.--align_end(v-if="preLoadedFiles.length > 0")
          +b.g-cell.g-cols--narrow(
            v-for="(file, inx) in preLoadedFiles"
            :key="file.index"
            )
            +b.ds-panel.--space_xl
              +e.element.--offset_top
                +b.g-row.--justify_center.--align_center.--space_none
                  +b.g-cell.g-cols--narrow.--style_files-loaded
                    +b.ds-panel.--space_sm
                      +e.element.--offset_horizontal
                        +b.ds-caption.--size_sm.--color_main-accent {{file.value.name}}
                  +b.g-cell.g-cols--narrow.--style_files-del.cur-pointer(
                    @click.prevent="docDelete(inx, file.id)"
                  )
                    +b.ds-panel.--space_sm
                      +e.element.--offset_horizontal
                        +b.ds-caption.--size_sm.--color_accent-red {{_('✕')}}
      +e.element
        +b.g-row.--justify_center.--justify_between-md.--align_end.--appearance_spaced
          +b.g-cell.g-cols--12.--9-xl
            +b.g-row.--justify_center.--justify_between
              +b.g-cell.g-cols--12.--4-sm(v-for="(size, name, index) in totalSizes" :key="name")
                +b.ds-panel.--space_3xl
                  +e.element.--offset_top
                    +b.control-output.--style_main
                      +e.label
                        +b.ds-caption.--size_xs.--light.--color_main {{size.name}}
                      +b.ds-caption.--size_sm.--light.--color_main-gray {{size.value}}
          +b.g-cell.g-cols--narrow
            click-outside(:do='resetErrorsFake')
              +b.BUTTON.button.--style_main.--full_width(
                id="submit-btn"
                :type="submit"
                @click="fakeInputValidation"
              )
                +b.g-row.--justify_between.--align_center
                  +b.g-cell.g-cols--narrow
                    +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-white
                      | {{ _('Make request') }}
</template>
<script>
import {
  getClientsListSimple,
  getCountiesList,
  creatingFile,
  deleteFile,
  createTransportation,
  getTransportationTypes,
  managerShippingCreate,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import { clone } from 'ramda';
import FormMixin, { defaultValidatorErrorsParser } from '../../Forms/FormMixin'

const ClickOutside = () => import('../../General/ClickOutside');

export default {
  components: {
    ClickOutside,
  },
  props: ['manager'],
  name: 'ShippingAddForm',
  mixins: [FormMixin],
  keyGetter: x => x.id,
  data() {
    return {
      clientsSet: {
        name: 'clientsSet',
        tag: 'd-control-multiselect',
        class: 'd-control-multiselect d-control-multiselect--style_main d-control-multiselect--style_light',
        label: this._('Client'),
        placeholder: this._('Select client'),
        props: {
          options: [],
          label: 'name',
          trackBy: 'name',
          searchable: false,
          allowEmpty: false,
        },
      },
      errors: [],
      checkTypeError: '',
      showFakeInput: false,
      hasFakeInputError: false,
      transportationModalConfig: {
        props: {
          title: this._('Your order is accepted'),
          subtitle: this._('In the near future you will receive a miscalculation on the order'),
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      overallVolumes: 0,
      overallWeights: 0,
      countiesList: [],
      transportationTypesList: [],
      selectedCountry: '',
      preLoadedFiles: [],
      filesLimit: false,
      location: '',
      nonFieldErrors: '',
      type: [],
      multiple: true,
      value: '',
      clientListSimple: [],
      options: [
        { id: 1, display: this._('Regular Sea'), icon: 'ferry' },
        { id: 2, display: this._('Fast Sea'), icon: 'ferry' },
        { id: 3, display: this._('Express'), icon: 'truck-fast-outline' },
        { id: 4, display: this._('Air'), icon: 'airplane' },
      ],
      addressesTypes: [
        {
          name: 'deliveryCountry',
          placeholder: this._('Ship to country'),
          tag: 'd-control-multiselect',
          type: 'select',
        },
      ],
      totalSizes: {
        overallVolume: {
          name: this._('Overall volume'),
          value: '',
        },
        totalWeight: {
          name: this._('Total weight'),
          value: '',
        },
        volumeWeightSea: {
          name: this._('Volume Weight SEA'),
          value: '',
        },
      },
      packageLabels: {
        title: this._('Model Number'),
        height: this._('Height (cm)'),
        width: this._('Width (cm)'),
        length: this._('Length (cm)'),
        quantity: this._('Number'),
        weight: this._('Package weight (kg)'),
        totalPackageVolume: this._('Overall volume'),
        totalPackageWeight: this._('Total weight (kg)'),
      },
      cargoOptions: {
        title: {
          label: this._('Name of cargo *'),
          rules: 'required|max:255',
        },
        freightCost: {
          label: this._('Cargo value *'),
          rules: 'required|max:11',
          type: 'number',
        },
        customsCode: {
          label: this._('Customs code *'),
          rules: 'required|max:255',
          type: 'number',
        },
        deliveryAddress: {
          label: this._('Delivery address *'),
          rules: 'required|max:255',
        },
        freightComment: {
          label: this._('Comment to cargo '),
          rules: 'max:300',
          placeholder: this._('Leave a comment'),
        },
      },
      deliveryOptions: {
        pickupAddress: {
          label: this._('Address of the factory in China *'),
          placeholder: this._('PickUp From'),
          rules: 'required|max:255',
        },
        deliveryCountry: {
          label: this._('Country of delivery'),
          placeholder: this._('To country'),
          // rules: 'only_сyrillic',
          type: 'select',
          tag: 'd-control-multiselect',
          props: {
            options: this.countiesList,
          },
        },
        clientComment: {
          label: this._('Comment'),
          placeholder: this._('Leave a comment'),
          rules: 'max:3000',
        },
      },
      orderFor: {
        client: '',
      },
      form: {
        pickupAddress: '',
        deliveryCountry: '',
        clientComment: '',
        documentSet: [],
        freights: [
          {
            transportationType: [{}],
            title: '',
            freightCost: '',
            customsCode: '',
            freightComment: '',
            deliveryAddress: '',
            packages: [
              {
                title: '',
                height: '',
                width: '',
                length: '',
                quantity: '',
                weight: '',
                totalPackageVolume: '',
                totalPackageWeight: '',
              },
            ],
          },
        ],
      },
      cargo: {
        transportationType: [{}],
        title: '',
        freightCost: '',
        customsCode: '',
        freightComment: '',
        deliveryAddress: '',
        packages: [
          {
            title: '',
            height: '',
            width: '',
            length: '',
            quantity: '',
            weight: '',
            totalPackageVolume: '',
            totalPackageWeight: '',
          },
        ],
      },
      package: {
        title: '',
        height: '',
        width: '',
        length: '',
        quantity: '',
        weight: '',
        totalPackageVolume: '',
        totalPackageWeight: '',
      },
    }
  },
  watch: {

    preLoadedFiles: function filesLimiter() {
      if (5 === this.preLoadedFiles.length) {
        this.filesLimit = true
      } else {
        this.filesLimit = false
      }
    },
  },
  mounted() {
    this.loadCountriesList()
    this.loadTransportationTypes()
    this.loadClientsList()
  },
  methods: {
    modalOpener,
    getPreloadedFiles(data) {
      creatingFile.execute({}, { doc: data.value.url }).then(res => {
        if (201 === res.code) {
          this.preLoadedFiles.push({ id: res.data.item.id, value: data.value })
          this.form.documentSet.push(res.data.item.id)
        }
      })
    },
    docDelete(index, id) {
      deleteFile.execute({ id }).then(res => {
        if (204 === res.status) {
          this.preLoadedFiles.splice(index, 1)
          this.form.documentSet.splice(index, 1)
        }
      })
    },
    addPackign(index) {
      const newPackage = JSON.parse(JSON.stringify(this.package))
      const current = this.form.freights[index]
      current.packages.push(newPackage)
    },
    addCargo() {
      const newCargo = JSON.parse(JSON.stringify(this.cargo))
      this.form.freights.push(newCargo)
    },
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid || this.hasFakeInputError) return
        this.submit(valid, this.form)
      })
    },
    send(data) {
      const transformedData = clone(data);
      transformedData.freights.forEach(el => {
        el.transportationType = el.transportationType.filter(item => Object.keys(item).length)
        const transformedTypes = []
        el.transportationType.forEach(type => {
          transformedTypes.push(type.id)
        })
        el.transportationType = transformedTypes
      })
      transformedData.deliveryCountry = transformedData.deliveryCountry.code
      if (this.manager) {
        transformedData.client = this.orderFor.client.id
        return managerShippingCreate.execute({}, transformedData).then(res => {
          if (201 === res.code) {
            this.$router.push({ name: 'manager:shipping' })
            this.modalOpener({
              props: {
                title: this._('Order is accepted'),
              },
              config: {
                width: '400',
                height: 'auto',
                adaptive: true,
                scrollable: true,
              },
            })
            this.$refs.validator.reset()
          }
        })
      }
      return createTransportation.execute({}, transformedData).then(res => {
        if (201 === res.code) {
          this.$router.push({ name: 'user:orders' })
          this.modalOpener(this.transportationModalConfig)
          this.$refs.validator.reset()
        }
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.username
      this.$refs.validator.setErrors(parsed)
    },
    loadTransportationTypes() {
      getTransportationTypes.execute().then(res => {
        this.transportationTypesList = res.data.items
      });
    },
    loadClientsList() {
      if (this.manager) {
        getClientsListSimple.execute().then(res => {
          res.data.items.forEach(element => {
            this.clientsSet.props.options.push({ id: element.id, name: `${element.firstName} ${element.lastName}` })
          });
        });
      }
    },
    loadCountriesList() {
      getCountiesList.execute().then(res => {
        Array.from(res.data.item).forEach(element => {
          this.countiesList.push(element)
        });
      });
    },
    allTotalValues() {
      // (длина * высоту * ширину / 1 000 000) * Количество транспортных упаковок
      this.totalSizes.overallVolume.value = 0
      this.overallWeights = 0
      this.overallVolumes = 0
      this.form.freights.forEach(element => {
        element.packages.forEach(packj => {
          const packagesValue = packj.totalPackageVolume
          const packagesWeight = packj.totalPackageWeight
          this.overallVolumes += packagesValue
          this.overallWeights += packagesWeight
        })
      });
      this.totalSizes.overallVolume.value = this.overallVolumes
      this.totalSizes.totalWeight.value = this.overallWeights
    },
    totalValues(freights) {
      // (длина * высоту * ширину / 1 000 000) * Количество транспортных упаковок
      freights.packages.reduce((acc, pack) => {
        acc = (((pack.height * pack.width * pack.length) / 1000000)) * pack.quantity
        pack.totalPackageVolume = Number(acc.toFixed(4))
        return acc
      }, 0);
      freights.packages.reduce((acc, pack) => {
        acc = (pack.quantity * pack.weight)
        pack.totalPackageWeight = acc
        return acc
      }, 0);
    },
    resetErrorsFake() {
      if (this.hasFakeInputError) {
        this.arrayWithFakeInputs.forEach(element => {
          element.classList.remove('ds-caption--style_error')
          this.errors = []
          this.hasFakeInputError = false
        })
      }
    },
    fakeInputValidation() {
      this.arrayWithFakeInputs = Array.from(this.$refs.validator.$el.getElementsByClassName('js-fake-content'))
      this.arrayWithFakeInputs.forEach(element => {
        if (0 === element.textContent.length) {
          element.classList.add('ds-caption--style_error')
          this.hasFakeInputError = true
        }
      });
      this.form.freights.forEach(el => {
        if (1 === el.transportationType.length) {
          const idx = this.form.freights.indexOf(el)
          this.errors[idx] = this._('Select transportation type.')
          this.hasFakeInputError = true
        }
      })
    },
  },
}
</script>
