<template lang="pug">
  include /mixin.pug
  +b.control-output.--style_main.--full-height
    +e.label
      +b.ds-caption.--size_xs.--light.--color_main {{label}}
    template
      +b.ds-caption.--size_sm.--light.--color_main-gray.SPAN {{text}}
</template>
<script>
export default {
  name: 'Output',
  props: ['text', 'label'],
  data() {
    return {
    }
  },
}
</script>
