<template lang="pug">
include /mixin.pug
+b.ds-section.--space_none
  +e.element
    +b.ds-section.--space_md
      +e.element
        +b.ds-inliner--size_xl.--style_close-btn.cur-pointer.hovered_scale-sm(
          @click="$emit('close')",
        )
          +e.body
            +b.SVG.svg-icon.--size_sm.--color_main(
              data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/close.svg"
              type="button",
              :aria-label="_('Close')",
            ): span(aria-hidden="true") &times;
        +b.ds-panel.--space_xl
          +e.element.--offset_bottom
            +b.ds-caption.--size_xl.--size_2xl-lg.--appearance_center.--bold.--color_main(
              v-if="title",
            ) {{ title }}
        +b.g-row.--space_none(v-if="subtitle")
          +b.g-cell.g-cols--10.--8-sm.--9-lg
            +b.ds-panel.--space_xl
              +e.element.--offset_bottom
                +b.ds-caption.--size_sm.--size_md-lg.--appearance_center.--color_main.--light {{ subtitle }}
        component(
          v-bind="$attrs",
          @finish="finish",
          @close="$emit('close')",
          :is="isComponent",
          :initial="$options.clone(initial)",
          :path="path",
        )
</template>
<script>

import { clone } from 'ramda';

export default {
  clone,
  props: {
    initial: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    promise: Function,
    isComponent: {},
    path: {
      type: String,
    },
  },
  methods: {
    finish(res) {
      // eslint-disable-next-line no-unused-expressions
      this.promise && this.promise(res);
      this.$emit('close');
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.path) {
        window.location.pathname = this.path;
      }
      if (!this.isComponent) {
        this.$emit('close');
      }
    }, 5000);
  },
};
</script>
