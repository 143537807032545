import {
  route,
} from '../../Routing/index.js'

const ClientsListPage = () => import('./Clients/Clients.vue')
const ManagersListPage = () => import('./Managers/Managers.vue')
const ShippingRequestsList = () => import('./Shipping/ShippingRequests.vue')
const SearchRequestsList = () => import('./Search/SearchRequests.vue')
const CheckingRequestsList = () => import('./Checking/CheckingRequests.vue')
const ContractorsList = () => import('./Contractors/Contractors.vue')
const PersonalData = () => import('./Profile/PersonalData.vue')
const ClientData = () => import('./Profile/ClientProfile.vue')
const ManagerData = () => import('./Profile/ManagerProfile.vue')

const SearchingOrder = () => import('./Search/SearchOrderView.vue')
const CheckingOrder = () => import('./Checking/CheckingOrderView.vue')
const ShippingOrder = () => import('./Shipping/ShippingOrderView.vue')

const InvoiceCreateForm = () => import('../Forms/InvoiceCreateForm.vue')

const NewShippingOrder = () => import('./Shipping/NewShippingOrder.vue')
const NewSearchingOrder = () => import('./Search/NewSearchOrder.vue')
const NewCheckingOrder = () => import('./Checking/NewCheckOrder.vue')

const NotificationsList = () => import('./Notifications/ManagersNotifications.vue')

const CLIENTS_LIST = 'clients/'
const MANAGERS_LIST = 'buyers/'
const SHIPPING_LIST = 'shipping/'
const SEARCH_LIST = 'product-search/'
const CHECKING_LIST = 'checking/'
const CONTRACTORS_LIST = 'contractors/'
const PERSONAL_DATA = 'profile/'
const CLIENT_DATA = 'client/:id/:UserName/'
const MANAGER_DATA = 'buyer/:id/:UserName/'
const SEARCH_ORDER = 'product-search/order/:id/:vId/'
const SEARCH_ORDER_INVOICE = 'product-search/order/:id/:vId/invoice'
const SEARCH_ORDER_INVOICE_DATA = 'product-search/order/:id/:vId/invoice/:iId'
const CHECK_ORDER = 'product-checking/order/:id/:vId/'
const CHECK_ORDER_INVOICE = 'product-checking/order/:id/:vId/invoice'
const CHECK_ORDER_INVOICE_DATA = 'product-checking/order/:id/:vId/invoice/:iId'
const SHIPPING_ORDER = 'product-shipping/order/:id/:vId/'
const SHIPPING_ORDER_INVOICE = 'product-shipping/order/:id/:vId/invoice'
const SHIPPING_ORDER_INVOICE_DATA = 'product-shipping/order/:id/:vId/invoice/:iId'
const NOTIFICATIONS_LIST = 'notifications/'

const NEW_SHIPPING_ORDER = 'shipping-order/'
const NEW_SEARCH_ORDER = 'product-search-order/'
const NEW_CHECKING_ORDER = 'product-checking-order/'

export const NEW_SHIPPING_ORDER_NAME = 'manager:new-shipping-order'
export const NEW_SEARCH_ORDER_NAME = 'manager:new-search-order'
export const NEW_CHECKING_ORDER_NAME = 'manager:new-checking-order'

export const CLIENTS_LIST_PAGE_NAME = 'manager:clients'
export const MANAGERS_LIST_PAGE_NAME = 'manager:managers'
export const SHIPPING_LIST_PAGE_NAME = 'manager:shipping'
export const SEARCH_LIST_PAGE_NAME = 'manager:search'
export const CHECKING_LIST_PAGE_NAME = 'manager:checking'
export const CONTRACTORS_LIST_PAGE_NAME = 'manager:contractors'
export const PERSONAL_DATA_NAME = 'manager:profile'
export const CLIENT_DATA_NAME = 'manager:client-profile'
export const MANAGER_DATA_NAME = 'manager:manager-profile'
export const MANAGER_SEARCH_ORDER_NAME = 'manager:search-order'
export const MANAGER_SHIPPING_ORDER_NAME = 'manager:shipping-order'
export const MANAGER_CHECKING_ORDER_NAME = 'manager:checking-order'
export const NOTIFICATIONS_LIST_NAME = 'manager:notifications'
export const MANAGER_ORDER_INVOICE_SHIPPING = 'manager:invoice-shipping'
export const MANAGER_ORDER_INVOICE_SEARCHING = 'manager:invoice-searching'
export const MANAGER_ORDER_INVOICE_CHECKING = 'manager:invoice-checking'
export const MANAGER_ORDER_INVOICE_EDIT_SHIPPING = 'manager:invoice-edit-shipping'
export const MANAGER_ORDER_INVOICE_EDIT_SEARCHING = 'manager:invoice-edit-searching'
export const MANAGER_ORDER_INVOICE_EDIT_CHECKING = 'manager:invoice-edit-checking'

export function createRoutes() {
  return [
    route(CLIENTS_LIST, ClientsListPage, CLIENTS_LIST_PAGE_NAME, { meta: { parent: 'all-users', current: 'c-list' } }),
    route(MANAGERS_LIST, ManagersListPage, MANAGERS_LIST_PAGE_NAME, { meta: { parent: 'all-users', current: 'm-list' } }),
    route(SHIPPING_LIST, ShippingRequestsList, SHIPPING_LIST_PAGE_NAME, { meta: { parent: 'all-orders', current: 'shipping' } }),
    route(SEARCH_LIST, SearchRequestsList, SEARCH_LIST_PAGE_NAME, { meta: { parent: 'all-orders', current: 'searching' } }),
    route(CHECKING_LIST, CheckingRequestsList, CHECKING_LIST_PAGE_NAME, { meta: { parent: 'all-orders', current: 'checking' } }),
    route(CONTRACTORS_LIST, ContractorsList, CONTRACTORS_LIST_PAGE_NAME, { meta: { current: 'ctr-list' } }),
    route(PERSONAL_DATA, PersonalData, PERSONAL_DATA_NAME, { meta: { current: 'personal' } }),
    route(CLIENT_DATA, ClientData, CLIENT_DATA_NAME, { meta: { parent: 'all-users', current: 'c-list' } }),
    route(MANAGER_DATA, ManagerData, MANAGER_DATA_NAME, { meta: { parent: 'all-users', current: 'm-list' } }),
    route(SHIPPING_ORDER, ShippingOrder, MANAGER_SHIPPING_ORDER_NAME, { meta: { parent: 'all-orders', current: 'shipping' } }),
    // INVOICES
    route(SHIPPING_ORDER_INVOICE, InvoiceCreateForm, MANAGER_ORDER_INVOICE_SHIPPING, { meta: { parent: 'all-orders', current: 'shipping' }, props: true }),
    route(SEARCH_ORDER_INVOICE, InvoiceCreateForm, MANAGER_ORDER_INVOICE_SEARCHING, { meta: { parent: 'all-orders', current: 'searching' }, props: true }),
    route(CHECK_ORDER_INVOICE, InvoiceCreateForm, MANAGER_ORDER_INVOICE_CHECKING, { meta: { parent: 'all-orders', current: 'checking' }, props: true }),
    // INVOICES EDITING
    route(SHIPPING_ORDER_INVOICE_DATA, InvoiceCreateForm, MANAGER_ORDER_INVOICE_EDIT_SHIPPING, { meta: { parent: 'all-orders', current: 'shipping' }, props: true }),
    route(SEARCH_ORDER_INVOICE_DATA, InvoiceCreateForm, MANAGER_ORDER_INVOICE_EDIT_SEARCHING, { meta: { parent: 'all-orders', current: 'searching' }, props: true }),
    route(CHECK_ORDER_INVOICE_DATA, InvoiceCreateForm, MANAGER_ORDER_INVOICE_EDIT_CHECKING, { meta: { parent: 'all-orders', current: 'checking' }, props: true }),
    // -------------
    route(CHECK_ORDER, CheckingOrder, MANAGER_CHECKING_ORDER_NAME, { meta: { parent: 'all-orders', current: 'checking' } }),
    route(SEARCH_ORDER, SearchingOrder, MANAGER_SEARCH_ORDER_NAME, { meta: { parent: 'all-orders', current: 'searching' } }),
    route(NOTIFICATIONS_LIST, NotificationsList, NOTIFICATIONS_LIST_NAME, { meta: { current: 'notice' } }),
    route(NEW_SHIPPING_ORDER, NewShippingOrder, NEW_SHIPPING_ORDER_NAME, { meta: { parent: 'all-orders', current: 'shipping' } }),
    route(NEW_SEARCH_ORDER, NewSearchingOrder, NEW_SEARCH_ORDER_NAME, { meta: { parent: 'all-orders', current: 'searching' } }),
    route(NEW_CHECKING_ORDER, NewCheckingOrder, NEW_CHECKING_ORDER_NAME, { meta: { parent: 'all-orders', current: 'checking' } }),

    { path: '', redirect: { name: CLIENTS_LIST_PAGE_NAME } },
    { path: '*', redirect: { name: '404' } },
  ]
}
