<template lang="pug">
  include /mixin.pug
  +b.VALIDATION-OBSERVER(
        ref="validator",
        v-slot="{ handleSubmit }",
        slim,
      )
    +b.FORM.d-control-form.--main.--full_height(
        @submit.prevent="handleSubmit(prepareData)"
        )
      +b.order-section.--full_height
        +e.element(v-if="manager")
          +b.g-row.--justify_between
            +b.g-cell.g-cols--12
              +b.ds-panel.--space_3xl
                +e.element.--offset_bottom
                  +b.ds-caption.--spacing_xs.--variant_uppercase.--size_xs.--size_sm-sm.--bold.--color_main
                    | {{_('Creating order')}}
                    span(v-if="orderFor.client") &nbsp;{{ _('for:') }} {{ orderFor.client.name }}
            +b.g-cell.g-cols--6.--4-sm.--3-xl
              +b.ds-panel.--space_xl
                +e.element.--offset_top
                  template
                    +b.TAG(
                      :tag="clientsSet.tag"
                      v-model='orderFor.client',
                      name='status',
                      type='select',
                      rules="required"
                      v-bind="clientsSet.props"
                      :input-label='clientsSet.label'
                      :class='clientsSet.class',
                      :placeholder='clientsSet.placeholder',
                    )
        +e.element.--full_height
          +b.g-row.--justify_between.--space_3xl
            +b.g-cell.g-cols--6.--12-till-xl
              +b.g-row.--appearance_column.--full_height
                +b.g-cell.g-cols--12(
                  v-for="(item, name, index) in form" :key="`field_${name}_${index}`"
                  :class="!name.includes('documentSet') & !name.includes('photoSet') & !name.includes('similarLink')? '' : 'g-cols--disabled'"
                )
                  template(v-if="!name.includes('documentSet') & !name.includes('photoSet') & !name.includes('similarLink')")
                    +b.ds-panel.--space_3xl
                      +e.element.--offset_top
                    +b.TAG(
                      :tag='formSettings[name].tag || "d-control-input"'
                      v-model='form[name]',
                      :rules='formSettings[name].rules',
                      :name='name',
                      type='text'
                      :input-label='formSettings[name].label'
                      :class="!name.includes('productName')? 'd-control-input--style_main-light d-control-input--height_2xl' : 'd-control-input--style_main-light'"
                      :placeholder='formSettings[name].placeholder',
                    )
            +b.g-cell.g-cols--6.--12-till-xl
              +b.g-row.--justify_start.--spce_none.--full_height.--appearance_column
                +b.g-cell.g-cols--12(
                  v-for="(item, name, index) in form" :key="`field_${formSettings[name]}_${index}`"
                  :class="!name.includes('similarLink')? 'g-cols--disabled' : ''"
                  )
                  template(v-if="name.includes('similarLink')")
                    +b.ds-panel.--space_3xl
                      +e.element.--offset_top
                        +b.TAG(
                          :tag='formSettings[name].tag || "d-control-input"'
                          v-model='form[name]',
                          :rules='formSettings[name].rules',
                          :name='name',
                          type='text'
                          :input-label='formSettings[name].label'
                          :class="name.includes('similarLink')? 'd-control-input--style_main-light d-control-input--height_3xl' : 'd-control-input--style_main-light'"
                          :placeholder='formSettings[name].placeholder',
                        )
                +b.g-cell.g-cols--auto
                  +b.g-row.--justify_start.--space_3xl.--full_height
                    +b.g-cell.g-cols--12
                      +b.g-row.--justify_center.--justify_start-md
                        +b.g-cell.g-cols--narrow
                          +b.ds-panel.--space_3xl
                            +e.element.--offset_top
                              ImageUploader(
                                @preLoadFilesHandler='getPreloadedImage'
                                :is-disabled="imageLimit"
                              )
                        +b.g-cell.g-cols--narrow
                          +b.ds-panel.--space_3xl
                            +e.element.--offset_top
                              file-uploader(
                                @preLoadFilesHandler='getPreloadedFiles'
                                :is-disabled="filesLimit"
                              )
                +b.g-cell.g-cols--12
                  +b.g-row.--justify_center.--justify_end-md.--full_height.--space_bottom-xs
                    +b.g-cell.--align_end.g-cols--narrow
                      +b.ds-panel.--space_3xl
                        +e.element.--offset_top
                          +b.BUTTON.button.--style_main.--full_width(
                            id="submit-btn"
                            :type="submit"
                          )
                            +b.g-row.--justify_between.--align_center
                              +b.g-cell.g-cols--narrow
                                +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-white
                                  | {{ _('Make request') }}
            +b.g-cell.g-cols--6(v-if="preLoadedFiles.docs.length > 0 || preLoadedImage.images.length > 0")
              +b.g-row.--justify_start.--align_end
                +b.g-cell.g-cols--narrow(
                  v-for="(file, inx) in preLoadedFiles.docs"
                  :key="file.index"
                  )
                  +b.ds-panel.--space_lg
                    +e.element.--offset_top
                      +b.g-row.--justify_center.--align_center.--space_none
                        +b.g-cell.g-cols--narrow.--style_files-loaded
                          +b.ds-panel.--space_sm
                            +e.element.--offset_horizontal
                              +b.ds-caption.--size_sm.--color_main-accent {{file.value.name}}
                        +b.g-cell.g-cols--narrow.--style_files-del.cur-pointer(
                          @click.prevent="docDelete(inx, file.id)"
                        )
                          +b.ds-panel.--space_sm
                            +e.element.--offset_horizontal
                              +b.ds-caption.--size_sm.--color_accent-red {{_('✕')}}
              +b.g-row.--justify_start.--align_end
                +b.g-cell.g-cols--narrow(
                  v-for="(file, indx) in preLoadedImage.images"
                  :key="file.index"
                  )
                  +b.ds-panel.--space_lg
                    +e.element.--offset_vertical
                      +b.g-row.--justify_center.--align_center.--space_none
                        +b.g-cell.g-cols--narrow.--style_files-loaded
                          +b.ds-panel.--space_sm
                            +e.element.--offset_horizontal
                              +b.ds-caption.--size_sm.--color_main-accent {{file.value.name}}
                        +b.g-cell.g-cols--narrow.--style_files-del.cur-pointer(
                          @click.prevent="imageDelete(indx, file.id)"
                        )
                          +b.ds-panel.--space_sm
                            +e.element.--offset_horizontal
                              +b.ds-caption.--size_sm.--color_accent-red {{_('✕')}}
</template>
<script>
import {
  creatingFile,
  creatingImage,
  deleteFile,
  deleteImage,
  productSearch,
  getClientsListSimple,
  managerSearchingCreate,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import { clone } from 'ramda';
import FormMixin, { defaultValidatorErrorsParser } from '../../Forms/FormMixin'

export default {
  components: {
  },
  props: ['manager'],
  name: 'roductSearchForm',
  mixins: [FormMixin],
  keyGetter: x => x.id,
  data() {
    return {
      clientsSet: {
        name: 'clientsSet',
        tag: 'd-control-multiselect',
        class: 'd-control-multiselect d-control-multiselect--style_main d-control-multiselect--style_light',
        label: this._('Client'),
        placeholder: this._('Select client'),
        props: {
          options: [],
          label: 'name',
          trackBy: 'name',
          searchable: false,
          allowEmpty: false,
        },
      },
      errors: '',
      checkTypeError: '',
      roductSearchFormModalConfig: {
        props: {
          title: this._('Your order is accepted'),
          subtitle: this._('In the near future you will receive a miscalculation on the order'),
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      preLoadedImage: {
        images: [],
      },
      preLoadedFiles: {
        docs: [],
      },
      filesLimit: false,
      imageLimit: false,
      nonFieldErrors: '',
      type: [],
      multiple: true,
      value: '',
      formSettings: {
        productName: {
          label: this._('Product Name *'),
          placeholder: this._('Product Name'),
          rules: 'required',
        },
        characteristics: {
          label: this._('Important product characteristics *'),
          placeholder: this._('Enter your text'),
          rules: 'required|max:3000',
          tag: 'd-control-textarea',
        },
        clientComment: {
          label: this._('Comment'),
          placeholder: this._('Enter your comment'),
          rules: 'max:3000',
          tag: 'd-control-textarea',
        },
        similarLink: {
          label: this._('Product Links and Keywords *'),
          placeholder: this._('Link to product (similar product)'),
          rules: 'required|max:3000',
          tag: 'd-control-textarea',
        },
        documentSet: [],
        photoSet: [],
      },
      orderFor: {
        client: '',
      },
      form: {
        productName: '',
        characteristics: '',
        clientComment: '',
        similarLink: '',
        documentSet: [],
        photoSet: [],
      },
    }
  },
  watch: {
    'preLoadedFiles.docs': function filesLimiter() {
      if (5 === this.preLoadedFiles.docs.length) {
        this.filesLimit = true
      } else {
        this.filesLimit = false
      }
    },
    'preLoadedImage.images': function ImageLimiter() {
      if (5 === this.preLoadedImage.images.length) {
        this.imageLimit = true
      } else {
        this.imageLimit = false
      }
    },
  },
  methods: {
    modalOpener,
    getPreloadedFiles(data) {
      creatingFile.execute({}, { doc: data.value.url }).then(res => {
        if (201 === res.code) {
          this.preLoadedFiles.docs.push({ id: res.data.item.id, value: data.value })
          this.form.documentSet.push(res.data.item.id)
        }
      })
    },
    getPreloadedImage(data) {
      creatingImage.execute({}, { image: data.value.url }).then(res => {
        if (201 === res.code) {
          this.preLoadedImage.images.push({ id: res.data.item.id, value: data.value })
          this.form.photoSet.push(res.data.item.id)
        }
      })
    },
    imageDelete(index, id) {
      deleteImage.execute({ id }).then(res => {
        if (204 === res.status) {
          this.preLoadedImage.images.splice(index, 1)
          this.form.photoSet.splice(index, 1)
        }
      })
    },
    docDelete(index, id) {
      deleteFile.execute({ id }).then(res => {
        if (204 === res.status) {
          this.preLoadedFiles.docs.splice(index, 1)
          this.form.documentSet.splice(index, 1)
        }
      })
    },
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid || this.hasFakeInputError) return
        this.submit(valid, this.form)
      })
    },
    send(data) {
      const transformedData = clone(data);
      if (this.manager) {
        transformedData.client = this.orderFor.client.id
        return managerSearchingCreate.execute({}, transformedData).then(res => {
          if (201 === res.code) {
            this.$router.push({ name: 'manager:search' })
            this.modalOpener({
              props: {
                title: this._('Order is accepted'),
              },
              config: {
                width: '400',
                height: 'auto',
                adaptive: true,
                scrollable: true,
              },
            })
            this.$refs.validator.reset()
          }
        })
      }
      return productSearch.execute({}, transformedData).then(res => {
        if (201 === res.code) {
          this.$router.push({ name: 'user:orders' })
          this.modalOpener(this.roductSearchFormModalConfig)
          this.$refs.validator.reset()
        }
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.username
      this.$refs.validator.setErrors(parsed)
    },
    loadClientsList() {
      if (this.manager) {
        getClientsListSimple.execute().then(res => {
          res.data.items.forEach(element => {
            this.clientsSet.props.options.push({ id: element.id, name: `${element.firstName} ${element.lastName}` })
          });
        });
      }
    },
  },
  mounted() {
    this.loadClientsList()
  },
}
</script>
