<template lang="pug">
  include /mixin.pug
  +b.order-section.--full_height
    +e.element.--offset-bottom_2xs.--full_height
      +b.g-row.--justify_center.--appearance_spaced.--space_xl-md.--align_center
        +b.g-cell.g-cols--12
          +b.g-row.--align_end.--justify_between
            +b.g-cell.g-cols--12.--6-md.--auto-lg
              +b.g-row.--space_xl-xl
                +b.g-cell.g-cols--12.--6-sm.--4-xl(
                  v-for="(filter, name, index) in filters" :key="`field_${name}_${index}`"
                )
                  template
                    +b.ds-panel.--space_3xl
                      +e.element.--offset_top
                    +b.TAG(
                      @input="() => debounceAction()"
                      :tag="filters[name].tag"
                      v-model='form[filter.name]',
                      :name='name',
                      type='select',
                      v-bind="filters[name].props"
                      :input-label='filters[name].label'
                      :class='filters[name].class',
                      :placeholder='filters[name].placeholder',
                    )
            +b.g-cell.g-cols--narrow(v-if="usersType === 'managers'")
              +b.ds-panel.--space_3xl-md
                +e.element.--offset_top
                  +b.BUTTON.button.--style_main.--full_width(
                    @click="modalOpener(addManagerFormModalConfig)"
                  )
                    +b.g-row.--justify_between.--align_center
                      +b.g-cell.g-cols--auto
                        +b.ds-inliner--size_xl
                          +e.body
                            +b.icon-wrapper
                              +b.SVG.svg-icon.--color_main-white(
                                :data-src="'https://unpkg.com/@mdi/svg@5.9.55/svg/plus-box-outline.svg'"
                                )
                      +b.g-cell.g-cols--narrow
                        +b.ds-caption.--size_sm.--appearance_center.--bold.--color_main-white
                          | {{ _('Add manager') }}
        +b.g-cell.g-cols--12.--scrollable
          +b.ds-table.--style_cargos.--variant_hoverable
            +e.head.THEAD
              +e.row.TR
                +e.cell.TH(v-for="(item, name, index) in ordersTableSetting" :key="name")
                  +b.ds-caption.--size_xs.--bold.--appearance_start.--color_main-white.--no-wrap.SPAN {{ordersTableSetting[name]}}
            +e.body.TBODY
              +e.row.--gray.TR(v-for="client in clientsList" :key="client.id")
                +e.cell.TD
                  +b.ds-panel.--space_lg
                    +e.element.--offset_full
                      +b.ROUTER-LINK.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.--no-wrap(
                        :to="{name: userRoute, params: {id: client.id, UserName: `${client.firstName} ${client.lastName}`}}"
                      ) {{client.firstName}} {{client.lastName}}
                +e.cell.TD
                  +b.ds-panel.--space_lg
                    +e.element.--offset_full
                      +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.--no-wrap.SPAN
                        | {{client.dateJoined}}
                +e.cell.TD
                  +b.ds-panel.--space_
                    +e.element.--offset_full
                      +b.g-row.--justify_start.--space_none.--align_center
                        +b.g-cell.g-cols--narrow
                          +b.ds-panel.--space_xs
                            +e.element.--offset_full
                              +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.P(
                                v-for="(phone, index) in client.phones" :key="index"
                                ) {{phone.number}} ,
                        +b.g-cell.g-cols--narrow
                          +b.ds-panel.--space_xs
                            +e.element.--offset_left
                              +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.A(
                                :href="`mailto:${client.email}`"
                              )
                                +b.ds-caption.--hovered_underline.SPAN
                                  | {{client.email}}
                +e.cell.TD
                  +b.ds-panel.--space_lg.--relative
                    toggler(
                      v-slot="{ active, collapse, toggleActive }"
                    )
                      click-outside(
                        :do='collapse'
                      ): div
                        +e.element.--offset_full
                          +b.ROUTER-LINK.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.--no-wrap(
                            v-if="client.activeOrders.length === 1"
                            :to="{name: client.activeOrders[0].link, params: {id: client.activeOrders[0]['id'], vId: client.activeOrders[0]['viewId']}}"
                            ) {{client.activeOrders[0].viewId? "№"+ client.activeOrders[0].viewId : ''}}
                          +b.ds-caption.--size_sm.--hovered_underline.--light.--appearance_start.--color_main-accent.--no-wrap.cur-pointer.SPAN(
                            v-if="client.activeOrders.length > 1"
                            @click="toggleActive"
                            ) {{client.activeOrders.length}} {{_('orders')}}
                          +b.ds-caption.--size_sm.--light.--appearance_start.--color_main-gray.--no-wrap.SPAN(
                            v-if="client.activeOrders.length === 0"
                            ) {{_('No active orders')}}
                        transition(name='scale')
                          +e.element.--offset_full(
                            v-show="active"
                            :ref="`order_${client.id}`"
                            )
                            +b.ds-panel.--space_xl.--shadowed_main.--orders-list
                              +e.element.--offset_full(v-for="(order, index) in client.activeOrders" :key="index")
                                +b.ROUTER-LINK.ds-caption.--hovered_underline.--size_sm.--light.--appearance_start.--color_main-accent.--no-wrap(
                                  :to="{name: order['link'], params: {id: order['id'], vId: order.viewId}}"
                                ) {{order.viewId? "№"+ order.viewId : ''}}
                +e.cell.TD
                  +b.ds-panel.--space_lg
                    +e.element.--offset_full
                      +b.ds-caption.--size_sm.--appearance_start.--color_main.--no-wrap.P
                        +b.ds-caption {{client.sumInvoices}}
                        +b.ds-caption.--light /
                        +b.ds-caption.--light(
                          :class="client.sumInvoices !== client.sumPayments? 'ds-caption--color_accent-red' : 'ds-caption--color_lite-green'"
                        ) {{client.sumPayments}} {{_('USD')}}
                +e.cell.TD
                  +b.ds-panel.--space_lg
                    +e.element.--offset_full
                      +b.ds-caption.--size_sm.--light.--appearance_start.--color_main.--no-wrap.SPAN
                        | {{client.isActive? _('Active') : _('Inactive')}}
    +e.element
      +b.g-cell.g-cols--12
        +b.g-row.--justify_center.--justify_start-lg.--align_center
          +b.g-cell.g-cols--narrow.UI-LIMIT-PAGINATION(
            :pagination='pagination'
            @input='handlePaginate'
          )
</template>
<script>
import {
  getClientsList,
  getBuyerList,
} from '@services/auth.service.js';
import { debounce, modalOpener } from '@utils/helpers'
import Toggler from '@components/General/Toggler.vue'
import { clone } from 'ramda';
import {
  MANAGER_SHIPPING_ORDER_NAME,
  MANAGER_SEARCH_ORDER_NAME,
  MANAGER_CHECKING_ORDER_NAME,
  CLIENT_DATA_NAME,
  MANAGER_DATA_NAME,
} from '../routes'

const AddManagerForm = () => import('../../Forms/AddManagerForm');

export default {
  name: 'UsersList',
  props: ['usersType'],
  // mixins: [FormMixin],
  components: { Toggler },
  data() {
    return {
      pagination: {},
      addManagerFormModalConfig: {
        props: {
          isComponent: AddManagerForm,
          title: this._('Adding manager'),
        },
        config: {
          width: '428',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        actor: this.getList,
      },
      userRoute: '',
      loadDataLink: '',
      breadcumbs: {
        parent: this._('ORDERS'),
        currentChild: this._('My orders'),
        link: 'CLIENT_ORDERS_NAME',
      },
      ordersTableSetting: {
        user: this._('User data'),
        dateJoined: this._('Date'),
        contactInfo: this._('Contact Information'),
        activeOrders: this._('Active orders'),
        sumPayments: this._('Payment'),
        isActive: this._('Status'),
      },
      filterSettings: [],
      clientsList: [],
      searchingResults: [],
      form: {
        search: '',
        status: '',
      },
      filters: {
        keywordsFilter: {
          name: 'search',
          model: '',
          tag: 'd-control-input',
          class: 'd-control-input--style_main-light',
          label: this._('Search'),
          placeholder: this._('Name / Last Name'),
        },
        statusFilter: {
          name: 'status',
          model: '',
          tag: 'd-control-multiselect',
          class: 'd-control-multiselect d-control-multiselect--style_main d-control-multiselect--style_light',
          label: this._('User status'),
          placeholder: this._('Select status'),
          props: {
            options: [
              { name: this._('Active'), code: true },
              { name: this._('Inactive'), code: false },
              { name: this._('View all'), code: '' },
            ],
            label: 'name',
            trackBy: 'name',
            searchable: false,
            allowEmpty: false,
          },
        },
      },
    }
  },
  created() {
    const delay = 1000;
    this.debounceAction = debounce(this.getList, delay);
  },
  methods: {
    modalOpener,
    getList(params = this.form) {
      // { is_active: 'is_active', search : this.filters[name].model }
      const transformedData = clone(params)
      if (params && params.status) {
        transformedData.is_active = params.status.code
      }
      this.loadDataLink.execute(transformedData).then(res => {
        this.pagination = res.data.pagination
        this.clientsList = res.data.items
        this.clientsList.forEach(element => {
          element.activeOrders.forEach(item => {
            if ('TransportationOrder' === item.resourcetype) {
              item.link = MANAGER_SHIPPING_ORDER_NAME
            } else if ('SearchOrder' === item.resourcetype) {
              item.link = MANAGER_SEARCH_ORDER_NAME
            } else {
              item.link = MANAGER_CHECKING_ORDER_NAME
            }
          });
        });
      });
    },
    handlePaginate(params) {
      this.getList({ ...this.form, ...params })
    },
    typeUsersCheck() {
      if ('clients' === this.usersType) {
        this.loadDataLink = getClientsList
        this.userRoute = CLIENT_DATA_NAME
      } else {
        this.loadDataLink = getBuyerList
        this.userRoute = MANAGER_DATA_NAME
      }
    },

  },
  mounted() {
    this.typeUsersCheck()
    this.getList()
    //   const delay = 500;
    //   this.debounceAction = debounce(this.getList(this.form), delay);
  },
}
</script>
