<template lang="pug">
  include /mixin.pug
  +b.footer
    +b.ds-section.--space_2xs.--space_sm-xl.--bg-color_main-white
      +e.element
        +b.g-row.--space_none.--justify_between.--align_center
          +b.g-cell.g-cols--12.--4-xl
            +b.g-row.--space_none.--justify_between.--justify_around-xl.--align_center
              +b.g-cell.g-cols--6.--narrow-xl
                +b.ds-panel
                  +e.element
                    +b.ds-caption.--appearance_center.--size_2xs.--size_xs-sm.--normal.--color_main {{_('© BLH Consulting: 2021')}}
              +b.g-cell.g-cols--6.--narrow-xl
                +b.g-row.--space_none.--justify_around.--align_center.--appearance_nowrap
                  +b.g-cell.g-cols--narrow(
                    v-for="(link, index) in shareElements" :key="index"
                  )
                    +b.ds-panel.--space_xs.--space_xl-xl
                      +e.element.--offset_horizontal
                        +b.A.link(
                          :href="link.url"
                          target="_blank"
                        )
                          +b.ds-inliner--size_xl.hovered_scale-sm
                            +e.body
                              +b.icon-wrapper
                                +b.IMG.svg-icon.--color_main-accent.--size_sm(
                                  :src="'/uploads/' + link.icon"
                                  )
          +b.g-cell.g-cols--6.--4-xl
            +b.g-row.--justify_center.--align_center.--appearance_spaced
              +b.g-cell.g-cols--12.--narrow-xl
                +b.ds-panel.--space_sm
                  +e.element.--offset_horizontal
                    +b.ds-caption.--hovered_underline.--size_2xs.--appearance_center.--size_xs-sm.--normal.--color_main
                      +b.link( href=`tel:${mainPhone}`) {{_(mainPhone)}}
              +b.g-cell.g-cols--12.--narrow-xl
                +b.ds-panel.--space_sm
                  +e.element.--offset_horizontal
                    +b.ds-caption.--hovered_underline.--size_2xs.--appearance_center.--size_xs-sm.--normal.--color_main
                      +b.link( href=`mailto:${mainEmail}`) {{_(mainEmail)}}
              +b.g-cell.g-cols--12.--narrow-xl
                +b.ds-panel.--space_sm
                  +e.element.--offset_horizontal
                    +b.ds-caption.--size_2xs.--appearance_center.--size_xs-sm.--normal.--color_main {{_('Berdyansk, st. Truth 80')}}
          +b.g-cell.g-cols--6.--4-xl
            +b.g-row.--justify_around.--align_center.--full_width.--appearance_spaced
              +b.g-cell.g-cols--12.--narrow-xl
                +b.g-row.--justify_around.--justify_center-xl.--align_center.--space_xl
                  +b.g-cell.g-cols--12.--narrow-xl
                    +b.ds-panel
                      +e.element
                        +b.ds-caption.--size_2xs.--appearance_center.--size_xs-sm.--normal.--color_main.--hovered_underline
                          +b.A.link(:href="privacyPolicy") {{_('Privacy Policy')}}
                  +b.g-cell.g-cols--12.--narrow-xl
                    +b.ds-panel
                      +e.element
                        +b.ds-caption.--size_2xs.--appearance_center.--size_xs-sm.--normal.--color_main.--hovered_underline
                          +b.A.link(:href="temsAndConditions") {{_('Terms & Conditions')}}
              +b.g-cell.g-cols--narrow.--narrow-xl
                +b.g-row.--justify_center.--justify_start-lg.--align_center.--space_none
                  +b.g-cell.g-cols--12-till-md
                    +b.authors.A(
                      href='https://webcase.com.ua'
                      target='_blank',
                      rel='nofollow noindex',
                    )
                      +b.SVG.svg-icon.--color_main-accent.--size_lg(
                        :data-src="'/static/svg/uEA0A-webcase_purple_black.svg'"
                        width='129',
                        height='33'
                        )
                      +e.text--color_main-black.SPAN {{_('сайт под ключ')}}
</template>
<script>
export default {
  components: {
  },
  name: 'FooterSection',
  data() {
    this.privacyPolicy = window.PRIVACY_POLICY
    this.shareElements = window.SOCIALS
    this.mainPhone = window.MAIN_PHONE
    this.mainEmail = window.MAIN_EMAIL
    this.temsAndConditions = window.TERMS_AND_СONDITIONS
    return {
    }
  },
  methods: {

  },
  mounted() {
  },
}
</script>
