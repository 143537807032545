<template lang="pug">
  include /mixin.pug
  +b.ds-section.--space_none.--bg-color_main-extra-light
    +e.element
      +b.g-row.--justify_center.--space_none
        +b.g-cell.g-cols--narrow
          side-bar.side-bar.side-bar--style_main(
            :type="'side-bar'")
        +b.g-cell.g-cols--auto
          +b.g-row.--justify_center.--space_none
            +b.g-cell.g-cols--12
              header-section
</template>
<script>
export default {
  name: 'ClientCabinet',
  data() {
    return {
      isMinimise: false,
    }
  },
  methods: {
  },
}
</script>
