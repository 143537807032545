import {
  route,
} from '../../Routing/index.js'

const ClientOrdersList = () => import('./Orders/ClientsOrders.vue')
const PersonalData = () => import('./Profile/PersonalData.vue')
const NotificationsList = () => import('./Notifications/BuyerNotifications.vue')

const SearchOrder = () => import('./Orders/SearchOrderView.vue')

const CLIENT_ORDERS_LIST = 'orders/'
const PERSONAL_DATA = 'profile/'
const NOTIFICATIONS_LIST = 'notifications/'

const SEARCH_ORDER = 'search-order/:id/:vId/'

export const CLIENT_ORDERS_LIST_NAME = 'buyer:orders'
export const PERSONAL_DATA_NAME = 'buyer:profile'
export const NOTIFICATIONS_LIST_NAME = 'buyer:notifications'

export const BUYER_SEARCH_ORDER_NAME = 'buyer:search-order'

export function createRoutes() {
  return [
    route(CLIENT_ORDERS_LIST, ClientOrdersList, CLIENT_ORDERS_LIST_NAME, { meta: { parent: 'all-orders', current: 'b-searching' } }),
    route(PERSONAL_DATA, PersonalData, PERSONAL_DATA_NAME, { meta: { current: 'personal' } }),
    route(NOTIFICATIONS_LIST, NotificationsList, NOTIFICATIONS_LIST_NAME, { meta: { current: 'notice' } }),

    route(SEARCH_ORDER, SearchOrder, BUYER_SEARCH_ORDER_NAME, { meta: { parent: 'all-orders', current: 'b-searching' } }),
    { path: '', redirect: { name: CLIENT_ORDERS_LIST_NAME } },
    { path: '*', redirect: { name: '404' } },
  ]
}
