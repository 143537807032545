<template lang="pug">
include /mixin.pug
row(
  :align="'center'"
)
  cell
    +b.P.ds-caption.--size_sm.--color_main.lang-label {{ _('Language:') }}
  cell
    d-control-multiselect.d-control-multiselect.d-control-multiselect--style_main(
      :options='languages'
      v-model='selectLang'
      @input='setLanguage'
      track-by='label'
      label='title'
    )
</template>
<script>

import { prefixLanguage, CURRENT_LANGUAGE } from '@utils/urls'

export default {
  name: 'language-bar',
  data() {
    return {
      language: CURRENT_LANGUAGE,
      languages: [
        { label: 'uk', title: this._('UK') },
        { label: 'ru', title: this._('RU') },
        { label: 'en', title: this._('EN') },
      ],
      selectLang: '',
    }
  },
  mounted() {
    /* eslint-disable prefer-destructuring */
    if (this.language) {
      this.selectLang = this.languages.find(el => el.label === this.language)
    } else {
      this.selectLang = this.languages[0]
    }
  },
  methods: {
    getLanguageLink(language) {
      return prefixLanguage(window.location.href, { language })
    },
    setLanguage(lang) {
      window.location.href = this.getLanguageLink(lang.label)
    },
  },
}
</script>
