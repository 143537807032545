<template lang="pug">
  include ../../../templates/mixin.pug

  label.d-control-input__label(
    :for='id',
  ) {{ inputLabel }}
    span(
      v-if='isRequired'
    ) *
</template>

<script>
export default {
  name: 'BoundLabel',
  block: 'input-group',
  props: {
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
  },
}
</script>
