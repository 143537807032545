<template lang="pug">
  include ../../../templates/mixin.pug
  +b.header
    +b.ds-section.--space_2xs.--bg-color_main-white.--style_header
      +e.element
        +b.header-wrapper
          +b.g-container--fit_2xl.g-container--space_none
            +b.g-row.--space_none.--justify_center.--align_center
              +b.g-cell.g-cols--12
                +b.g-row.--space_none.--justify_center.--align_center.--relative.--space_none
                  +b.g-cell(:class="notFound? '' : 'g-cols--2 g-cols--disabled_from-lg'")
                  +b.g-cell.g-cols--auto(:class="notFound? '' : 'g-cols--disabled_from-lg'")
                    +b.g-row.--space_none.--justify_center.--justify_start-lg.--align_center.--space_none
                      +b.g-cell.g-cols--narrow
                        +b.ds-panel.--space_none
                          +e.element.--offset_horizontal
                            +b.A.ds-link(href="/")
                              +b.ds-avatar.--size_sm.--size_md-sm
                                +e.image(alt="company logo" src='/static/img/logo.png')
                      transition(name='scale')
                        +b.g-cell.g-cols--narrow
                          +b.ds-panel.--space_xs
                            +e.element.--offset_left
                              +b.ds-caption.--size_2xs.--size_md-sm.--bold.--color_main {{_('BLH CONSULTING')}}
                            +e.element.--offset_left
                              +b.ds-caption.--size_3xs.--size_xs-sm.--light.--color_main {{_('Your Consultant in China')}}
                  +b.g-cell.g-cols--narrow.--disabled_till-lg
                    +b.ds-panel.--space_xl
                      +e.element.--offset_left
                        +b.ds-caption.--size_sm.--size_lg-lg.--bold.--color_main.--variant_uppercase.P
                          +b.ds-caption.SPAN {{breadcrumbs.parent}} {{breadcrumbs.parent? '/ ' : ''}}
                            +b.TAG.ds-caption(
                              :tag="this.$route.params.vId || $route.params.UserName? 'router-link' : 'span'"
                              :to="{name: breadcrumbs.path}"
                              :class="this.$route.params.vId || $route.params.UserName? 'ds-caption--color_main-accent ds-caption--hovered_underline' : '' "
                            ) {{breadcrumbs.current}}
                            +b.ds-caption.--color_main.SPAN {{this.$route.params.vId? ' / ' + '№' + this.$route.params.vId : this.$route.params.UserName? ' / ' + this.$route.params.UserName : '' }}
                  +b.g-cell.g-cols--2.--auto-lg
                    +b.ds-panel.--space_3xl.--space_none-lg
                      +e.element.--offset_right
                        user-bar(
                          :notice-route='noticeRoute'
                          :notice-count='noticeCount'
                          :not-found="notFound"
                        )
</template>
<script>
import {
  CLIENT_ORDERS_NAME,
} from '../Client/routes'

import {
  SHIPPING_LIST_PAGE_NAME,
  SEARCH_LIST_PAGE_NAME,
  CHECKING_LIST_PAGE_NAME,
  CLIENTS_LIST_PAGE_NAME,
  MANAGERS_LIST_PAGE_NAME,
} from '../Manager/routes'
import {
  CLIENT_ORDERS_LIST_NAME,
} from '../Buyer/routes'
// import notFoundVue from '../Pages/not-found.vue'

export default {
  props: ['path', 'noticeRoute', 'noticeCount', 'notFound'],
  components: {
  },
  name: 'HeaderSection',
  data() {
    return {
      breadcrumbsPath: {
        shipping: SHIPPING_LIST_PAGE_NAME,
        searching: SEARCH_LIST_PAGE_NAME,
        checking: CHECKING_LIST_PAGE_NAME,
        'my-orders': CLIENT_ORDERS_NAME,
        'b-searching': CLIENT_ORDERS_LIST_NAME,
        'c-list': CLIENTS_LIST_PAGE_NAME,
        'm-list': MANAGERS_LIST_PAGE_NAME,
      },
      breadcrumbsTitles: {
        notice: this._('Notification'),
        personal: this._('Personal data'),
        checking: this._('Checking the product / factory'),
        searching: this._('Product search'),
        shipping: this._('Shipping'),
        'm-list': this._('Managers'),
        'all-users': this._('All users'),
        'c-list': this._('Clients'),
        'ctr-list': this._('Contactors'),
        'all-orders': this._('Orders'),
        'b-searching': this._('Product search'),
        'my-orders': this._('My orders'),
        'check-form': this._('Checking the product / factory'),
        'search-form': this._('Product search'),
        'shipping-form': this._('Shipping'),
      },
      breadcrumbs: {
        current: '',
        parent: '',
        path: '',
      },
    }
  },
  watch: {
    // eslint-disable-next-line
    '$route.name'() {
      this.getBreadcrumbs();
    },
  },
  mounted() {
    this.getBreadcrumbs();
  },
  methods: {
    getBreadcrumbs() {
      this.breadcrumbs.parent = this.breadcrumbsTitles[this.$route.meta.parent]
      this.breadcrumbs.current = this.breadcrumbsTitles[this.$route.meta.current]
      this.breadcrumbs.path = this.breadcrumbsPath[this.$route.meta.current]
    },
  },
}
</script>
