<template lang="pug">
  include ../../../templates/mixin.pug
  +b.modal-inner
    +b.VALIDATION-OBSERVER(
      ref="validator",
      v-slot="{ handleSubmit }",
      slim,
    )
      +b.FORM.d-control-form--main(@submit.prevent="handleSubmit(prepareData)")
        +b.g-row.--justify_center.--appearance_spaced.--space_none.--space_3xl-md
          +b.g-cell.g-cols--12(v-for="field in fields" :key="field.name")
            +b.ds-panel.--space_xl.--space_sm-md
              +e.element.--offset_vertical
                d-control-input(
                  @input="phoneLengthValodator()"
                  :ref="field.name"
                  :name='field.name'
                  :rules='field.rules'
                  :type='field.type'
                  :input-label='field.label'
                  class='d-control-input--style_main-light'
                  v-model='form[field.name]'
                  autofocus
                )
          +b.g-cell.g-cols--12(v-if="nonFieldErrors")
            +b.ds-caption.--appearance_center
              +b.error-label.LABEL {{ nonFieldErrors[0] }}
          +b.g-cell.g-cols--12
            +b.BUTTON.button.--style_main.--full_width(
              type="submit"
            )
              +b.ds-caption.--size_md.--appearance_center.--bold.--color_main-white
                | {{ _('Add') }}
</template>
<script>
import {
  addCounterparty,
} from '@services/auth.service.js';
import { modalOpener } from '@utils/helpers'
import { clone } from 'ramda';
import FormMixin, { defaultValidatorErrorsParser } from './FormMixin'

export default {
  props: ['auth_link'],
  name: 'FormAuthorization.',
  mixins: [FormMixin],
  data() {
    return {
      regNoticeModalConfig: {
        props: {
          title: 'Contractor added',
        },
        config: {
          width: '400',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        actor: this.getChooseModalData,
      },
      countiesList: [],
      selectedCountry: '',
      location: '',
      nonFieldErrors: '',
      fields: [
        {
          name: 'name',
          label: this._('Name'),
          rules: 'required|min:2|special_symbols',
        },
        {
          name: 'phoneNumber',
          type: 'number',
          label: this._('Phone'),
          rules: 'required|min:2',
        },
        {
          name: 'email',
          type: 'email',
          label: this._('Email'),
          rules: 'required',
        },
        {
          name: 'address',
          label: this._('Address'),
          rules: 'required',
        },
        {
          name: 'wechatLogin',
          label: this._('Wechat login'),
          rules: 'required',
        },
      ],
      form: {
        name: '',
        phoneNumber: '',
        email: '',
        address: '',
        wechatLogin: '',
      },
    }
  },
  methods: {
    modalOpener,
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return
        this.submit(valid, this.form)
      })
    },
    phoneLengthValodator() {
      this.form.phoneNumber = this.form.phoneNumber.slice(0, 16);
    },
    send(data) {
      const transformedData = clone(data);
      return addCounterparty.execute({}, transformedData).then(res => {
        if (201 === res.code) {
          this.$emit('finish', this.form);
          this.modalOpener(this.regNoticeModalConfig)
          this.$refs.validator.reset()
          this.$emit('close');
        }
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.nonFieldErrors = parsed.username
      this.$refs.validator.setErrors(parsed)
    },
    errorsReset() {
      this.nonFieldErrors = ''
    },
  },
  mounted() {
    this.$refs.name[0].$el.children[0].children[0].children[0].focus()
  },
}
</script>
