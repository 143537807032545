<template lang="pug">
  include ../../../templates/mixin.pug
  +b.g-row.--space_none.--justify_center.--align_center
    +b.g-cell.g-cols--auto
      +b.g-row.--space_none.--justify_end.--align_center
        language-bar.language-bar
        +b.g-cell.g-cols--narrow.--disabled_till-lg(v-if="!notFound")
          +b.ds-panel.--space_lg
            +e.element.--offset_right
              +b.ROUTER-LINK.ds-panel.--space_xs.--relative(
                :to="{name: noticeRoute}"
              )
                +e.element.--offset_vertical.--appearance_round.--size_1.--inner-space_sm.--bg-color_main-extra-light(
                  :class="'hovered_scale-sm'"
                )
                  +b.SVG.notice-icon.--color_main(data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/bell-outline.svg")
                +b.ds-panel.--space_xs.--location_notice.--absolute
                  +e.element.--offset_vertical.--appearance_round.--bg-color_lite-green.--style_notification
                    +b.SPAN.ds-caption.--size_sm.--color_main-white {{noticeCount}}
        +b.g-cell.g-cols--narrow
          click-outside(:do='closeUserbarMenu')
            +b.g-row.--space_none.--justify_center.--align_center(@click="toggleUserbar")
              +b.g-cell.g-cols--narrow
                +b.g-row.--space_none.--justify_center.--align_center.--space_xs
                  +b.g-cell.g-cols--narrow.--disabled_from-lg
                    +b.g-row.--space_none.--justify_center.--align_center
                      +b.SVG.svg-icon.svg-icon.--size_sm.--color_lite-gray.hovered_scale-sm.cur-pointer(
                        :class="(isActive) ? 'svg-icon--flip_vertically': ''"
                        data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/chevron-left.svg"
                      )
                  +b.g-cell.g-cols--narrow
                    +b.ds-panel.--space_none.--space_lg-lg
                      +e.element.--offset_horizontal
                        +b.ds-avatar.--size_sm.--size_md-sm.--appearance_rounded
                          +e.image.IMG(
                            v-if="profileData.avatar"
                            alt="user avatar"
                            :src='profileData.avatar')
                          +e.image.IMG(
                            v-if="!profileData.avatar"
                            alt="user avatar"
                            src='/static/img/no-user-image.jpg'
                            )
              +b.g-cell.g-cols--narrow.--disabled_till-lg
                +b.ds-panel.--space_xs
                  +e.element.--offset_vertical
                    +b.ds-caption.--size_sm.--color_main {{profileData.firstName}} {{profileData.lastName}}
                  +e.element.--offset_vertical
                    +b.ds-caption.--size_xs.--light.--color_main {{profileData.companyName}}
              +b.g-cell.g-cols--narrow.--disabled_till-lg
                +e.element.--offset_left
                  +b.ds-avatar.--size_lg.--ds-avatar
                    +b.SVG.svg-icon.--color_lite-gray.hovered_scale-sm.cur-pointer(
                      :class="(isActive) ? 'svg-icon--flip_vertically': ''"
                      data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/chevron-left.svg")
    transition(name='fade_right')
      +b.g-cell.g-cols--narrow.--absolute.--style_userbar-menu.--style_userbar-mobile(v-if="isActive")
        +b.g-row.--space_none.--justify_center.--align_center.--space_none
          +b.g-cell.g-cols--12.--disabled_from-lg
            +b.ds-panel.--space_lg
              +e.element.--offset_top
                +b.ds-caption.--size_sm.--color_main-white.--appearance_center {{profileData.firstName}} {{profileData.lastName}}
              +e.element.--offset_bottom
                +b.ds-caption.--size_xs.--light.--color_main-white.--appearance_center {{profileData.companyName}}
          +b.g-cell.g-cols--12.--disabled_from-mac.lang-cell
            row
              cell
                +b.P.ds-caption.--color_main-light.--size_md.--light {{ _('Language:')}}
              cell(
                v-for='item in languages'
                :key='item.label'
              )
                +b.A.ds-caption.--size_sm.--color_main-light(
                  :class="[language === item.label ? 'ds-caption--color_main-white' : '']"
                  :href='getLanguageLink(item.label)'
                ) {{ item.title }}
          +b.g-cell.g-cols--12.--disabled_from-lg.userbar-cell
            +b.g-row.--space_none.--justify_start.--align_center.--space_none.--relative
              +b.g-cell.g-cols--narrow
                +b.ds-panel.--space_lg
                  +e.element
                    +b.ds-inliner--size_xl
                      +e.body
                        +b.icon-wrapper.--bg-color_main-dark.--appearance_spaced_xs.--appearance_rounded
                          +b.SVG.svg-icon.--color_main-light.--size_sm(data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/bell-outline.svg")
              +b.g-cell.g-cols--narrow(v-if="!notFound")
                +b.ds-panel
                  +e.element.--offset_left
                    +b.ROUTER-LINK.ds-caption.--size_md.--light.--color_main-light(
                      :to="{name: noticeRoute}"
                    ) {{_('Notification')}}
                       +b.SPAN.ds-caption.--size_md.--medium.--color_accent-red {{' ' + noticeCount}}
          +b.g-cell.g-cols--12.userbar-cell
            +b.ds-panel.--space_xl
              +e.element.--offset_vertical
                +b.g-row.--space_none.--justify_start.--appearance_nowrap.--align_center.--relative
                  +b.g-cell.g-cols--narrow
                    +b.ds-inliner--size_xl
                      +e.body
                        +b.icon-wrapper.--bg-color_main-dark.--appearance_spaced_xs.--appearance_rounded
                          +b.SVG.svg-icon.--color_main-light.--size_sm(data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/logout-variant.svg")
                  +b.g-cell.g-cols--narrow
                    +b.ds-panel
                      +e.element.--offset_left
                        +b.ds-caption.--size_md.--cur-pointer.--light.--color_main-light
                          +b.SPAN.ds-caption.--hovered_main-light(
                            @click="logOut"
                          ) {{_('Log out')}}
</template>
<script>
import {
  getAccount,
  logOutResource,
} from '@services/auth.service.js';

import { prefixLanguage, CURRENT_LANGUAGE } from '@utils/urls'

const Toggler = () => import('../General/Toggler');
const ClickOutside = () => import('../General/ClickOutside');

export default {
  props: [
    'mobile',
    'noticeRoute',
    'noticeCount',
    'notFound',
  ],
  components: {
    Toggler,
    ClickOutside,
  },
  name: 'UserBar',
  data() {
    return {
      language: CURRENT_LANGUAGE,
      languages: [
        { label: 'uk', title: this._('UK') },
        { label: 'ru', title: this._('RU') },
        { label: 'en', title: this._('EN') },
      ],
      profileData: {},
      isActive: false,
    }
  },
  methods: {
    getLanguageLink(language) {
      return prefixLanguage(window.location.href, { language })
    },
    logOut() {
      return logOutResource.execute().then(res => {
        this.redirect(res.data.item.redirect)
      })
    },
    toggleUserbar() {
      this.isActive = !this.isActive
    },
    closeUserbarMenu() {
      this.isActive = false
    },
    redirect(path) {
      window.location.pathname = path;
    },
    loadAccountData() {
      getAccount.execute().then(res => {
        this.profileData = res.data.item
      });
    },
  },
  mounted() {
    this.loadAccountData()
  },
}
</script>
