<script>
export default {
  name: 'Toggler',
  props: {
    active: {
      default: false,
      type: Boolean,
      required: false,
    },
    requiredEmitting: {
      default: false,
      type: Boolean,
      required: false,
    },
    sizeChecker: {
      default: false,
      required: false,
    },

  },
  data() {
    return {
      activeState: false,
    };
  },
  created() {
    this.activeState = this.getStateBySize(window.innerWidth);
  },
  methods: {
    getStateBySize(size) {
      if (this.sizeChecker) {
        return this.sizeChecker(size);
      }

      return this.active;
    },
    toggleActive() {
      this.activeState = !this.activeState;
      this.emit();
    },
    emit() {
      if (this.requiredEmitting) {
        this.$emit('toggle', this.activeState);
      }
    },
    collapse(state = false) {
      this.activeState = state;
      this.emit();
    },
  },
  render() {
    return this.$scopedSlots.default({
      active: this.activeState,
      toggleActive: this.toggleActive,
      collapse: this.collapse,
    });
  },
};
</script>
