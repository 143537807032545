import { createI18n } from '@/i18n'
import { createRouter } from '@/router';

export function createApp({ Vue, I18n, Router }) {
  const i18n = createI18n({ Vue, I18n })
  const router = createRouter({ Vue, Router });

  Vue.prototype.$copyToClipboard = function copyToClipboard(value) {
    // HTTPS ок localhost
    navigator.clipboard.writeText(value)
  };

  Vue.prototype.$body = document.body;
  const app = new Vue({
    i18n,
    router,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })

  return { app, i18n }
}
